import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../../global/global.model';
import {Especialidades} from './especialidades.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class EspecialidadesServices {
	
	private formData: Especialidades = new Especialidades();

	constructor(private http: HttpClient) { }

	setDataEspecialidad(data:Especialidades){
		this.formData=data;
	}

	getDataEspecialidad(){
		var proveedor: Especialidades = this.formData;
		let promise = new Promise((resolve, reject) => {
            resolve(proveedor);
        });
        return promise;
	}

	getDataEspecialidades():Observable<Especialidades[]>{
		return this.http.get<Especialidades[]>(Globals.BASE_URL_API_REST+'especialidades.json');
	}

	saveEspecialidades(data:Especialidades){
		return this.http.post(Globals.BASE_URL_API_REST+'especialidades.json',data);
	}

	deleteEspecialidades(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'especialidades/'+id+'.json');
	}

	updateEspecialidades(data:Especialidades){
		 return this.http.patch(Globals.BASE_URL_API_REST+'especialidades/'+data.id+'.json',data);
	}

	viewEspecialidades(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'especialidades/'+id+'.json');
	}

	viewReconsultasAtenciones(idhis:number,idespec:number){
		return this.http.get(Globals.BASE_URL_API_REST+'op_filtrado_citas_medicas_reconsultas/'+idhis+'/'+idespec+'.json');
   	}

	conteoReconsultasAtenciones(idhis:number,idtcp:number,idespec:number){
		return this.http.get(Globals.BASE_URL_API_REST+'op_conteo_citas_medicas_reconsultas/'+idhis+'/'+idtcp+'/'+idespec+'.json');
   	}
	   
}