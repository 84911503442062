import { Component } from '@angular/core';
import { localStorageServices } from './appauth/auth/localStorageServices';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  userdata: any;
  texto:string="";
  loading:string="";
  constructor(public localdata: localStorageServices) {
    this.userdata = this.localdata.getValueFromLocalStorage();
    if(this.userdata && Object.entries(this.userdata).length > 0){
      this.texto=(this.userdata.datos.config.text);
      this.loading=this.userdata.datos.config.loading;
    }
    
  }

}
