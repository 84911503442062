import { Component, OnInit,ViewEncapsulation,Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { Users } from '../../user/users.model';
import { CustomValidators } from 'ng2-validation';
import {UserServices} from '../../user/users.services';
import {NotificationServices} from '../../../notification/notificatio.services';
import {GroupsServices} from '../../../group-pages/groups/groups.services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Groups } from '../../../group-pages/groups/groups.model';

@Component({
    selector: 'app-edit-user',
    templateUrl:'./usereditmodal-component.html',
    styleUrls: ['./usereditmodal-component.scss'],
    encapsulation: ViewEncapsulation.None
  })
  export class UserEditModalComponent implements OnInit {
   
    @Input() public user;
    public dataGroup={'name':'vacio'} as Groups;

    breakpoint: number;
    
    public formGroupFullname: FormGroup;
    public editFullname:boolean=false;

    public formGroupUserName: FormGroup;
    public editUserName:boolean=false;

    public formGroupUserIdentificador: FormGroup;
    public editIdentificador:boolean=false;

    public selectedGroup:number;

    public formGroupUserGrupo: FormGroup;
    public editGrupo:boolean=false;

    public dataUserEditDialog: any;

    ratio = '4:1';
    groupdata:any;
    constructor(public dialogRef:NgbActiveModal,
                private fb: FormBuilder,
                private servicesUser:UserServices,
                private servicesGroup:GroupsServices,
                private Noti:NotificationServices) {
                }
    
    ngOnInit(){
      this.dataUserEditDialog=this.user;
      
      this.selectedGroup=this.dataUserEditDialog.group_id;

      this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
      this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
      
      
      this.servicesGroup.getDataGroups().subscribe((data:any)=>{
        this.groupdata=data.groups;
        this.dataGroup = this.groupdata.find(obj => obj.id == this.dataUserEditDialog.group_id);
        
        
      });

      this.formGroupFullname = this.fb.group({
        nicname: [this.dataUserEditDialog.nicname, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(250)])]
      });

      this.formGroupUserName = this.fb.group({
        username: [this.dataUserEditDialog.username,Validators.compose([Validators.required, CustomValidators.email])]
      });

      this.formGroupUserIdentificador = this.fb.group({
        identificador: [this.dataUserEditDialog.identificador,null]
      });
      
      this.formGroupUserGrupo = this.fb.group({
        group_id: [this.dataUserEditDialog.group_id, Validators.compose([Validators.required])]
      });
  
    }

    //editar username
    editUserNameEnable(){
      this.editUserName=true;
      this.formGroupUserName = this.fb.group({
        username: [this.dataUserEditDialog.username,Validators.compose([Validators.required, CustomValidators.email])]
      });
    }

    editUserNameDisable(){
      this.editUserName=false;
    }

    saveUserName(){
      
      let userDataModel:Users=this.formGroupUserName.value;
      userDataModel.id=this.dataUserEditDialog.id;
      this.dataUserEditDialog.username=userDataModel.username;
      this.servicesUser.updateUser(userDataModel).subscribe((data:any)=>{
        if(data.estado){
          this.Noti.setNotification(data).then((datas)=>{
              if(datas){
                this.editUserName=false;
              }
          });
        }else{
          this.Noti.setNotification(data);
        }
      });

    }


    //editar fullname
    editFullNameEnable(){
      this.editFullname=true;
      this.formGroupFullname = this.fb.group({
        nicname: [this.dataUserEditDialog.nicname, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(250)])]
      });
    }

    editFullNameDisable(){
      this.editFullname=false;
    }

    saveUserFullname(){
      let userDataModel:Users=this.formGroupFullname.value;
      userDataModel.id=this.dataUserEditDialog.id;
      this.dataUserEditDialog.nicname=userDataModel.nicname;
      this.servicesUser.updateUser(userDataModel).subscribe((data:any)=>{
        if(data.estado){
          this.Noti.setNotification(data).then((datas)=>{
              if(datas){
                this.editFullname=false;
              }
          });
        
        }else{
          this.Noti.setNotification(data);
        }
      });
    }

    //editar identificador

    editIdentificadorEnable(){
      this.editIdentificador=true;
      this.formGroupUserIdentificador = this.fb.group({
        identificador: [this.dataUserEditDialog.identificador,null]
      });
    }

    editIdentificadorDisable(){
      this.editIdentificador=false;
    }

    saveUserIdentificador(){
      let userDataModel:Users=this.formGroupUserIdentificador.value;
      userDataModel.id=this.dataUserEditDialog.id;
      this.dataUserEditDialog.identificador=userDataModel.identificador;
      this.servicesUser.updateUser(userDataModel).subscribe((data:any)=>{
        if(data.estado){
          this.Noti.setNotification(data).then((datas)=>{
              if(datas){
                this.editIdentificador=false;
              }
          });
        
        }else{
          this.Noti.setNotification(data);
        }
      });
    }
    //Editar grupo

    // editGrupoEnable(){
      
    //   this.editGrupo=true;
    //   this.selectedGroup=this.dataUserEditDialog.group_id;
      
    //   this.formGroupUserGrupo = this.fb.group({
    //     group_id: [this.dataUserEditDialog.group_id, Validators.compose([Validators.required])]
    //   });

    // }

    // editGrupoDisable(){
    //   this.editGrupo=false;
    //   this.selectedGroup=this.dataUserEditDialog.group_id;
      
      
    // }

    // saveUserGrupo(){
    //   let userDataModel:Users=this.formGroupUserGrupo.value;
    //   userDataModel.id=this.dataUserEditDialog.id;
    //   this.dataUserEditDialog.group_id=userDataModel.grupo_id;
    //   this.servicesUser.updateUser(userDataModel).subscribe((data:any)=>{
    //     if(data.estado){
    //       this.Noti.setNotification(data).then((datas)=>{
    //           if(datas){
    //             this.editGrupo=false;
    //             this.dialogRef.close(1);
    //           }
    //       });
        
    //     }else{
    //       this.Noti.setNotification(data);
    //     }
    //   });
    // }


    onResize(event) {
      this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
      this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
      
    }

    cerrarModal(){
      this.dialogRef.close(2);
    }

  }
  
 