import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Enfermerias } from '../../enfermeria/enfermerias.model';
import { EnfermeriasServices } from '../../enfermeria/enfermerias.services';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Personas } from '../../../personas/persona/personas.model';
import { PersonasServices } from '../../../personas/persona/personas.services';
import { Especialidades } from '../../../especialidades/especialidade/especialidades.model';
import { EspecialidadesServices } from '../../../especialidades/especialidade/especialidades.services';

@Component({
	selector: 'app-editenfermeria',
	templateUrl: './editenfermeria.component.html',
	styleUrls: ['./editenfermeria.component.scss']
})

export class EditEnfermeriaComponent implements OnInit {

	@Input() Enfermeria:Enfermerias;
	public dataPersonas={'cedula':'vacio'} as Personas;
	public personasData:any;
	public dataEspecialidades={'nombre':'vacio'} as Especialidades;
	public especialidadesData:any;

	public formEnfermeriaPersona:FormGroup;
	public editPersona:boolean=false;

	public formEnfermeriaEspecialidade:FormGroup;
	public editEspecialidade:boolean=false;

	public formEnfermeriaEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioEnfermeria:EnfermeriasServices,
		private servicioPersona:PersonasServices,
		private servicioEspecialidade:EspecialidadesServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioPersona.getDataPersonas().subscribe((data:any)=>{
			this.personasData=data.personas;
			this.dataPersonas=this.personasData.find(obj => obj.id == this.Enfermeria.persona_id);
		});
		this.servicioEspecialidade.getDataEspecialidades().subscribe((data:any)=>{
			this.especialidadesData=data.especialidades;
			this.dataEspecialidades=this.especialidadesData.find(obj => obj.id == this.Enfermeria.especialidade_id);
		});

		this.formEnfermeriaPersona=this.fb.group({
			persona_id:[this.Enfermeria.persona_id,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formEnfermeriaEspecialidade=this.fb.group({
			especialidade_id:[this.Enfermeria.especialidade_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}
	
	editEnfermeriaPersonaEnable(){
		this.editPersona=true;
		this.formEnfermeriaPersona=this.fb.group({
			persona_id:[this.Enfermeria.persona_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editEnfermeriaPersonaDisable(){
		this.editPersona=false;
	}

	saveEnfermeriaPersona(){
		let EnfermeriaDataModel:Enfermerias=this.formEnfermeriaPersona.value;
		//EnfermeriaDataModel.id=this.Enfermeria.id;
		this.Enfermeria.persona_id=EnfermeriaDataModel.persona_id;
		this.servicioEnfermeria.updateEnfermerias(EnfermeriaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editPersona=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editEnfermeriaEspecialidadeEnable(){
		this.editEspecialidade=true;
		this.formEnfermeriaEspecialidade=this.fb.group({
			especialidade_id:[this.Enfermeria.especialidade_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editEnfermeriaEspecialidadeDisable(){
		this.editEspecialidade=false;
	}

	saveEnfermeriaEspecialidade(){
		let EnfermeriaDataModel:Enfermerias=this.formEnfermeriaEspecialidade.value;
		//EnfermeriaDataModel.id=this.Enfermeria.id;
		this.Enfermeria.especialidade_id=EnfermeriaDataModel.especialidade_id;
		this.servicioEnfermeria.updateEnfermerias(EnfermeriaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editEspecialidade=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}

