import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Indicaciones } from '../../../../appasclepio/citas-medicas-medicos/indicaciones/indicacione/indicaciones.model';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { ElementComponentService } from '../../servicios/component.servicios';

@Component({
  selector: 'app-confirmar-medicamento-enfermera-hospitalizar',
  templateUrl: './confirmar-medicamento-enfermera-hospitalizar.component.html',
  styleUrls: ['./confirmar-medicamento-enfermera-hospitalizar.component.scss']
})
export class ConfirmarMedicamentoEnfermeraHospitalizarComponent implements OnInit {
  @Input() Indicacione: any;
  dataProductoproveedore: any;
  IndicacionesDataModel: any;
  motivo: string = "";
  userdata: any;
  constructor(private notificationServices: NotificationServices,
    private activeModal: NgbActiveModal,
    public localdata: localStorageServices,
    private agendar: ElementComponentService,
    private ngxService: NgxUiLoaderService,) { }

  ngOnInit() {
    this.IndicacionesDataModel = { id: this.Indicacione.id } as any;
    this.userdata = this.localdata.getValueFromLocalStorage();
  }

  cerrarModal() {
    this.activeModal.close(2);
  }

  save() {
    let datasms = { 'val': this.Indicacione.vademecum.codigo + ' ' + this.Indicacione.vademecum.nombre + " " + this.Indicacione.vademecum.presentacion + ' para suministrar.', 'msg': 'Cancelar dosis del medicamento ' };
    this.notificationServices.msgConfir(datasms).then((datas) => {
      if (datas) {
        let DiferimientopagoDataModel = { id: this.Indicacione.id } as Indicaciones;
        DiferimientopagoDataModel.suspender = 0;
        DiferimientopagoDataModel.persona_id = this.userdata.datos.persona.id;
        
        this.agendar.updateIndicacionesHospitalizar(DiferimientopagoDataModel).subscribe((data: any) => {
          if (data.estado) {
            this.notificationServices.setNotification(data).then((datas) => {
              if (datas) {
                this.activeModal.close(data.datos);
              }
            });
          } else {
            this.notificationServices.setNotification(data);
          }
        });
      }
    });

  }

  fraction(value, donly = true) {
    let tolerance = 1.0E-6; // a partir de cuantas decimales se hace el redondeo
    let h1 = 1;
    let h2 = 0;
    let k1 = 0;
    let k2 = 1;
    let negative = false;
    let i: any;

    if (parseInt(value) == value) { // si el valor es un número entero, detener el código
      return value;
    } else if (value < 0) {
      negative = true;
      value = -value;
    }

    if (donly) {
      i = parseInt(value);
      value -= i;
    }

    let b = value;

    do {
      let a = Math.floor(b);
      let aux = h1;
      h1 = a * h1 + h2;
      h2 = aux;
      aux = k1;
      k1 = a * k1 + k2;
      k2 = aux;
      b = 1 / (b - a);
    } while (Math.abs(value - h1 / k1) > value * tolerance);

    return (negative ? "-" : '') + ((donly && (i != 0)) ? i + ' ' : '') + (h1 == 0 ? '' : h1 + "/" + k1);
  }

}
