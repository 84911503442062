import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ConfirmarMedicamentoEnfermeraHospitalizarComponent } from '../modal/confirmar-medicamento-enfermera-hospitalizar/confirmar-medicamento-enfermera-hospitalizar.component';

@Component({
  selector: 'app-dosis-enfermeria-hospitalizacion',
  templateUrl: './dosis-enfermeria-hospitalizacion.component.html',
  styleUrls: ['./dosis-enfermeria-hospitalizacion.component.scss']
})
export class DosisEnfermeriaHospitalizarComponent implements OnInit {
  @Input() items: any;
  @Input() tamanio: number = 0;
  @Input() k: number = 0;
  @Output() clickEvent = new EventEmitter();
  @Input() Indicacione: any;
  ultimoDato: any;
  current_date: any;
  current_time: any;
  config: any = {
    size: 'md',
    centered: true,
    resolve: { datasend: null }
  }
  constructor(public dialog: NgbModal,) { }

  ngOnInit() {
    this.current_time = moment().format("HH")
    this.current_date = moment().format("YYYY-MM-DD");
    for (let index = 0; index < this.items.value.length; index++) {
      const element = this.items.value[index];
      this.ultimoDato = element;
    }
  }

  retornar(row: any): any {
    let lista = [row.persona.nombre, row.persona.apellido];
    let resultado3 = "";
    for (let i = 0; i < lista.length; i++) {
      lista[i].split(' ').reduce((r, w) => resultado3 += w.slice(0, 1), '');
    }
    return { ac: resultado3 };
  }

  cancelar(data: any) {
    let dialogRef = this.dialog.open(ConfirmarMedicamentoEnfermeraHospitalizarComponent, this.config);
    dialogRef.componentInstance.Indicacione = this.Indicacione;
    dialogRef.componentInstance.ultimoDato = this.ultimoDato;

    dialogRef.result.then(
      result => {
        if (result && result !== 2) {
          data = result;
          this.clickEvent.emit(data);
        }
      },
      reason => {
        console.log(reason);
      }
    );
  }


}
