import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Globals } from '../../../../global/global.model';
import { Atencionesmedicas, BuscarGeneral, CitaExemanesMedicos } from './atencionesmedicas.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class AtencionesmedicasServices {

	private formData: BuscarGeneral = new BuscarGeneral();
	private formDataExamen: CitaExemanesMedicos = new CitaExemanesMedicos();
	constructor(private http: HttpClient) { }

	getBuscarPersona(): BuscarGeneral {
		var Estado: BuscarGeneral = {
			buscar_cie10: this.formData.buscar_cie10,
			buscar_diagnostico: this.formData.buscar_diagnostico,

		};
		return Estado;
	}

	setBuscarPersona(data: BuscarGeneral) {
		this.formData.buscar_cie10 = data.buscar_cie10;
		this.formData.buscar_diagnostico = data.buscar_diagnostico;
	}

	setDataConfirmarExamen(data: any) {
		this.formDataExamen = data;
	}

	getDataConfirmarExamen() {
		var proveedor: CitaExemanesMedicos = this.formDataExamen;
		let promise = new Promise((resolve, reject) => {
			resolve(proveedor);
		});
		return promise;
	}

	getDataEspecialidadExamenMedico(): Observable<any[]> {
		return this.http.get<any[]>(Globals.BASE_URL_API_REST + 'especialidades.json');
	}

	saveCitaexemanes(data: CitaExemanesMedicos) {
		return this.http.post(Globals.BASE_URL_API_REST + 'citaexemanes.json', data);
	}

	viewExamenesMedicosPacientes(id: number) {
		return this.http.get(Globals.BASE_URL_API_REST + 'op_filtrado_atencion_medica_examenes_pacientes/' + id + '.json');
	}

	viewAtencionCitaMedica(id: number) {
		return this.http.get(Globals.BASE_URL_API_REST + 'op_filtrado_atencion_medica/' + id + '.json');
	}

	viewAtencionCitaMedicasTodo(id: number, hospitalizacion: number) {
		return this.http.get(Globals.BASE_URL_API_REST + 'op_filtrado_atencion_medica_todo/' + id + '.json?hospitalizacion=' + hospitalizacion);
	}

	getDataExamenesmedicos(): Observable<CitaExemanesMedicos[]> {
		return this.http.get<CitaExemanesMedicos[]>(Globals.BASE_URL_API_REST + 'examenesmedicos.json');
	}

	getDataAtencionesmedicas(): Observable<Atencionesmedicas[]> {
		return this.http.get<Atencionesmedicas[]>(Globals.BASE_URL_API_REST + 'atencionesmedicas.json');
	}

	saveAtencionesmedicas(data: Atencionesmedicas) {
		return this.http.post(Globals.BASE_URL_API_REST + 'atencionesmedicas.json', data);
	}

	deleteAtencionesmedicas(id: number) {
		return this.http.delete(Globals.BASE_URL_API_REST + 'atencionesmedicas/' + id + '.json');
	}

	updateAtencionesmedicas(data: Atencionesmedicas) {
		return this.http.patch(Globals.BASE_URL_API_REST + 'atencionesmedicas/' + data.id + '.json', data);
	}

	viewAtencionesmedicas(id: number) {
		return this.http.get(Globals.BASE_URL_API_REST + 'atencionesmedicas/' + id + '.json');
	}

	viewHistoriasclinicas(id: number) {
		return this.http.get(Globals.BASE_URL_API_REST + 'historiasclinicas/' + id + '.json');
	}

	filtrarCitaExamenHorarioMedico(data: any) {
		return this.http.get(Globals.BASE_URL_API_REST + 'op_filtrado_horarios_examen_medico.json?eid=' + data.tipoempleado_id + '&dia=' + data.dia + '&fecha=' + data.fecha + '&exaid=' + data.examenesmedico_id + '&examecita=' + data.examecita_id);
	}

	viewHistoriasClinicaSignosVitales(id: number) {
		return this.http.get(Globals.BASE_URL_API_REST + 'op_filtrado_citas_medicas_signos_vitales/' + id + '.json');
	}

	getResultadoExemenExternosSolicitudMedico() {
		return this.http.get(Globals.BASE_URL_API_REST + 'op_externas_solicitudes.json');
	}

	getPreciosPorNombreEspecialidad(nombre: string = '') {
		return this.http.get(Globals.BASE_URL_API_REST + 'bucar_tarifas_citas_medicas.json?nombre=' + nombre);
	}

	updateCitasmedicas(data: any) {
		return this.http.patch(Globals.BASE_URL_API_REST + 'citasmedicas/' + data.id + '.json', data);
	}

	filtrarCitaMedicaMedicosEspecialidadHospitalizacion(id: number) {
		return this.http.get(Globals.BASE_URL_API_REST + 'op_filtrado_medicos_citas_eh_especialidades/' + id + '.json');
	}

}