import { Component, OnInit, Input } from '@angular/core';
import { Personas } from '../../persona/personas.model';

@Component({
  selector: 'app-historial-paciente',
  templateUrl: './historial-paciente.component.html',
  styleUrls: ['./historial-paciente.component.scss']
})
export class HistorialPacienteComponent implements OnInit {
  @Input() persona:any;
  constructor() { }

  ngOnInit() {
  }

}
