import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from "rxjs/operators";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { localStorageServices } from '../auth/localStorageServices';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {

    constructor(
        private ngxService: NgxUiLoaderService,
        private localdata:localStorageServices,
        private router: Router,) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request)
            .pipe(
                map(res => {
                    return res
                }),
                catchError((error: HttpErrorResponse) => {
                    let errorMsg = '';
                    if (error.error instanceof ErrorEvent) {
                        this.ngxService.stopLoader('princialLoder');
                        errorMsg = `Error: ${error.error.message}`;
                    } else {
                        this.ngxService.stopLoader('princialLoder');
                        errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
                    }

                    return throwError(errorMsg);
                })
            )
    }
}