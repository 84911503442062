import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-nombre',
  templateUrl: './nombre.component.html',
  styleUrls: ['./nombre.component.scss']
})
export class NombreComponent implements OnInit {
  @Input() nombre:string;
  @Input() tamanio=18;
  constructor() { }

  ngOnInit() {
    let cantidad=this.nombre.length;
    if(cantidad>this.tamanio)
    this.nombre=this.nombre.toUpperCase().substring(0,this.tamanio-3);
    else
    this.nombre=this.nombre.toUpperCase().substring(0,this.tamanio);
    
  }

}
