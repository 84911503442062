import { Component, Input, OnInit } from '@angular/core';
import { ElementComponentService } from '../servicios/component.servicios';

@Component({
  selector: 'app-ocupacion-info-cita',
  templateUrl: './ocupacion-info-cita.component.html',
  styleUrls: ['./ocupacion-info-cita.component.scss']
})
export class OcupacionInfoCitaComponent implements OnInit {

  @Input() idHistoriaMedica: number;
  rows=[];
  verInfoCita:boolean=true;

  constructor(private servicioApps: ElementComponentService) { }

  ngOnInit() {

    this.servicioApps.getDataViewOcupaciones(this.idHistoriaMedica).subscribe((data: any) => {
      this.rows = (data.historiaocupaciones);
      console.log(this.rows);
      
    });
  }

  verInfoCitaDetalle(){
    if(this.verInfoCita==true){
      this.verInfoCita=false;
    }else{
      this.verInfoCita=true;
    }
  }
}
