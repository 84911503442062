import { Component, OnInit,ViewEncapsulation, ViewChild } from '@angular/core';
import { PersonasServices} from '../persona/personas.services';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { NotificationServices} from '../../../../appauth/notification/notificatio.services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Personas } from '../persona/personas.model';
import { AddPersonaComponent } from '../modal/add/addpersona.component';
import { EditPersonaComponent } from '../modal/edit/editpersona.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
	selector: 'ms-personavista',
	templateUrl:'./personavista.component.html',
	styleUrls: ['./personavista.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class PersonasVistaComponent implements OnInit {
	rows = [];
	temp = [];
	
	iconItems = [{
		text: 'Nuevo',
		icon: 'mdi mdi-note-plus'
	},{
		text: 'Refrescar',
		icon: 'mdi mdi-backup-restore'
	}]

	config: any = {
		size: 'lg',
		centered: true,
		resolve: { datasend: null }
	}


	select(text: string) {
		if(text=='Refrescar'){
			this.listaPersonas();
		} else if(text=='Nuevo') {
			this.open();
		}
	}

	constructor(public dialog: NgbModal,
		private serviciosPersonas:PersonasServices,
		private notificationServices:NotificationServices,
		private ngxService: NgxUiLoaderService,
	){}

	ngOnInit(){
		this.listaPersonas();
	}

	listaPersonas(){
		this.ngxService.startLoader('princialLoder');
		this.serviciosPersonas.getDataPersonas().subscribe((data:any)=>{
			this.rows=data.personas;
			this.temp = [...this.rows];
			this.ngxService.stopLoader('princialLoder');
		});
	}

	updateFilter(event){
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function(d){
			return d.cedula.toLowerCase().indexOf(val) !== -1 
				   || ( d.nombre && d.nombre.toLowerCase().indexOf(val) !== -1)
				   || ( d.apellido && d.apellido.toLowerCase().indexOf(val) !== -1)
				   || ( d.razonsocial && d.razonsocial.toLowerCase().indexOf(val) !== -1)
				   || !val;
		});
		this.rows = temp;
	}

	open(){
		let dialogRef = this.dialog.open(AddPersonaComponent, this.config);
		dialogRef.result.then(
			result=>{
				if(result){
					this.listaPersonas();
				}
			},
			reason=>{
				console.log(reason);
			}
		);
	}

	delete(dataPersona:any){
		let data={'val':dataPersona.cedula,'msg':'Eliminar Personas'};
		this.notificationServices.msgEliminarConfir(data).then((datas)=>{
			if(datas){
				this.serviciosPersonas.deletePersonas(dataPersona.id).subscribe((smsdata:any)=>{
					if(smsdata.estado){
						this.notificationServices.setNotification(smsdata).then((datas)=>{
							if(datas){
								this.listaPersonas();
							}
						});
					}else{
						this.notificationServices.setNotification(smsdata);
					}
				});
			}
		});
	}

	edit(data:any){
		let dialogRef = this.dialog.open(EditPersonaComponent, this.config);
		dialogRef.componentInstance.Persona = data;
		dialogRef.result.then(
			result=>{
				if(result===1){
					this.listaPersonas();
				}
			},
			reason=>{
				console.log(reason);
			}
		);
	}

	editEstado(dataPersona:Personas){
		let dataEstadoPersona= {} as Personas;
		dataEstadoPersona.id=dataPersona.id;
		dataEstadoPersona.estado=Number(!dataPersona.estado);
		this.serviciosPersonas.updatePersonas(dataEstadoPersona).subscribe((data:any)=>{});
	}
}

