import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomFormsModule } from 'ng2-validation';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxDatatableModule, 
    FormsModule,CustomFormsModule, 
    NgbModule, 
    ReactiveFormsModule, 
  ],
  providers:[
  ],
  exports:[
  ]
})
export class ReportesReferenciasModule { }