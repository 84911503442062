import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditEmpleadoComponent } from './edit/editempleado.component';
import { AddEmpleadoComponent } from './add/addempleado.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ModalPersonaModule } from '../../personas/modal/modalpersona.module';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxDatatableModule,
		ModalPersonaModule,
		NgSelectModule
	],
	declarations:[EditEmpleadoComponent,AddEmpleadoComponent],
	entryComponents:[EditEmpleadoComponent,AddEmpleadoComponent],
})
export class ModalEmpleadoModule { }
