import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GroupsServices } from '../groups/groups.services';

@Component({
    selector: 'ms-grouplist',
    templateUrl:'./grouplist.component.html',
    encapsulation: ViewEncapsulation.None
})
export class GroupListComponent implements OnInit {
    rows = [];
    constructor(private groupServices:GroupsServices,
        private ngxService: NgxUiLoaderService,
      ) {}

    ngOnInit(){
        this.ngxService.startLoader('princialLoder');
        this.groupServices.getDataGroups().subscribe((data:any)=>{
            this.rows=data.groups;
            this.ngxService.stopLoader('princialLoder');
        });
    }

}