import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-formapago',
  templateUrl: './info-formapago.component.html',
  styleUrls: ['./info-formapago.component.scss']
})
export class InfoFormapagoElementComponent implements OnInit {
  @Input()  detalle:any;
  ver:boolean=false;
  constructor() { }

  ngOnInit() {
  }

  mas(){
    this.ver=true;
  }

  menos(){
    this.ver=false;
  }

}
