import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MotivoscitasServices } from '../motivoscita/motivoscitas.services';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddMotivoscitaComponent } from '../modal/add/addmotivoscita.component';

@Component({
	selector: 'ms-motivoscitavista',
	templateUrl: './motivoscitavista.component.html',
	styleUrls: ['./motivoscitavista.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class MotivoscitasVistaComponent implements OnInit {
	rows = [];
	temp = [];
	groupedForms:any;

	iconItems = [{
		text: 'Nuevo',
		icon: 'mdi mdi-note-plus'
	}, {
		text: 'Refescar',
		icon: 'mdi mdi-backup-restore'
	}]

	config: any = {
		size: 'lg',
		centered: true,
		resolve: { datasend: null }
	}


	select(text: string) {
		if (text == 'Refescar') {
			this.listaMotivoscitas();
		} else if (text == 'Nuevo') {
			this.open();
		}
	}

	constructor(public dialog: NgbModal,
		private serviciosMotivoscitas: MotivoscitasServices,
		private notificationServices: NotificationServices,
	) { }

	ngOnInit() {
		this.listaMotivoscitas();
	}

	listaMotivoscitas() {
		this.serviciosMotivoscitas.getDataMotivoscitas().subscribe((data: any) => {
			this.rows = data.motivoscitas;
			this.groupedForms = this.groupBy(this.rows, row => row.formulario);
			this.temp = [...this.rows];
		});
	}

	updateFilter(event) {
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function (d) {
			return d.nombre.toLowerCase().indexOf(val) !== -1 || !val;
		});
		this.rows = temp;
		this.groupedForms = this.groupBy(this.rows, row => row.formulario);

	}

	open() {
		let dialogRef = this.dialog.open(AddMotivoscitaComponent, this.config);
		dialogRef.result.then(
			result => {
				if (result === 1) {
					this.listaMotivoscitas();
				}
			},
			reason => {
				console.log(reason);
			}
		);
	}

	groupBy(list, keyGetter) {
		const map = new Map();
		list.forEach((item) => {
			const key = keyGetter(item);
			const collection = map.get(key);
			if (!collection) {
				map.set(key, [item]);
			} else {
				collection.push(item);
			}
		});
		return map;
	}

	
}

