import { Component, Input, OnInit } from '@angular/core';
import { Atencionesmedicas } from '../../../appasclepio/citas-medicas-medicos/atencionesmedicas/atencionesmedica/atencionesmedicas.model';
import { ElementResumenHospitalizacionComponentService } from '../servicios/resumen.hospitalizacion.services';

@Component({
  selector: 'app-recumen-general-por-hospitalizacion',
  templateUrl: './recumen-general-por-hospitalizacion.component.html',
  styleUrls: ['./recumen-general-por-hospitalizacion.component.scss']
})
export class RecumenGeneralPorHospitalizacionComponent implements OnInit {
  @Input() dataInfoHospitalizacion: any;
  dataInfoAtencionMedica: any;
  dataInfoAtencionMedicaCero:any;
  citas = [];
  tipo = 13;

  constructor(private resumen: ElementResumenHospitalizacionComponentService) { }

  ngOnInit() {

    console.log(this.dataInfoHospitalizacion);
    
    
    let itemAddHospitalizacion = {} as Atencionesmedicas;
    itemAddHospitalizacion.citasmedica_id = -9;
    itemAddHospitalizacion.anotaciones = "";
    itemAddHospitalizacion.id = 0;
    itemAddHospitalizacion.medico = undefined;
    itemAddHospitalizacion.topoatencion = 13;
    this.citas.push(itemAddHospitalizacion);

    let itemAddSignos = {} as Atencionesmedicas;
    itemAddSignos.citasmedica_id = -7;
    itemAddSignos.anotaciones = "";
    itemAddSignos.id = 0;
    itemAddSignos.medico = undefined;
    itemAddSignos.topoatencion = 11;
    this.citas.push(itemAddSignos);

    this.resumen.getDataResumenInfoAtencionesHopitalizacion(this.dataInfoHospitalizacion.id).subscribe((infoHopitalizacion: any) => {
      infoHopitalizacion.atencionesmedicas.forEach(element => {
        this.citas.push(element);
      });
      this.dataInfoAtencionMedica = infoHopitalizacion.atencionesmedicas[0];
      this.dataInfoAtencionMedicaCero= infoHopitalizacion.atencionesmedicas[0];

      let itemAddMedicamentos = {} as Atencionesmedicas;
      itemAddMedicamentos.citasmedica_id = -4;
      itemAddMedicamentos.anotaciones = "";
      itemAddMedicamentos.id = 0;
      itemAddMedicamentos.medico = undefined;
      itemAddMedicamentos.topoatencion = 8;
      this.citas.push(itemAddMedicamentos);

      let itemAddCuadroGlicemico = {} as Atencionesmedicas;
      itemAddCuadroGlicemico.citasmedica_id = -44;
      itemAddCuadroGlicemico.anotaciones = "";
      itemAddCuadroGlicemico.id = 0;
      itemAddCuadroGlicemico.medico = undefined;
      itemAddCuadroGlicemico.topoatencion = 888;
      this.citas.push(itemAddCuadroGlicemico);

      let itemResumennfermeria = {} as Atencionesmedicas;
      itemResumennfermeria.citasmedica_id = -8;
      itemResumennfermeria.anotaciones = "";
      itemResumennfermeria.id = 0;
      itemResumennfermeria.medico = undefined;
      itemResumennfermeria.topoatencion = 12;
      this.citas.push(itemResumennfermeria);




      let itemResumenEpicrisis = {} as Atencionesmedicas;
      itemResumenEpicrisis.citasmedica_id = -5;
      itemResumenEpicrisis.anotaciones = "";
      itemResumenEpicrisis.id = 0;
      itemResumenEpicrisis.medico = undefined;
      itemResumenEpicrisis.topoatencion = 9;
      this.citas.push(itemResumenEpicrisis);

      let itemResumenReceta = {} as Atencionesmedicas;
      itemResumenReceta.citasmedica_id = -6;
      itemResumenReceta.anotaciones = "";
      itemResumenReceta.id = 0;
      itemResumenReceta.medico = undefined;
      itemResumenReceta.topoatencion = 10;
      this.citas.push(itemResumenReceta);



    });

  }

  confirmarAtencion(data: any) {
    console.log(data);
    this.dataInfoAtencionMedica = data;
    this.tipo = data.topoatencion;
    switch (this.tipo) {
      case 4:
        {
          this.tipo=0;
          this.dataInfoAtencionMedica = undefined;
          setTimeout(() => {
            this.tipo = data.topoatencion;
            this.dataInfoAtencionMedica = data;
          }, 100);
        }
        break;
      case 9:
        console.log("It is a Monday.");
        break;
      case 10:
        console.log("It is a Tuesday.");
        break;
      case 11:
        console.log("It is a Wednesday.");
        break;
      case 12:
        console.log("It is a Thursday.");
        break;
      default:
        console.log("No such day exists!");
        break;
    }

  }

}
