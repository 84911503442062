import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormacionacademicasRoutes } from './formacionacademicas.routing';
import { FormacionacademicasVistaComponent } from './vistas/formacionacademicavista.component';
import { ModalFormacionacademicaModule } from './modal/modalformacionacademica.module';
import { FormacionacademicasServices } from './formacionacademica/formacionacademicas.services';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(FormacionacademicasRoutes),
		NgxDatatableModule,
		ModalFormacionacademicaModule
	],
	declarations:[
		FormacionacademicasVistaComponent,
	],
	providers:[
		FormacionacademicasServices
	]
})

export class FormacionacademicasModule {}
