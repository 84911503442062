import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../../global/global.model';
import {Citasmedicainformaciones} from './citasmedicainformaciones.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class CitasmedicainformacionesServices {

	constructor(private http: HttpClient) { }

	getDataCitasmedicainformaciones(form:string,seccion:number):Observable<Citasmedicainformaciones[]>{
		return this.http.get<Citasmedicainformaciones[]>(Globals.BASE_URL_API_REST+'citasmedicainformaciones.json');
	}

	getDataCitasMotivos(form:string,seccion:number,idcita:number,idatencionmedica:number):Observable<Citasmedicainformaciones[]>{
		return this.http.get<Citasmedicainformaciones[]>(Globals.BASE_URL_API_REST+'lista_motivos_citas_asignados/' + idcita +'/' + seccion+'/' +idatencionmedica+'.json?form='+form);
	}

	saveCitasmedicainformaciones(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'citasmedicainformaciones.json',data);
	}

	deleteCitasmedicainformaciones(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'citasmedicainformaciones/'+id+'.json');
	}

	updateCitasmedicainformaciones(data:Citasmedicainformaciones){
		 return this.http.patch(Globals.BASE_URL_API_REST+'citasmedicainformaciones/'+data.id+'.json',data);
	}

	viewCitasmedicainformaciones(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'citasmedicainformaciones/'+id+'.json');
	}
}