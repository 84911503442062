import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Provincias } from '../../provincia/provincias.model';
import { ProvinciasServices } from '../../provincia/provincias.services';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
	selector: 'app-addprovincia',
	templateUrl: './addprovincia.component.html',
	styleUrls: ['./addprovincia.component.scss']
})

export class AddProvinciaComponent implements OnInit {
	public formAddProvincia: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioProvincia:ProvinciasServices,
		private ngxService: NgxUiLoaderService,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.formAddProvincia = this.fb.group({
			nombre:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150)])],
			estado:[1,null],
		});
	}


	save(){
		let provinciaDataModel:Provincias=this.formAddProvincia.value;
		this.ngxService.startLoader('princialLoder');
		this.servicioProvincia.saveProvincias(provinciaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.ngxService.stopLoader('princialLoder');
						this.activeModal.close(data);
					}
				});
			}else{
				this.ngxService.stopLoader('princialLoder');
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(0);
	}
}