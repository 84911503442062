import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { ElementComponentService } from '../../servicios/component.servicios';

@Component({
  selector: 'app-forma-pagos',
  templateUrl: './forma-pagos.component.html',
  styleUrls: ['./forma-pagos.component.scss']
})
export class FormaPagosElementComponent implements OnInit {
  @Input() total: number;
  @Input() abonoData: number;
  @Output() newItemEvent = new EventEmitter<string>();
  rows = [];
  dataPago:any;
  userdata: any;
  config: any = {
    size: 'sm',
    centered: true,
    resolve: { datasend: null }
  }
  constructor(
    private servicioventas: ElementComponentService,
    public localdata: localStorageServices,
    private activeModal:NgbActiveModal,
    public dialog: NgbModal,) { }

  ngOnInit() {
    this.userdata = this.localdata.getValueFromLocalStorage();
  
      this.servicioventas.getDataConfirmarVentaFormaPago().subscribe((data: any) => {
        this.rows = data.formapagos;
      });
  }

  seleccionar(data: any, info: any) {
    let sms = {data:data,info:info} as any
    this.activeModal.close(sms);
  }

  onChange(data:any){
    if(data){
      this.dataPago=data;
    }else{
      this.dataPago=undefined;
    }
    
  }

}
