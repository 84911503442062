import { Component, OnInit, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { OpcionesmotivosServices } from '../opcionesmotivo/opcionesmotivos.services';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Opcionesmotivos } from '../opcionesmotivo/opcionesmotivos.model';
import { AddOpcionesmotivoComponent } from '../modal/add/addopcionesmotivo.component';
import { EditOpcionesmotivoComponent } from '../modal/edit/editopcionesmotivo.component';

@Component({
	selector: 'ms-opcionesmotivovista',
	templateUrl: './opcionesmotivovista.component.html',
	styleUrls: ['./opcionesmotivovista.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class OpcionesmotivosVistaComponent implements OnInit {
	@Input() itemMotivo: any;
	position: number = -1;

	rows = [];
	temp = [];

	iconItems = [{
		text: 'Nuevo',
		icon: 'mdi mdi-note-plus'
	}, {
		text: 'Refescar',
		icon: 'mdi mdi-backup-restore'
	}]

	config: any = {
		size: 'sm',
		centered: true,
		resolve: { datasend: null }
	}


	select(text: string) {
		if (text == 'Refescar') {
			this.listaOpcionesmotivos();
		} else if (text == 'Nuevo') {
			this.open();
		}
	}

	constructor(public dialog: NgbModal,
		private serviciosOpcionesmotivos: OpcionesmotivosServices,
		private notificationServices: NotificationServices,
	) { }

	ngOnInit() {
		this.listaOpcionesmotivos();
	}

	listaOpcionesmotivos() {
		this.serviciosOpcionesmotivos.getDataOpcionesmotivos(this.itemMotivo.id).subscribe((data: any) => {
			this.rows = data.opcionesmotivos;
			this.temp = [...this.rows];
		});
	}

	updateFilter(event) {
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function (d) {
			return d.opciones.toLowerCase().indexOf(val) !== -1 || !val;
		});
		this.rows = temp;
	}

	open() {
		let dialogRef = this.dialog.open(AddOpcionesmotivoComponent, this.config);
		dialogRef.componentInstance.motivo = this.itemMotivo;
		dialogRef.componentInstance.Orden = this.rows.length+1;
		dialogRef.result.then(
			result => {
				if (result === 1) {
					this.listaOpcionesmotivos();
				}
			},
			reason => {
				console.log(reason);
			}
		);
	}

	delete(dataOpcionesmotivo: any) {
		let data = { 'val': dataOpcionesmotivo.opciones, 'msg': 'Eliminar Opcionesmotivos' };
		this.notificationServices.msgEliminarConfir(data).then((datas) => {
			if (datas) {
				this.serviciosOpcionesmotivos.deleteOpcionesmotivos(dataOpcionesmotivo.id).subscribe((smsdata: any) => {
					if (smsdata.estado) {
						this.notificationServices.setNotification(smsdata).then((datas) => {
							if (datas) {
								this.listaOpcionesmotivos();
							}
						});
					} else {
						this.notificationServices.setNotification(smsdata);
					}
				});
			}
		});
	}

	edit(data: any) {
		let dialogRef = this.dialog.open(EditOpcionesmotivoComponent, this.config);
		dialogRef.componentInstance.Opcionesmotivo = data;
		dialogRef.result.then(
			result => {
				if (result === 1) {
					this.listaOpcionesmotivos();
				}
			},
			reason => {
				console.log(reason);
			}
		);
	}

	editEstado(dataOpcionesmotivo: Opcionesmotivos) {
		let dataEstadoOpcionesmotivo = {} as Opcionesmotivos;
		dataEstadoOpcionesmotivo.id = dataOpcionesmotivo.id;
		dataEstadoOpcionesmotivo.estado = Number(!dataOpcionesmotivo.estado);
		this.serviciosOpcionesmotivos.updateOpcionesmotivos(dataEstadoOpcionesmotivo).subscribe((data: any) => { });
	}

	listaMotivosCambioOpcion(data: any) {
		this.position = data.orden;
		this.serviciosOpcionesmotivos.getDataOpcionesmotivos(this.itemMotivo.id).subscribe((data: any) => {
			this.rows = data.opcionesmotivos;
			this.temp = [...this.rows];
		});
	}
}

