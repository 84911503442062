import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponseBase } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { localStorageServices } from '../auth/localStorageServices';
import { Router } from "@angular/router";
import { CerrarErrorComponent } from '../cerrar-error/cerrar-error.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    config: any = {
        size: 'sm',
        centered: true,
        resolve: { datasend: null }
      };

    constructor(private localdata: localStorageServices, private router: Router,public dialog: NgbModal,) {

    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let tokendata = null;
        if (JSON.stringify(this.localdata.getValueFromLocalStorage()) != 'null' && JSON.stringify(this.localdata.getValueFromLocalStorage()) != '{}') {
            tokendata = this.localdata.getValueFromLocalStorage().datos.token;
        }
        if (req.headers.get('Authorization') == 'SmartRoad 2525258479' && req.method == 'PATCH') {
            return next.handle(req.clone({ setHeaders: { Authorization: req.headers.get('Authorization') } }));
        } else if (req.headers.get('Authorization') == 'LocalPrinter 66630998755432' && req.method == 'PUT') {
            return next.handle(req.clone({ setHeaders: { Authorization: req.headers.get('Authorization') } }));
        } else {
            let authHeader = 'SmartRoad ' + tokendata;
            const authReq = req.clone({ setHeaders: { Authorization: authHeader } });
            return next.handle(authReq);
        }
    }
}