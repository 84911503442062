import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { localStorageServices } from '../../../appauth/auth/localStorageServices';
import { NotificationServices } from '../../../appauth/notification/notificatio.services';
import { Globals } from '../../../global/global.model';
import { ElementComponentService } from '../servicios/component.servicios';

@Component({
  selector: 'app-lista-citas-hospitalizacion-pagos',
  templateUrl: './lista-citas-hospitalizacion-pagos.component.html',
  styleUrls: ['./lista-citas-hospitalizacion-pagos.component.scss']
})
export class ListaCitasHospitalizacionPagosComponent implements OnInit {
  @Input() listaHospitalizacionCitas: any;
  @Input() idHospitalizacion: number;
  listaCita = [];
  imgEmergencia: string = Globals.imagenEmergencia;
  totalPorCobrar: number = 0;
  userdata:any;
  constructor(private srvListaCitas: ElementComponentService, 
    private ngxService: NgxUiLoaderService,
    public localdata: localStorageServices,
    private router: Router,
    private route: ActivatedRoute,
    private notificationServices: NotificationServices,) { }

  ngOnInit() {
    this.userdata = this.localdata.getValueFromLocalStorage();
    /* let ids = [];
    for (let j = 0; j < this.listaHospitalizacionCitas.length; j++) {
      const element = this.listaHospitalizacionCitas[j];
      let existe = false;
      let idcita = element.citasmedica_id;
      for (let k = 0; k < ids.length; k++) {
        const elementid = ids[k];
        if (elementid == element.citasmedica_id) {
          existe = true;
          break;
        }
      }
      if (!existe && element.citasmedica_id > 0) {
        ids.push(idcita);
      }
    } */
    this.lista();
  }

  lista() {
    let dataSend = {idh: this.idHospitalizacion } as any;
    this.ngxService.startLoader('princialLoder');
    this.srvListaCitas.CitasFacturasLista(dataSend).subscribe((daya: any) => {
      if (daya) {
        this.listaCita = daya.citas;
        this.totalPorCobrar=0;
        for (let l = 0; l < this.listaCita.length; l++) {
          const element = this.listaCita[l];
          if (element.citasmedica.estado == 0) {
            this.totalPorCobrar += element.citasmedica.costo;
          }
        }
        this.ngxService.stopLoader('princialLoder');
      }
    });
  }

  terminar() {
    let data;

    data = { 'val': '', 'msg': 'Terminar hospitalización, paciente' };
    this.notificationServices.msgConfir(data).then((datas) => {
      if (datas) {
        this.ngxService.startLoader('princialLoder');
        let AdiccioneDataModel = {} as any;
        AdiccioneDataModel.id = this.idHospitalizacion;
        AdiccioneDataModel.estado = 2;
        AdiccioneDataModel.medico = this.userdata.datos.persona.id;

        this.srvListaCitas.updateHospitalizacionesFin(AdiccioneDataModel).subscribe((dataupdate: any) => {
          if (dataupdate.estado) {
            this.notificationServices.setNotification(dataupdate);
            this.lista();
            this.router.navigate(['/cita-medica-medico/atencion-citas-medicas'], { relativeTo: this.route.parent, skipLocationChange: true });
            this.ngxService.stopLoader('princialLoder');
          } else {
            this.notificationServices.setNotification(dataupdate);
            this.ngxService.stopLoader('princialLoder');
          }
        });
      }
    });
  }
}
