import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { ConsultasFacturasService } from '../../servicios/consultas.facturas.services';

@Component({
  selector: 'app-add-static-items',
  templateUrl: './add-static-items.component.html',
  styleUrls: ['./add-static-items.component.scss']
})
export class AddStaticItemsComponent implements OnInit {
  rows=[];
  ivacalculado:number=0;
  constructor(private items:ConsultasFacturasService,
    private activeModal:NgbActiveModal,
    private servicioDecimal: localStorageServices) { }

  ngOnInit() {
    this.items.getDataItemsVentas().subscribe((idata:any)=>{
      this.rows=idata.items;
      this.rows.forEach(element => {
        element.seleccionado=0;
      });
    });
  }

  addItems(data:any){
    
    let sumaiva:number=0;
    let sumaivatotal: number = 0;
    let sumaivadescuento: number = 0;
    for (let indeximp = 0; indeximp < data.itemimpuestos.length; indeximp++) {
      const element = data.itemimpuestos[indeximp];
      if(element.tipoimpusto && element.tipoimpusto.numero==2 && element.tipoimpusto.valorimpuestos.length>0 ){
        sumaiva+=this.servicioDecimal.round((data.valor-data.descuento)-((data.valor-data.descuento)/(1+(element.tipoimpusto.valorimpuestos[0].valor/100))));
        sumaivatotal +=this.servicioDecimal.round((data.valor) - ((data.valor)/ (1 + (element.tipoimpusto.valorimpuestos[0].valor / 100))));
        sumaivadescuento +=this.servicioDecimal.round((data.descuento) - ((data.descuento )/ (1 + (element.tipoimpusto.valorimpuestos[0].valor / 100))));
      }else{
        sumaiva+=0;
        sumaivatotal+= 0; 
        sumaivadescuento+= 0;
      }
    }

    let dataSendExamenParametro = { codigo: data.codigo , 
                                    data:data,
                                    itemfijo_id: data.id, 
                                    estado: 0, 
                                    cantidad: data.cantidad, 
                                    precio: this.servicioDecimal.round(data.valor - sumaivatotal), 
                                    descuento:this.servicioDecimal.round(data.descuento-sumaivadescuento), 
                                    iva: this.servicioDecimal.round(sumaiva*data.cantidad) } as any;
    
    this.items.setDetallesFacturaItems(dataSendExamenParametro, 1);
  }

  cerrarModal(){
    this.activeModal.close(2);
  }
  
}
