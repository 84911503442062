import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditPersonaComponent } from './edit/editpersona.component';
import { AddPersonaComponent } from './add/addpersona.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { ObtenerEdadComponent } from './obtener-edad/obtener-edad.component';
import { ViewComponent } from './view/view.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ResumenComponent } from './resumen/resumen.component';
import { HistorialPacienteComponent } from './historial-paciente/historial-paciente.component';
import { UbicacionesComponent } from './ubicaciones/ubicaciones.component';
import { ParroquiasModule } from '../../../ubicacion/parroquias/parroquias.module';
import { ProvinciasModule } from '../../../ubicacion/provincias/provincias.module';
import { CantonesModule } from '../../../ubicacion/cantones/cantones.module';
import { NombreComponent } from './nombre/nombre.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DirectivaModule } from '../../../../directivas/directiva.module';
import { EditInformacionPersonaComponent } from './editarinformacion/editinformacionpersona.component';
import { AddPersonaClienteInformacionComponent } from './add-cliente-informacion/add-cliente-informacion.component';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgSelectModule,
		ParroquiasModule,
		CantonesModule,
		ProvinciasModule,
		NgbModule,
		DirectivaModule
	],
	exports:[ObtenerEdadComponent,ViewComponent,ResumenComponent,HistorialPacienteComponent,EditInformacionPersonaComponent,UbicacionesComponent],
	declarations:[EditPersonaComponent,AddPersonaComponent,ObtenerEdadComponent, ViewComponent, ResumenComponent, HistorialPacienteComponent, UbicacionesComponent, NombreComponent,EditInformacionPersonaComponent,AddPersonaClienteInformacionComponent],
	entryComponents:[EditPersonaComponent,AddPersonaComponent,ObtenerEdadComponent,AddPersonaClienteInformacionComponent],
})
export class ModalPersonaModule { }
