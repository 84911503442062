import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditEtniaComponent } from './edit/editetnia.component';
import { AddEtniaComponent } from './add/addetnia.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
	],
	declarations:[EditEtniaComponent,AddEtniaComponent],
	entryComponents:[EditEtniaComponent,AddEtniaComponent],
})
export class ModalEtniaModule { }
