import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from '../../../../app/global/global.model';
import { MspReportesService } from '../servicios/msprepotrtes.servicios';
import { ElementResumenHospitalizacionComponentService } from '../servicios/resumen.hospitalizacion.services';

@Component({
  selector: '[app-resumen-signos-vitales-hospitalizacion]',
  templateUrl: './resumen-signos-vitales-hospitalizacion.component.html',
  styleUrls: ['./resumen-signos-vitales-hospitalizacion.component.scss']
})
export class ResumenSignosVitalesHospitalizacionComponent implements OnInit {
  @Input() idHospital: number;
  @Input() idHistoriaClinica: number;
  signos:any[]=[];
  balanceHidrico:any[]=[];
  balanceMedicio:any[]=[];
  pdfpath:any;

  constructor(private smpForm:MspReportesService,
    public sanitizer: DomSanitizer,
    private resumen: ElementResumenHospitalizacionComponentService) { }

  ngOnInit() {
    this.imprimir();
    this.resumen.viewSignosVilatesHospitalizacionResumen(this.idHospital).subscribe((info: any) => {
      this.signos = info.signosvitales;
      this.listAtensionBalanceHidrico();
      this.listAtensionMedionesActividades()
    });
    
  }


  listAtensionBalanceHidrico() {
    this.resumen.viewBalanceHidricoHospitalizacionResumen(this.idHospital).subscribe((dataInfo: any) => {
      this.balanceHidrico = [];
      for (let k = 0; k < dataInfo.signosbalancehidricos.length; k++) {
        const element = dataInfo.signosbalancehidricos[k];
        this.balanceHidrico.push(element);
      }
    });
  }

  listAtensionMedionesActividades() {
    this.resumen.viewMedicionesActividadesHospitalizacionResumen(this.idHospital).subscribe((dataInfo: any) => {
      this.balanceMedicio = [];
      for (let k = 0; k < dataInfo.signosmedicionactividades.length; k++) {
        const element = dataInfo.signosmedicionactividades[k];
        this.balanceMedicio.push(element);
      }
    });
  }

  imprimir(){
    this.smpForm.getMSPForm004({historia:this.idHistoriaClinica,hospitalizacione_id:this.idHospital}).subscribe((datapdf:any)=>{
      this.pdfpath=this.sanitizer.bypassSecurityTrustResourceUrl(Globals.BASE_URL_API_REST+ datapdf.datos.MSP.ruta); 
    })
  }

}
