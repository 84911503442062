import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataSharingService } from '../citasmedicainformacione/imagensgv.services';

@Component({
  selector: '[app-svg-object]',
  templateUrl: './svg-object.component.html',
  styleUrls: ['./svg-object.component.scss']
})
export class SvgObjectComponent implements AfterViewInit, OnInit {
  @Input() dataG: any;
  xpos: number = 0;
  ypos: number = 0;
  valorItem: any = "";
  pintar: number = 0;
  verInfo: boolean = false;
  @Output() clickEvent = new EventEmitter();
  dataInfoParte: any;
  dataSelect: any;
  //0 sin nada
  //1 seleccionado
  //2 guardado
  constructor(private DataSharing: DataSharingService,) {
    this.DataSharing.SharingData.subscribe((res: any) => {
      this.dataSelect = res;
      this.pintar = 0;
      this.verInfo = false;

      if (this.dataG != undefined) {
        this.dataG.children.forEach(element => {
          element.children.forEach(element2 => {
            for (let i = 0; i < this.dataSelect.lista.length; i++) {
              const fila = this.dataSelect.lista[i];
              if (Number(element2.value) == Number(fila.numero) && fila.data.guardado == 0 && fila.base==1) {
                this.pintar = 0;
                this.verInfo = false;
              } else if (Number(element2.value) == Number(fila.numero) && fila.data.guardado == 1 && fila.base==1) {
                this.pintar = 2;
                this.verInfo = true;
                this.valorItem = fila.numero;
                let width: any = document.getElementById('p' + element2.value);
                let obj: any = width.getBBox();
                let b: number = obj.width;
                let h: number = obj.height;
                let x: number = obj.x + (b / 2);
                let y: number = obj.y + (h / 2);
                this.ypos = y;
                this.xpos = x;
              }
            }
          });
        });
      }
    })
  }

  ngOnInit() {

  }

  public onClick(roomNumber: number, dataImg: any): void {

    if (this.dataSelect && this.dataSelect.info !== undefined) {
      this.dataInfoParte = dataImg;
      this.valorItem = roomNumber;
      if (this.pintar == 0) {
        this.pintar = 1;
        this.dataInfoParte.seccion = 1;
        this.verInfo = true;
      } else if (this.pintar == 1) {
        this.pintar = 0
        this.dataInfoParte.seccion = 0;
        this.verInfo = false;
      } else if (this.pintar == 2) {
        let siesta: boolean = false;
        for (let i = 0; i < this.dataSelect.lista.length; i++) {
          const fila = this.dataSelect.lista[i];
          if (Number(roomNumber) == Number(fila.numero) && fila.data.guardado == 1 && (this.dataSelect.info!=undefined && this.dataSelect.info.id==fila.data.id )) {
            siesta=true;
            break;
          }
        }
        if (!siesta) {
          this.pintar = 3;
          this.dataInfoParte.seccion = 1;
          this.verInfo = true;
        }
      } else if (this.pintar == 3) {
        this.pintar = 2;
        this.dataInfoParte.seccion = 0;
        this.verInfo = false;
      }
      let width: any = document.getElementById('p' + roomNumber);
      let obj: any = width.getBBox();
      let b: number = obj.width;
      let h: number = obj.height;
      let x: number = obj.x + (b / 2);
      let y: number = obj.y + (h / 2);
      this.ypos = y;
      this.xpos = x;

      let infoParte = { numero: roomNumber, estado: false, seleccion: this.dataInfoParte.seccion, info: this.dataSelect.info } as any;
      this.clickEvent.emit(infoParte);
    }
  }

  ngAfterViewInit() {
  }

  verInf() {
    this.verInfo = !this.verInfo;
    let infoParte = { numero: this.valorItem, estado: this.verInfo } as any;
    this.clickEvent.emit(infoParte);
  }


}
