import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { InsumounidadesRoutes } from './insumounidades.routing';
import { InsumounidadesVistaComponent } from './vistas/insumounidadevista.component';
import { ModalInsumounidadeModule } from './modal/modalinsumounidade.module';
import { InsumounidadesServices } from './insumounidade/insumounidades.services';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(InsumounidadesRoutes),
		NgxDatatableModule,
		ModalInsumounidadeModule
	],
	declarations:[
		InsumounidadesVistaComponent,
	],
	providers:[
		InsumounidadesServices
	],
	exports: [
		InsumounidadesVistaComponent
	]
})

export class InsumounidadesModule {}
