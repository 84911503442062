import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Formacionacademicas } from '../../formacionacademica/formacionacademicas.model';
import { FormacionacademicasServices } from '../../formacionacademica/formacionacademicas.services';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-editformacionacademica',
	templateUrl: './editformacionacademica.component.html',
	styleUrls: ['./editformacionacademica.component.scss']
})

export class EditFormacionacademicaComponent implements OnInit {

	@Input() Formacionacademica:Formacionacademicas;

	public formFormacionacademicaNombre:FormGroup;
	public editNombre:boolean=false;

	public formFormacionacademicaEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioFormacionacademica:FormacionacademicasServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {

		this.formFormacionacademicaNombre=this.fb.group({
			nombre:[this.Formacionacademica.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150)])],
		});
	}
	

	editFormacionacademicaNombreEnable(){
		this.editNombre=true;
		this.formFormacionacademicaNombre=this.fb.group({
			nombre:[this.Formacionacademica.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150)])],
		});
	}

	editFormacionacademicaNombreDisable(){
		this.editNombre=false;
	}

	saveFormacionacademicaNombre(){
		let FormacionacademicaDataModel:Formacionacademicas=this.formFormacionacademicaNombre.value;
		FormacionacademicaDataModel.id=this.Formacionacademica.id;
		this.Formacionacademica.nombre=FormacionacademicaDataModel.nombre;
		this.servicioFormacionacademica.updateFormacionacademicas(FormacionacademicaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editNombre=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}

