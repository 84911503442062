import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CustomFormsModule } from 'ng2-validation';
import { DirectivaModule } from '../../directivas/directiva.module';
import { ListaResultadoExamenComponent } from './lista-resultado-examen/lista-resultado-examen.component';


@NgModule({
  declarations: [ListaResultadoExamenComponent],
  imports: [
    CommonModule,
    FormsModule,
	ReactiveFormsModule,
    NgxDatatableModule, 
    FormsModule,
    CustomFormsModule, 
    NgbModule, 
    ReactiveFormsModule,
    NgSelectModule,
	DirectivaModule
  ],
  providers:[
  ],
  exports:[
    ListaResultadoExamenComponent
  ]
})
export class AppElementExamenModule { }
