import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ElementComponentService } from '../servicios/component.servicios';
import * as moment from 'moment-timezone';
import { localStorageServices } from '../../../appauth/auth/localStorageServices';

@Component({
  selector: 'app-lista-pendiente-examen-cita',
  templateUrl: './lista-pendiente-examen-cita.component.html',
  styleUrls: ['./lista-pendiente-examen-cita.component.scss']
})
export class ListaPendienteExamenCitaComponent implements OnInit {
  @Input() dataPaciente:any;
  @Output() clickEvent = new EventEmitter();
  groupedCitas: any;
  groupedExamenes: any;
  tempCitas: any;
  tempExamenes: any;
  totalExamenes: number = 0;
  cantidadExamenes: number = 0;
  ivaExamenes: number = 0;


  constructor(private servicioConsultas:ElementComponentService,
    private servicioDecimal: localStorageServices,) { }

  ngOnInit() {
    this.listar();
  }

  listar(){
    this.servicioConsultas.MostrarCitasYExamenesFacturasBusquedasEdiciones(this.dataPaciente.admisione.id).subscribe((data: any) => {
      this.groupedCitas = this.groupBy(data.info.citasmedicas, row => moment.tz(row.created, "America/Guayaquil").locale('es').format("LL"));
      this.groupedExamenes = this.groupBy(data.info.citaexemanes, row => moment.tz(row.created, "America/Guayaquil").locale('es').format("LL"));
      this.tempCitas = this.groupedCitas;
      this.tempExamenes = this.groupedExamenes;
    });
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  onChangeExamenes(data: any) {
    const val: any = data;
    const tempn = new Map(
      [...this.tempExamenes]
        .filter(([k, v]) => (k === val || !val))
    );
    this.groupedExamenes = tempn;
  }

  onChangeCitas(data: any) {
    const val: any = data;
    const tempn = new Map(
      [...this.tempCitas]
        .filter(([k, v]) => (k === val || !val))
    );
    this.groupedCitas = tempn;
  }

  sumarExamenes(data: any) {
    this.clickEvent.emit(data);
  }

}
