import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Insumounidades } from '../../insumounidade/insumounidades.model';
import { InsumounidadesServices } from '../../insumounidade/insumounidades.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
@Component({
	selector: 'app-editinsumounidade',
	templateUrl: './editinsumounidade.component.html',
	styleUrls: ['./editinsumounidade.component.scss']
})

export class EditInsumounidadeComponent implements OnInit {

	@Input() Insumounidade:Insumounidades;

	public formInsumounidadeNombre:FormGroup;
	public editNombre:boolean=false;

	public formInsumounidadeEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioInsumounidade:InsumounidadesServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {

		this.formInsumounidadeNombre=this.fb.group({
			nombre:[this.Insumounidade.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(25)])],
		});
	}
	

	editInsumounidadeNombreEnable(){
		this.editNombre=true;
		this.formInsumounidadeNombre=this.fb.group({
			nombre:[this.Insumounidade.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(25)])],
		});
	}

	editInsumounidadeNombreDisable(){
		this.editNombre=false;
	}

	saveInsumounidadeNombre(){
		let InsumounidadeDataModel:Insumounidades=this.formInsumounidadeNombre.value;
		InsumounidadeDataModel.id=this.Insumounidade.id;
		this.Insumounidade.nombre=InsumounidadeDataModel.nombre;
		this.servicioInsumounidade.updateInsumounidades(InsumounidadeDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editNombre=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}

