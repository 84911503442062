import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../../global/global.model';
import {Prefijos} from './prefijos.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class PrefijosServices {

	constructor(private http: HttpClient) { }

	getDataPrefijos():Observable<Prefijos[]>{
		return this.http.get<Prefijos[]>(Globals.BASE_URL_API_REST+'prefijos.json');
	}

	savePrefijos(data:Prefijos){
		return this.http.post(Globals.BASE_URL_API_REST+'prefijos.json',data);
	}

	deletePrefijos(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'prefijos/'+id+'.json');
	}

	updatePrefijos(data:Prefijos){
		 return this.http.patch(Globals.BASE_URL_API_REST+'prefijos/'+data.id+'.json',data);
	}

	viewPrefijos(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'prefijos/'+id+'.json');
	}

	busquedaFiltros(){
		return this.http.get<Prefijos[]>(Globals.BASE_URL_API_REST+'op_prefijos_busquedas.json');
	}
}