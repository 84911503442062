import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConsultasFacturasService } from '../../../appfacturas/busquedas/servicios/consultas.facturas.services';
import { localStorageServices } from '../../../appauth/auth/localStorageServices';

@Component({
  selector: 'app-lista-retencion-ventas',
  templateUrl: './lista-retencion-ventas.component.html',
  styleUrls: ['./lista-retencion-ventas.component.scss']
})
export class RetencionListaVentasComponent implements OnInit {
  @Input() infoEmpresa:any;
  @Input() subtotal:number;
  @Input() iva:number;
  @Input() valorenta:number;
  @Input() valoriva:number;
  @Output() clickEvent = new EventEmitter();
  impuesto=[];
  retencionsi:boolean=false

  constructor(private retencion:ConsultasFacturasService,
    private servicioDecimal: localStorageServices,
    ) { }

  ngOnInit() {
    if(this.valorenta>0 || this.valoriva>0){
      this.calcular();
      this.retencionsi=true;
    }
   
  }

  editEstadoRenta(data:any) {
    this.retencionsi=(!data);
		if(this.retencionsi)
		{
      this.calcular();
     
		}else{
      this.impuesto=[];
      this.clickEvent.emit(this.impuesto);
    }

  }

  calcular(){
    let ipu=[];
    this.retencion.listaRetencioneFacturas(this.infoEmpresa).subscribe((data:any)=>{
      data.retencioneslocales.forEach(element => {
        if(element.impuestoretencione.tiporenta.codigo==1){
          let calculoRenta:number=this.servicioDecimal.round((this.subtotal*(element.impuestoretencione.porcentaje/100)));
          let item ={retencioneslocale_id:element.id,valor:calculoRenta,nombre:'RENTA',codigo:element.impuestoretencione.tiporenta.codigo,porcentaje:element.impuestoretencione.porcentaje,codigop:element.impuestoretencione.codigo};
          ipu.push(item);
        }else if(element.impuestoretencione.tiporenta.codigo==2){
          let calculoIVA:number=this.servicioDecimal.round((this.iva*(element.impuestoretencione.porcentaje/100)));
          let item ={retencioneslocale_id:element.id,valor:calculoIVA,nombre:'IVA',codigo:element.impuestoretencione.tiporenta.codigo,porcentaje:element.impuestoretencione.porcentaje,codigop:element.impuestoretencione.codigo};
          ipu.push(item);
        }else if(element.impuestoretencione.tiporenta.codigo==3){
          let calculoISD:number=this.servicioDecimal.round((this.subtotal*(element.impuestoretencione.porcentaje/100)));
          let item ={retencioneslocale_id:element.id,valor:calculoISD,nombre:'ISD',codigo:element.impuestoretencione.tiporenta.codigo,porcentaje:element.impuestoretencione.porcentaje,codigop:element.impuestoretencione.codigo};
          ipu.push(item);
        }
      });
      this.impuesto=ipu;
      this.clickEvent.emit(this.impuesto);
    });
  }

}
