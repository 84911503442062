import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EmpleadosRoutes } from './empleados.routing';
import { EmpleadosVistaComponent } from './vistas/empleadovista.component';
import { ModalEmpleadoModule } from './modal/modalempleado.module';
import { EmpleadosServices } from './empleado/empleados.services';
import { ModalPersonaModule } from '../personas/modal/modalpersona.module';
import { LaboratoriosComponent } from './laboratorios/laboratorios.component';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(EmpleadosRoutes),
		NgxDatatableModule,
		ModalEmpleadoModule,
		ModalPersonaModule
	],
	providers:[
		EmpleadosServices
	],
	declarations:[
		EmpleadosVistaComponent,
		LaboratoriosComponent,
	],
	exports:[
		EmpleadosVistaComponent
	]
})

export class EmpleadosModule {}
