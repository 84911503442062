import { Routes } from '@angular/router';
import { AuthGuard } from '../../../appauth/auth/auth.guard';
import { EmpleadosVistaComponent } from './vistas/empleadovista.component';

export const EmpleadosRoutes: Routes = [{
	path: '',
	redirectTo: 'empleado-index',
	pathMatch: 'full',
	canActivate: [AuthGuard]
},{
	path: '',
	children: [{
		path: 'empleado-index',
		component: EmpleadosVistaComponent,
		canActivate: [AuthGuard]
	}]
}];
