import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Personas } from '../../persona/personas.model';
import { PersonasServices } from '../../persona/personas.services';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { customValidatorMondogo } from '../../../../custom-validator/customValidator.services';
import { PrefijosServices } from '../../../prefijos/prefijo/prefijos.services';
import { Etnias } from '../../../etnias/etnia/etnias.model';
import { Formacionacademicas } from '../../../formacionacademicas/formacionacademica/formacionacademicas.model';

@Component({
	selector: 'app-editpersona',
	templateUrl: './editpersona.component.html',
	styleUrls: ['./editpersona.component.scss']
})

export class EditPersonaComponent implements OnInit {

	@Input() Persona:Personas;
	tipoForm:number;
	public dataPrefijos:any;
	dataUbicacion={parroquia_id:0,cantone_id:0,provincia_id:0} as any;
	public dataEtnias={'nombre':'vacio'} as Etnias;
	public etniasData:any;
	public dataFormacionacademicas={'nombre':'vacio'} as Formacionacademicas;
	public formacionacademicasData:any;

	public formPersonaCedula:FormGroup;
	public editCedula:boolean=false;
	
	public formPersonaDireccion:FormGroup;
	public editDireccion:boolean=false;

	public formPersonaPrefijo:FormGroup;
	public editPrefijo:boolean=false;

	public formPersonaTelefono:FormGroup;
	public editTelefono:boolean=false;

	public formPersonaTelefono_trabajo:FormGroup;
	public editTelefono_trabajo:boolean=false;

	public formPersonaCelular:FormGroup;
	public editCelular:boolean=false;

	public formPersonaCelular_trabajo:FormGroup;
	public editCelular_trabajo:boolean=false;

	public formPersonaEmail:FormGroup;
	public editEmail:boolean=false;

	public formPersonaEstado:FormGroup;
	public editEstado:boolean=false;

	public formPersonaTelefono_trabajo_dos:FormGroup;
	public editTelefono_trabajo_dos:boolean=false;

	public formPersonaCelular_trabajo_dos:FormGroup;
	public editCelular_trabajo_dos:boolean=false;

	public formPersonaFecha_nacimiento:FormGroup;
	public editFecha_nacimiento:boolean=false;

	public formPersonaEstado_civil:FormGroup;
	public editEstado_civil:boolean=false;

	public formPersonaSexo:FormGroup;
	public editSexo:boolean=false;
	
	public formPersonaFacturaremail:FormGroup;
	public editFacturaremail:boolean=false;

	public formPersonaBarrio:FormGroup;
	public editBarrio:boolean=false;

	public formPersonaEtnia:FormGroup;
	public editEtnia:boolean=false;

	public formPersonaFormacionacademica:FormGroup;
	public editFormacionacademica:boolean=false;

	public formPersonaLugarnacimiento:FormGroup;
	public editLugarnacimiento:boolean=false;

	public formPersonaNacionalidad:FormGroup;
	public editNacionalidad:boolean=false;

	dataEstadoCivil=[
		{id:1,nombre:'Soltero(a)'},
		{id:2,nombre:'Unión de hecho'},
		{id:3,nombre:'Casado(a)'},
		{id:4,nombre:'Divorciado(a)'},
		{id:5,nombre:'Viudo(a)'}
	]

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private serivcioPrefijo:PrefijosServices,
		private servicioPersona:PersonasServices,
		private validatciones:customValidatorMondogo,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.tipoForm=this.Persona.tipo;
		this.serivcioPrefijo.busquedaFiltros().subscribe((data:any)=>{
			this.dataPrefijos=data.prefijos;
		});
		
		this.servicioPersona.getDataEtnias().subscribe((data:any)=>{
			this.etniasData=data.etnias;
			if(this.Persona.etnia_id)
				this.dataEtnias=this.etniasData.find(obj => obj.id == this.Persona.etnia_id);
		});

		this.servicioPersona.getDataFormacionacademicas().subscribe((data:any)=>{
			this.formacionacademicasData=data.formacionacademicas;
			if(this.Persona.formacionacademica_id)
				this.dataFormacionacademicas=this.formacionacademicasData.find(obj => obj.id == this.Persona.formacionacademica_id);
		});

		if(this.tipoForm===1){
			this.formPersonaCedula=this.fb.group({
				tipo:[this.Persona.tipo,Validators.compose([Validators.required])],
				cedula: [this.Persona.cedula,Validators.compose([Validators.required,Validators.minLength(10), Validators.maxLength(10),this.validatciones.digitoVerificador])],
				nombre:[this.Persona.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(250)])],
				apellido:[this.Persona.apellido,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(250)])],
			});
		}else if(this.tipoForm===2){
			this.formPersonaCedula=this.fb.group({
				tipo:[2,Validators.compose([Validators.required])],
				cedula: [this.Persona.cedula,Validators.compose([Validators.required,Validators.minLength(13),Validators.maxLength(13)])],
				razonsocial:[this.Persona.razonsocial,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300)])],
			});
		}else if(this.tipoForm===3){
			this.formPersonaCedula=this.fb.group({
				tipo:[this.Persona.tipo,Validators.compose([Validators.required])],
				cedula: [this.Persona.cedula,Validators.compose([Validators.required,Validators.minLength(5), Validators.maxLength(50),this.validatciones.digitoVerificador])],
				nombre:[this.Persona.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(250)])],
				apellido:[this.Persona.apellido,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(250)])],
			});
		}

		this.formPersonaDireccion=this.fb.group({
			direccion:[this.Persona.direccion,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300)])],
		});

		this.formPersonaPrefijo=this.fb.group({
			prefijo:[this.Persona.prefijo,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10)])],
		});

		this.formPersonaTelefono=this.fb.group({
			telefono:[this.Persona.telefono,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(9)])],
		});

		this.formPersonaTelefono_trabajo=this.fb.group({
			telefono_trabajo:[this.Persona.telefono_trabajo,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(9)])],
		});

		this.formPersonaCelular=this.fb.group({
			celular:[this.Persona.celular,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10)])],
		});

		this.formPersonaCelular_trabajo=this.fb.group({
			celular_trabajo:[this.Persona.celular_trabajo,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10)])],
		});

		this.formPersonaEmail=this.fb.group({
			email:[this.Persona.email,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(250)])],
		});

		this.formPersonaTelefono_trabajo_dos=this.fb.group({
			telefono_trabajo_dos:[this.Persona.telefono_trabajo_dos,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(9)])],
		});

		this.formPersonaCelular_trabajo_dos=this.fb.group({
			celular_trabajo_dos:[this.Persona.celular_trabajo_dos,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10)])],
		});

		var datePipeFecha_nacimiento = new DatePipe("en-US");
		this.formPersonaFecha_nacimiento=this.fb.group({
			fecha_nacimiento:[datePipeFecha_nacimiento.transform(this.Persona.fecha_nacimiento, 'yyyy-MM-dd'),Validators.compose([Validators.required,CustomValidators.date])],
		});

		this.formPersonaEstado_civil=this.fb.group({
			estado_civil:[this.Persona.estado_civil,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formPersonaSexo=this.fb.group({
			sexo:[this.Persona.sexo,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10)])],
		});
		this.dataUbicacion.parroquia_id=this.Persona.parroquia_id;

		this.formPersonaFacturaremail=this.fb.group({
			facturaremail:[this.Persona.facturaremail,Validators.compose([Validators.minLength(1), Validators.maxLength(250)])],
		});
		
		this.formPersonaBarrio=this.fb.group({
			barrio:[this.Persona.barrio,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50)])],
		});

		this.formPersonaEtnia=this.fb.group({
			etnia_id:[this.Persona.etnia_id,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formPersonaFormacionacademica=this.fb.group({
			formacionacademica_id:[this.Persona.formacionacademica_id,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formPersonaLugarnacimiento=this.fb.group({
			lugarnacimiento:[this.Persona.lugarnacimiento,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150)])],
		});

		this.formPersonaNacionalidad=this.fb.group({
			nacionalidad:[this.Persona.nacionalidad,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50)])],
		});

	}
	

	
	editPersonaCedulaEnable(){
		this.editCedula=true;

		if(this.tipoForm===1){
			this.formPersonaCedula=this.fb.group({
				tipo:[this.Persona.tipo,Validators.compose([Validators.required])],
				cedula: [this.Persona.cedula,Validators.compose([Validators.required,Validators.minLength(10), Validators.maxLength(10),this.validatciones.digitoVerificador])],
				nombre:[this.Persona.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(250)])],
				apellido:[this.Persona.apellido,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(250)])],
			});
		}else if(this.tipoForm===2){
			this.formPersonaCedula=this.fb.group({
				tipo:[2,Validators.compose([Validators.required])],
				cedula: [this.Persona.cedula,Validators.compose([Validators.required,Validators.minLength(13),Validators.maxLength(13)])],
				razonsocial:[this.Persona.razonsocial,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300)])],
			});
		}else if(this.tipoForm===3){
			this.formPersonaCedula=this.fb.group({
				tipo:[this.Persona.tipo,Validators.compose([Validators.required])],
				cedula: [this.Persona.cedula,Validators.compose([Validators.required,Validators.minLength(5), Validators.maxLength(50),this.validatciones.digitoVerificador])],
				nombre:[this.Persona.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(250)])],
				apellido:[this.Persona.apellido,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(250)])],
			});
		}
	}

	editPersonaCedulaDisable(){
		this.editCedula=false;
	}

	savePersonaCedula(){
		let PersonaDataModel:Personas=this.formPersonaCedula.value;
		PersonaDataModel.id=this.Persona.id;
		this.Persona.cedula=PersonaDataModel.cedula;
		if(this.tipoForm===2){
			this.Persona.razonsocial=PersonaDataModel.razonsocial;
		}else{
			this.Persona.nombre=PersonaDataModel.nombre;
			this.Persona.apellido=PersonaDataModel.apellido;
		}
		this.servicioPersona.updatePersonas(PersonaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editCedula=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editPersonaDireccionEnable(){
		this.editDireccion=true;
		this.formPersonaDireccion=this.fb.group({
			direccion:[this.Persona.direccion,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300)])],
		});
	}

	editPersonaDireccionDisable(){
		this.editDireccion=false;
	}

	savePersonaDireccion(){
		let PersonaDataModel:Personas=this.formPersonaDireccion.value;
		PersonaDataModel.id=this.Persona.id;
		this.Persona.direccion=PersonaDataModel.direccion;
		this.servicioPersona.updatePersonas(PersonaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editDireccion=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editPersonaPrefijoEnable(){
		this.editPrefijo=true;
		this.formPersonaPrefijo=this.fb.group({
			prefijo:[this.Persona.prefijo,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10)])],
		});
	}

	editPersonaPrefijoDisable(){
		this.editPrefijo=false;
	}

	savePersonaPrefijo(){
		let PersonaDataModel:Personas=this.formPersonaPrefijo.value;
		PersonaDataModel.id=this.Persona.id;
		this.Persona.prefijo=PersonaDataModel.prefijo;
		this.servicioPersona.updatePersonas(PersonaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editPrefijo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editPersonaTelefonoEnable(){
		this.editTelefono=true;
		this.formPersonaTelefono=this.fb.group({
			telefono:[this.Persona.telefono,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(9)])],
		});
	}

	editPersonaTelefonoDisable(){
		this.editTelefono=false;
	}

	savePersonaTelefono(){
		let PersonaDataModel:Personas=this.formPersonaTelefono.value;
		PersonaDataModel.id=this.Persona.id;
		this.Persona.telefono=PersonaDataModel.telefono;
		this.servicioPersona.updatePersonas(PersonaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editTelefono=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editPersonaTelefono_trabajoEnable(){
		this.editTelefono_trabajo=true;
		this.formPersonaTelefono_trabajo=this.fb.group({
			telefono_trabajo:[this.Persona.telefono_trabajo,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(9)])],
		});
	}

	editPersonaTelefono_trabajoDisable(){
		this.editTelefono_trabajo=false;
	}

	savePersonaTelefono_trabajo(){
		let PersonaDataModel:Personas=this.formPersonaTelefono_trabajo.value;
		PersonaDataModel.id=this.Persona.id;
		this.Persona.telefono_trabajo=PersonaDataModel.telefono_trabajo;
		this.servicioPersona.updatePersonas(PersonaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editTelefono_trabajo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editPersonaCelularEnable(){
		this.editCelular=true;
		this.formPersonaCelular=this.fb.group({
			celular:[this.Persona.celular,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10)])],
		});
	}

	editPersonaCelularDisable(){
		this.editCelular=false;
	}

	savePersonaCelular(){
		let PersonaDataModel:Personas=this.formPersonaCelular.value;
		PersonaDataModel.id=this.Persona.id;
		this.Persona.celular=PersonaDataModel.celular;
		this.servicioPersona.updatePersonas(PersonaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editCelular=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editPersonaCelular_trabajoEnable(){
		this.editCelular_trabajo=true;
		this.formPersonaCelular_trabajo=this.fb.group({
			celular_trabajo:[this.Persona.celular_trabajo,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10)])],
		});
	}

	editPersonaCelular_trabajoDisable(){
		this.editCelular_trabajo=false;
	}

	savePersonaCelular_trabajo(){
		let PersonaDataModel:Personas=this.formPersonaCelular_trabajo.value;
		PersonaDataModel.id=this.Persona.id;
		this.Persona.celular_trabajo=PersonaDataModel.celular_trabajo;
		this.servicioPersona.updatePersonas(PersonaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editCelular_trabajo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editPersonaEmailEnable(){
		this.editEmail=true;
		this.formPersonaEmail=this.fb.group({
			email:[this.Persona.email,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(250)])],
		});
	}

	editPersonaEmailDisable(){
		this.editEmail=false;
	}

	savePersonaEmail(){
		let PersonaDataModel:Personas=this.formPersonaEmail.value;
		PersonaDataModel.id=this.Persona.id;
		
		this.servicioPersona.updatePersonas(PersonaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editEmail=false;
						this.Persona.email=PersonaDataModel.email;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editPersonaTelefono_trabajo_dosEnable(){
		this.editTelefono_trabajo_dos=true;
		this.formPersonaTelefono_trabajo_dos=this.fb.group({
			telefono_trabajo_dos:[this.Persona.telefono_trabajo_dos,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(9)])],
		});
	}

	editPersonaTelefono_trabajo_dosDisable(){
		this.editTelefono_trabajo_dos=false;
	}

	savePersonaTelefono_trabajo_dos(){
		let PersonaDataModel:Personas=this.formPersonaTelefono_trabajo_dos.value;
		PersonaDataModel.id=this.Persona.id;
		this.Persona.telefono_trabajo_dos=PersonaDataModel.telefono_trabajo_dos;
		this.servicioPersona.updatePersonas(PersonaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editTelefono_trabajo_dos=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editPersonaCelular_trabajo_dosEnable(){
		this.editCelular_trabajo_dos=true;
		this.formPersonaCelular_trabajo_dos=this.fb.group({
			celular_trabajo_dos:[this.Persona.celular_trabajo_dos,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10)])],
		});
	}

	editPersonaCelular_trabajo_dosDisable(){
		this.editCelular_trabajo_dos=false;
	}

	savePersonaCelular_trabajo_dos(){
		let PersonaDataModel:Personas=this.formPersonaCelular_trabajo_dos.value;
		PersonaDataModel.id=this.Persona.id;
		this.Persona.celular_trabajo_dos=PersonaDataModel.celular_trabajo_dos;
		this.servicioPersona.updatePersonas(PersonaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editCelular_trabajo_dos=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	tipo(data:any){
		this.tipoForm=Number(data.target.value);
		if(Number(data.target.value)===1){
			this.formPersonaCedula=this.fb.group({
				tipo:[1,Validators.compose([Validators.required])],
				cedula: [this.Persona.cedula,Validators.compose([Validators.required,Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]*'),this.validatciones.digitoVerificador])],
				nombre:[this.Persona.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(250)])],
				apellido:[this.Persona.apellido,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(250)])],
			});
		}else if(Number(data.target.value)===2){
			this.formPersonaCedula=this.fb.group({
				tipo:[2,Validators.compose([Validators.required])],
				cedula: [this.Persona.cedula,Validators.compose([Validators.required,Validators.minLength(13),Validators.maxLength(13)])],
				razonsocial:[this.Persona.razonsocial,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300)])],
			});
		}else if(Number(data.target.value)===3){
			this.formPersonaCedula=this.fb.group({
				tipo:[1,Validators.compose([Validators.required])],
				cedula: [this.Persona.cedula,Validators.compose([Validators.required,Validators.minLength(5), Validators.maxLength(50), Validators.pattern('[0-9]*'),this.validatciones.digitoVerificador])],
				nombre:[this.Persona.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(250)])],
				apellido:[this.Persona.apellido,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(250)])],
			});
		}
	}

	editPersonaFecha_nacimientoEnable(){
		this.editFecha_nacimiento=true;
		var datePipeFecha_nacimiento = new DatePipe("en-US");
		this.formPersonaFecha_nacimiento=this.fb.group({
			fecha_nacimiento:[datePipeFecha_nacimiento.transform(this.Persona.fecha_nacimiento, 'yyyy-MM-dd'),Validators.compose([Validators.required,CustomValidators.date])],
		});
	}

	editPersonaFecha_nacimientoDisable(){
		this.editFecha_nacimiento=false;
	}

	savePersonaFecha_nacimiento(){
		let PersonaDataModel:Personas=this.formPersonaFecha_nacimiento.value;
		PersonaDataModel.id=this.Persona.id;
		this.Persona.fecha_nacimiento=PersonaDataModel.fecha_nacimiento;
		this.servicioPersona.updatePersonas(PersonaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editFecha_nacimiento=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editPersonaEstado_civilEnable(){
		this.editEstado_civil=true;
		this.formPersonaEstado_civil=this.fb.group({
			estado_civil:[this.Persona.estado_civil,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editPersonaEstado_civilDisable(){
		this.editEstado_civil=false;
	}

	savePersonaEstado_civil(){
		let PersonaDataModel:Personas=this.formPersonaEstado_civil.value;
		PersonaDataModel.id=this.Persona.id;
		this.Persona.estado_civil=PersonaDataModel.estado_civil;
		this.servicioPersona.updatePersonas(PersonaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editEstado_civil=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editPersonaSexoEnable(){
		this.editSexo=true;
		this.formPersonaSexo=this.fb.group({
			sexo:[this.Persona.sexo,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10)])],
		});
	}

	editPersonaSexoDisable(){
		this.editSexo=false;
	}

	savePersonaSexo(){
		let PersonaDataModel:Personas=this.formPersonaSexo.value;
		PersonaDataModel.id=this.Persona.id;
		this.Persona.sexo=PersonaDataModel.sexo;
		this.servicioPersona.updatePersonas(PersonaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editSexo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	sexo(data:any){
		this.formPersonaSexo.get('sexo').setValue(data.target.value);
		this.formPersonaSexo.updateValueAndValidity();
	}

	cambiarUbicacion(data:any){
		if(data.parroquia_id!=null){
			let PersonaDataModel:Personas=this.formPersonaSexo.value;
			PersonaDataModel.id=this.Persona.id;
			this.Persona.parroquia_id=data.parroquia_id;
			this.servicioPersona.updatePersonas(PersonaDataModel).subscribe((data:any)=>{
				if(data.estado){
					this.Noti.setNotification(data).then((datas)=>{
						if(datas){
							this.editSexo=false;
						}
					});
				}else{
					this.Noti.setNotification(data);
				}
			});
		}
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

	editPersonaFacturaremailEnable(){
		this.editFacturaremail=true;
		this.formPersonaFacturaremail=this.fb.group({
			facturaremail:[this.Persona.facturaremail,Validators.compose([Validators.minLength(1), Validators.maxLength(250)])],
		});
	}

	editPersonaFacturaremailDisable(){
		this.editFacturaremail=false;
	}

	savePersonaFacturaremail(){
		let PersonaDataModel:Personas=this.formPersonaFacturaremail.value;
		PersonaDataModel.id=this.Persona.id;
		this.Persona.facturaremail=PersonaDataModel.facturaremail;
		this.servicioPersona.updatePersonas(PersonaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editFacturaremail=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editPersonaBarrioEnable(){
		this.editBarrio=true;
		this.formPersonaBarrio=this.fb.group({
			barrio:[this.Persona.barrio,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50)])],
		});
	}

	editPersonaBarrioDisable(){
		this.editBarrio=false;
	}

	savePersonaBarrio(){
		let PersonaDataModel:Personas=this.formPersonaBarrio.value;
		PersonaDataModel.id=this.Persona.id;
		this.Persona.barrio=PersonaDataModel.barrio;
		this.servicioPersona.updatePersonas(PersonaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editBarrio=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editPersonaEtniaEnable(){
		this.editEtnia=true;
		this.formPersonaEtnia=this.fb.group({
			etnia_id:[this.Persona.etnia_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editPersonaEtniaDisable(){
		this.editEtnia=false;
	}

	savePersonaEtnia(){
		let PersonaDataModel:Personas=this.formPersonaEtnia.value;
		PersonaDataModel.id=this.Persona.id;
		this.Persona.etnia_id=PersonaDataModel.etnia_id;
		
		this.servicioPersona.updatePersonas(PersonaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editEtnia=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editPersonaFormacionacademicaEnable(){
		this.editFormacionacademica=true;
		this.formPersonaFormacionacademica=this.fb.group({
			formacionacademica_id:[this.Persona.formacionacademica_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editPersonaFormacionacademicaDisable(){
		this.editFormacionacademica=false;
	}

	savePersonaFormacionacademica(){
		let PersonaDataModel:Personas=this.formPersonaFormacionacademica.value;
		PersonaDataModel.id=this.Persona.id;
		this.Persona.formacionacademica_id=PersonaDataModel.formacionacademica_id;
		this.servicioPersona.updatePersonas(PersonaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editFormacionacademica=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editPersonaLugarnacimientoEnable(){
		this.editLugarnacimiento=true;
		this.formPersonaLugarnacimiento=this.fb.group({
			lugarnacimiento:[this.Persona.lugarnacimiento,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150)])],
		});
	}

	editPersonaLugarnacimientoDisable(){
		this.editLugarnacimiento=false;
	}

	savePersonaLugarnacimiento(){
		let PersonaDataModel:Personas=this.formPersonaLugarnacimiento.value;
		PersonaDataModel.id=this.Persona.id;
		this.Persona.lugarnacimiento=PersonaDataModel.lugarnacimiento;
		this.servicioPersona.updatePersonas(PersonaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editLugarnacimiento=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editPersonaNacionalidadEnable(){
		this.editNacionalidad=true;
		this.formPersonaNacionalidad=this.fb.group({
			nacionalidad:[this.Persona.nacionalidad,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50)])],
		});
	}

	editPersonaNacionalidadDisable(){
		this.editNacionalidad=false;
	}

	savePersonaNacionalidad(){
		let PersonaDataModel:Personas=this.formPersonaNacionalidad.value;
		PersonaDataModel.id=this.Persona.id;
		this.Persona.nacionalidad=PersonaDataModel.nacionalidad;
		this.servicioPersona.updatePersonas(PersonaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editNacionalidad=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

}

