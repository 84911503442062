import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { parse } from 'svgson';
import { DataSharingImagenService } from '../../servicios/verimagen.services';

@Component({
  selector: '[app-f008-seccion9]',
  templateUrl: './f008-seccion9.component.html',
  styleUrls: ['./f008-seccion9.component.scss']
})
export class F008Seccion9Component implements OnInit {
  @Input() infoFormulacionSeccion: any;
  @Input() infosendParte?: any;
  @Input() pathfield?: any;
  public svgIcon: any;
  verInfoRoot: boolean = false;
  valorItemRoot: number = 0;
  listaMostraInfo = [];

  constructor(private httpClient: HttpClient, private DataSharing: DataSharingImagenService,) {
    if (this.pathfield != undefined) {
      this.infosendParte = [];
      this.infoFormulacionSeccion.lista.forEach(elementgrafico => {
        let infoParte = {} as any;
        for (let k = 0; k < this.infoFormulacionSeccion.motivos.length; k++) {
          const elementgraf = this.infoFormulacionSeccion.motivos[k];
          if (elementgrafico.motivoscita_id == elementgraf.id) {
            infoParte = { numero: elementgrafico.detalle, data: elementgraf, base: 1 } as any;
            break;
          }
        }
        this.infosendParte.push(infoParte);
      });

      this.verInfoRoot = false;
      let itemCompleto = { lista: this.infosendParte, info: undefined } as any;
      this.DataSharing.SharingData.next(itemCompleto);
    }
  }

  ngOnInit() {

    this.leerGrafica(this.pathfield);
  }


  leerGrafica(ruta: string) {
    this.svgIcon = undefined;
    this.infosendParte = [];
    this.httpClient
      .get(ruta, { responseType: 'text' })
      .subscribe((value: any) => {
        parse(value).then((infoJson: any) => {
          let info = infoJson;
          let tmpItem = [];
          for (let index = 0; index < info.children.length; index++) {
            const element = info.children[index];
            let g2: any = element;
            g2.value = index;
            g2.seccion = 0;
            let g1 = { name: 'g', type: "element", value: "", attributes: { "class": "svg-menu__path__seleccion__background" }, children: [g2] } as any;
            let g = {
              name: 'g', type: "element", value: "", attributes: {
                "class": "svg-menu__path__seleccion",
                "cursor": "pointer",
                "pointer-events": "all"
              }, children: [g1]
            } as any;
            tmpItem.push(g);
          }
          info.children = tmpItem;
          this.svgIcon = info;
        })
      });
  }

  seleccionParte(data: any) {
    this.valorItemRoot = data.numero;
    this.verInfoRoot = data.estado;

    let indice = -1;
    let encontrado: boolean = false;
    let base: number = 0;
    for (let index = 0; index < this.infosendParte.length; index++) {
      const element = this.infosendParte[index];
      if (element.numero == data.numero && data.info && element.data.id == data.info.id) {
        indice = index;
        base = element.base;
        encontrado = true;
        break;
      }
    }

    let infoParte = { numero: this.valorItemRoot, data: data.info, base: 0 } as any;
    if (data.info != undefined && !encontrado && base == 0) {
      this.infosendParte.push(infoParte);
    } else if (data.info != undefined && encontrado && base == 0) {
      this.infosendParte.splice(indice, 1);
    }

    this.listaMostraInfo = [];
    this.infosendParte.forEach(element => {

      if ((element.base == 1) && element.numero == data.numero) {
        this.listaMostraInfo.push(element);
      }
    }, this);
  }

  verInfRoot() {
    this.verInfoRoot = !this.verInfoRoot;
  }


}
