import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../../global/global.model';
import {Solicitudexternas} from './solicitudexternas.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class SolicitudexternasServices {

	constructor(private http: HttpClient) { }

	getDataSolicitudexternas():Observable<Solicitudexternas[]>{
		return this.http.get<Solicitudexternas[]>(Globals.BASE_URL_API_REST+'solicitudexternas.json');
	}

	saveSolicitudexternas(data:Solicitudexternas){
		return this.http.post(Globals.BASE_URL_API_REST+'solicitudexternas.json',data);
	}

	deleteSolicitudexternas(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'solicitudexternas/'+id+'.json');
	}

	updateSolicitudexternas(data:Solicitudexternas){
		 return this.http.patch(Globals.BASE_URL_API_REST+'solicitudexternas/'+data.id+'.json',data);
	}

	updateRegistroReferencias(data:Solicitudexternas){
		return this.http.patch(Globals.BASE_URL_API_REST+'registro_medicos_referencias/'+data.id+'.json',data);
   	}

	viewSolicitudexternas(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'solicitudexternas/'+id+'.json');
	}
}