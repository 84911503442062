import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Globals } from '../../../global/global.model';

import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Detallefacturas, Facturas } from './consultas.model';

@Injectable({
  providedIn: 'root'
})
export class ConsultasFacturasService {

  private formData: Facturas = new Facturas();

  constructor(private http: HttpClient) {
    this.formData.detallefacturas = [];
  }


  filtrarFacturasBusquedas(id: number, nombres: string) {
    return this.http.get(Globals.BASE_URL_API_REST + 'lista_facturas_consultas/' + id + '.json?nombres=' + nombres);
  }

  MostrarCitasYExamenesFacturasBusquedas(id: number) {
    return this.http.get(Globals.BASE_URL_API_REST + 'lista_mostrar_parafacturar/' + id + '.json');
  }

  MostrarCitasYExamenesFacturasBusquedasAdmision(data:any) {
    return this.http.put(Globals.BASE_URL_API_REST + 'lista_mostrar_parafacturar_admision.json',data);
  }

  MostrarCitasYExamenesFacturasBusquedasAdmisionPorFechaPago(data:any) {
    return this.http.post(Globals.BASE_URL_API_REST + 'lista_mostrar_parafacturar_admision.json',data);
  }

  deleteAllDetallesFacturas(): void {
    this.formData.detallefacturas=[];
    this.formData.iva=0;
    this.formData.numero=0;
    this.formData.persona_id=0;
  }

  setDetallesFacturasExamenes(data: Detallefacturas[], tipo: number): Facturas[] {
    var detalles: Detallefacturas[] = this.formData.detallefacturas;
    let existe: boolean = false;
    let indexE: number = 0;
    for (let i = 0; i < data.length; i++) {
      const element: Detallefacturas = data[i];
      existe = false;
      indexE = 0;
      for (let j = 0; j < detalles.length; j++) {
        const elementuno = detalles[j];
        if (element.citaexemane_id == elementuno.citaexemane_id) {
          existe = true;
          indexE = j;
          break;
        }
      }

      if (!existe && tipo == 1) {
        detalles.push(data[i]);
      } else if (tipo == 2 && existe) {
        detalles.splice(indexE, 1);
      }

    }

    this.formData.detallefacturas = detalles;

    return this.formData.detallefacturas;

  }

  setDetallesFacturaExamenes(data: Detallefacturas, tipo: number): Facturas[] {
    var detalles: Detallefacturas[] = this.formData.detallefacturas;
    let existe: boolean = false;
    let indexE: number = 0;

    const element: Detallefacturas = data;
    existe = false;
    indexE = 0;
    for (let j = 0; j < detalles.length; j++) {
      const elementuno = detalles[j];
      if (element.citaexemane_id == elementuno.citaexemane_id) {
        existe = true;
        indexE = j;
        break;
      }
    }

    if (!existe && tipo == 1) {
      detalles.push(data);
    } else if (tipo == 2 && existe) {
      detalles.splice(indexE, 1);
    }


    this.formData.detallefacturas = detalles;

    console.log(this.formData);
      
    return this.formData.detallefacturas;

  }

  setDetallesFacturasCitas(data: Detallefacturas[], tipo: number): Facturas[] {
    var detalles: Detallefacturas[] = this.formData.detallefacturas;
    let existe: boolean = false;
    let indexE: number = 0;
    for (let i = 0; i < data.length; i++) {
      const element: Detallefacturas = data[i];
      existe = false;
      indexE = 0;
      for (let j = 0; j < detalles.length; j++) {
        const elementuno = detalles[j];
        if (element.citasmedica_id == elementuno.citasmedica_id) {
          existe = true;
          indexE = j;
          break;
        }
      }

      if (!existe && tipo == 1) {
        detalles.push(data[i]);
      } else if (tipo == 2 && existe) {
        detalles.splice(indexE, 1);
      }

    }

    this.formData.detallefacturas = detalles;

    return this.formData.detallefacturas;

  }

  setDetallesFacturaCitas(data: Detallefacturas, tipo: number): Facturas[] {
    var detalles: Detallefacturas[] = this.formData.detallefacturas;
    let existe: boolean = false;
    let indexE: number = 0;

    const element: Detallefacturas = data;
    existe = false;
    indexE = 0;
    for (let j = 0; j < detalles.length; j++) {
      const elementuno = detalles[j];
      if (element.citasmedica_id == elementuno.citasmedica_id) {
        existe = true;
        indexE = j;
        break;
      }
    }

    if (!existe && tipo == 1) {
      detalles.push(data);
    } else if (tipo == 2 && existe) {
      detalles.splice(indexE, 1);
    }


    this.formData.detallefacturas = detalles;

    console.log(this.formData);
      
    return this.formData.detallefacturas;

  }


  setDetallesFacturaItems(data: any, tipo: number): Facturas[] {
    var detalles: any[] = this.formData.detallefacturas;
    let existe: boolean = false;
    let indexE: number = 0;

    const element: any = data;
    existe = false;
    indexE = 0;
    for (let j = 0; j < detalles.length; j++) {
      const elementuno = detalles[j];
      if (element.itemfijo_id == elementuno.itemfijo_id) {
        existe = true;
        indexE = j;
        break;
      }
    }

    if (!existe && tipo == 1) {
      detalles.push(data);
    } else if (tipo == 2 && existe) {
      detalles.splice(indexE, 1);
    }


    this.formData.detallefacturas = detalles;

    console.log(this.formData);
      
    return this.formData.detallefacturas;

  }


  getFacturaInformacion(){
		var proveedor: Facturas = this.formData;
		let promise = new Promise((resolve, reject) => {
            resolve(proveedor);
        });
        return promise;
	}

  //Factura
  getDataConfirmarVentaFormaPago():Observable<any[]>{
		return this.http.get<any[]>(Globals.BASE_URL_API_REST+'op_forma_pago_venta.json');
	}

  getDataEmpresaConfiguraciones():Observable<any[]>{
		return this.http.get<any[]>(Globals.BASE_URL_API_REST+'listafacturasinfo.json');
	}

  viewTipoComprobante(id:number,codigo:string,local:string){
    return this.http.get(Globals.BASE_URL_API_REST+'buscar_tipo_comprobante/'+id+'/'+codigo+'/'+local+'.json');
	}
  
  viewPersonas(nombres:string){
		return this.http.get(Globals.BASE_URL_API_REST+'op_pacientes_busquedas.json?nombres='+nombres);
	}

	saveFacturas(data:Facturas){
		return this.http.post(Globals.BASE_URL_API_REST+'facturas.json',data);
	}

  upadteFacturas(data:Facturas){
		return this.http.patch(Globals.BASE_URL_API_REST+'facturas/'+data.id+'.json',data);
	}

  upadteFacturasEdicion(data:Facturas){
		return this.http.patch(Globals.BASE_URL_API_REST+'actulizar_factura_informacion/'+data.id+'.json',data);
	}

  viewFacturas(id:number){
		return this.http.get(Globals.BASE_URL_API_REST+'facturas/'+id+'.json');
	}

	saveFactura(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'facturaciones.json',data);
	}

  upadteFacturaciones(data:any){
		return this.http.patch(Globals.BASE_URL_API_REST+'facturaciones/'+data.id+'.json',data);
	}	

  getDataFacturasAdmisionExamenCita(data: any): Observable<Facturas[]> {
		return this.http.post<Facturas[]>(Globals.BASE_URL_API_REST + 'lista_facturas_consultas.json', data);
	}

  getDataFacturasAdmisionExamenCitaFechaPago(data: any): Observable<Facturas[]> {
		return this.http.put<Facturas[]>(Globals.BASE_URL_API_REST + 'lista_facturas_consultas.json', data);
	}

  getDataItemsVentas():Observable<any[]>{
		return this.http.get<any[]>(Globals.BASE_URL_API_REST+'lista_items_static.json');
	}
  

  /////////////

  getDataEmpresaLocalesConfiguraciones():Observable<any[]>{
		return this.http.get<any[]>(Globals.BASE_URL_API_REST+'locales.json');
	}

  listaTipoComprobantes(){
    return this.http.get(Globals.BASE_URL_API_REST+'lista_tipocomprobantes.json');
  }

  listaRetencioneFacturas(data:any){
    return this.http.post(Globals.BASE_URL_API_REST+'buscar_retenciones_facturas.json',data);
  }

  ////

  saveDocumentorentas(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'documentorentas.json',data);
	}

  getDataDocumentorentas(val:number):Observable<any[]>{
		return this.http.get<any[]>(Globals.BASE_URL_API_REST+'lista_documentorentas_factura/'+val+'.json');
	}
  
  updateDocumentorentas(data:any){
    return this.http.patch(Globals.BASE_URL_API_REST+'documentorentas/'+data.id+'.json',data);
 }

}