import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { SolicitudexternasServices } from '../solicitudexterna/solicitudexternas.services';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Solicitudexternas } from '../solicitudexterna/solicitudexternas.model';
import { AddSolicitudexternaComponent } from '../modal/add/addsolicitudexterna.component';
import { EditSolicitudexternaComponent } from '../modal/edit/editsolicitudexterna.component';
import { Personas } from '../../personas/persona/personas.model';

@Component({
	selector: 'ms-solicitudexternavista',
	templateUrl: './solicitudexternavista.component.html',
	styleUrls: ['./solicitudexternavista.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class SolicitudexternasVistaComponent implements OnInit {
	rows = [];
	temp = [];

	iconItems = [{
		text: 'Nuevo',
		icon: 'mdi mdi-note-plus'
	}, {
		text: 'Refescar',
		icon: 'mdi mdi-backup-restore'
	}]

	config: any = {
		size: 'lg',
		centered: true,
		resolve: { datasend: null }
	}


	select(text: string) {
		if (text == 'Refescar') {
			this.listaSolicitudexternas();
		} else if (text == 'Nuevo') {
			this.open();
		}
	}

	constructor(public dialog: NgbModal,
		private serviciosSolicitudexternas: SolicitudexternasServices,
		private notificationServices: NotificationServices,
	) { }

	ngOnInit() {
		this.listaSolicitudexternas();
	}

	listaSolicitudexternas() {
		this.serviciosSolicitudexternas.getDataSolicitudexternas().subscribe((data: any) => {
			this.rows = data.solicitudexternas;
			this.temp = [...this.rows];
		});
	}

	updateFilter(event) {
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function (d) {
			return d.persona.cedula.toLowerCase().indexOf(val) !== -1
				|| (d.persona.nombre && d.persona.nombre.toLowerCase().indexOf(val) !== -1)
				|| (d.persona.apellido && d.persona.apellido.toLowerCase().indexOf(val) !== -1)
				|| (d.persona.razonsocial && d.persona.razonsocial.toLowerCase().indexOf(val) !== -1)
				|| !val;
		});
		this.rows = temp;
	}

	open() {
		let dialogRef = this.dialog.open(AddSolicitudexternaComponent, this.config);
		dialogRef.result.then(
			result => {
				if (result === 1) {
					this.listaSolicitudexternas();
				}
			},
			reason => {
				console.log(reason);
			}
		);
	}

	delete(dataSolicitudexterna: any) {
		let data = { 'val': dataSolicitudexterna.nombre, 'msg': 'Eliminar Tipocitamedicas' };
		this.notificationServices.msgEliminarConfir(data).then((datas) => {
			if (datas) {
				this.serviciosSolicitudexternas.deleteSolicitudexternas(dataSolicitudexterna.id).subscribe((smsdata: any) => {
					if (smsdata.estado) {
						this.notificationServices.setNotification(smsdata).then((datas) => {
							if (datas) {
								this.listaSolicitudexternas();
							}
						});
					} else {
						this.notificationServices.setNotification(smsdata);
					}
				});
			}
		});
	}

	edit(data: any) {
		let dialogRef = this.dialog.open(EditSolicitudexternaComponent, this.config);
		dialogRef.componentInstance.Solicitudexterna = data;
		dialogRef.result.then(
			result => {
				if (result === 1) {
					this.listaSolicitudexternas();
				}
			},
			reason => {
				console.log(reason);
			}
		);
	}

	editEstado(dataSolicitudexterna: Solicitudexternas) {
		let dataEstadoSolicitudexterna = {} as Solicitudexternas;
		dataEstadoSolicitudexterna.id = dataSolicitudexterna.id;
		dataEstadoSolicitudexterna.estado = Number(!dataSolicitudexterna.estado);
		this.serviciosSolicitudexternas.updateSolicitudexternas(dataEstadoSolicitudexterna).subscribe((data: any) => { });
	}

	usuarios(data: any) {

		let datasms = { 'val': data.persona.cedula+' '+data.persona.nombre+' '+data.persona.apellido, 'msg': 'Confirmar consulta de resultados exámenes médicos. A ' };
		this.notificationServices.msgConfir(datasms).then((datas) => {
			if (datas) {

				let SolicitudexternaDataModel = { 'id': data.persona.id, 'cedula': data.persona.cedula, 'nombre': data.persona.nombre, 'email': data.persona.email } as Personas;
				this.serviciosSolicitudexternas.updateRegistroReferencias(SolicitudexternaDataModel).subscribe((data: any) => {
					if (data.estado) {
						this.notificationServices.setNotification(data).then((datas) => {
							if (datas) {
								this.notificationServices.setNotification(data);
								this.listaSolicitudexternas();
							}
						});
					} else {
						this.notificationServices.setNotification(data);
					}
				});
			}
		});
	}
}

