import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { AuthLoginGuard } from './appauth/auth/auth.login.guard';
import { AuthGuard } from './appauth/auth/auth.guard';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadChildren: () => import('./starter/starter.module').then(m => m.StarterModule),
        canActivate: [AuthGuard]
      }
      , {
        path: 'grupos',
        loadChildren: () => import('./appauth/group-pages/groups.module').then(m => m.GroupsModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente'] }
      }, {
        path: 'usuarios',
        loadChildren: () => import('./appauth/user-pages/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard],
      }, {
        path: 'configuracion',
        loadChildren: () => import('./appasclepio/configuracion/configuracion.module').then(m => m.ConfiguracionModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente', 'Administrador', 'Contabilidad'] }
      },
      {
        path: 'ubicacion',
        loadChildren: () => import('./appasclepio/ubicacion/ubicacion.module').then(m => m.UbicacionModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente'] }
      },
      {
        path: 'historia',
        loadChildren: () => import('./appasclepio/historia/historia.module').then(m => m.HistoriaModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente'] }
      },
      {
        path: 'admision',
        loadChildren: () => import('./appasclepio/admiciones/admiciones.module').then(m => m.AdmicionModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente', 'Admisión'] }
      },
      {
        path: 'medico',
        loadChildren: () => import('./appasclepio/medicos/medicos.module').then(m => m.MedicosModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente', 'Medicos'] }
      },
      {
        path: 'cita-medica',
        loadChildren: () => import('./appasclepio/citas-medicas/citas-medicas.module').then(m => m.CitasMedicasModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente', 'Medicos'] }
      },
      {
        path: 'cita-medica-admision',
        loadChildren: () => import('./appasclepio/citas-medicas-admisiones/citas-medicas-admisiones.module').then(m => m.CitasMedicasAdmisionesModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente', 'Admisión'] }
      }, {
        path: 'cita-medica-medico',
        loadChildren: () => import('./appasclepio/citas-medicas-medicos/citas-medicas-medicos.module').then(m => m.CitasMedicasMedicosModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente', 'Medicos'] }
      },
      {
        path: 'cita-medica-enfermeria',
        loadChildren: () => import('./appasclepio/citas-medicas-enfermerias/citas-medicas-enfermerias.module').then(m => m.CitasMedicasEnfermeriasModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente', 'Enfermería'] }
      }, {
        path: 'examen-medico-gerente',
        loadChildren: () => import('./appasclepio/examen-medicos/examen-medicos.module').then(m => m.ExamenMedicosModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente', 'Laboratorios', 'Admisión', 'Enfermería'] }
      }, {
        path: 'examenes-medicos',
        loadChildren: () => import('./appasclepio/examen-medico-gerente/examen-medico-gerente.module').then(m => m.ExamenMedicoGerenteModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente'] }
      }, {
        path: 'historial-citas-medicas',
        loadChildren: () => import('./appasclepio/historial-citas-medicas/historial.citas.medicas.module').then(m => m.HistorialCitasMedicasModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente', 'Medicos'] }
      }, {
        path: 'pedidos',
        loadChildren: () => import('./appinsumos/config-pedidos/config-pedidos.module').then(m => m.ConfigPedidosModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente', 'Farmacia'] }
      }, {
        path: 'empresa',
        loadChildren: () => import('./appinsumos/config/config.module').then(m => m.ConfigModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente', 'Farmacia', 'Administrador'] }
      }, {
        path: 'realizar-pedido',
        loadChildren: () => import('./appinsumos/config-realizar-pedidos/config-realizar-pedidos.module').then(m => m.ConfigRealizarPedidosModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente', 'Farmacia'] }
      }, {
        path: 'bodegas',
        loadChildren: () => import('./appinsumos/config-bodegas/config-bodegas.module').then(m => m.ConfigBodegasModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente', 'Farmacia'] }
      }, {
        path: 'insumos-examenes',
        loadChildren: () => import('./appinsumoexamenes/especialidad-examenes/especialidades.module').then(m => m.EspecialidadExamenInsumosModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente', 'Farmacia', 'Laboratorios'] }
      }, {
        path: 'informes',
        loadChildren: () => import('./appreportes/informes/informes.module').then(m => m.InformesModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente'] }
      }, {
        path: 'info-admisiones',
        loadChildren: () => import('./appreportes/admisiones/informes.admisiones.module').then(m => m.AdmisionesInformesModule),
        canActivate: [AuthGuard],
        data: { roles: ['Admisión'] }
      }, {
        path: 'info-medicos',
        loadChildren: () => import('./appreportes/medicos/informes.medicos.module').then(m => m.MedicosInformesModule),
        canActivate: [AuthGuard],
        data: { roles: ['Medicos'] }
      }, {
        path: 'config-empresa',
        loadChildren: () => import('./appconfig/configuraciones/configuraciones.module').then(m => m.ConfiguracionesModule),
        canActivate: [AuthGuard],
        data: { roles: ['Administrador'] }
      }, {
        path: 'referencias-examenes',
        loadChildren: () => import('./appreferencias/referencia-consultas/referencia-consultas.module').then(m => m.ReferenciaConsultasModule),
        canActivate: [AuthGuard],
        data: { roles: ['Referencias'] }
      }, {
        path: 'facturas-consultas',
        loadChildren: () => import('./appfacturas/busquedas/busquedas.facturas.module').then(m => m.AdmisionesFacturaInformesModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente', 'Admisión', 'Contabilidad'] }
      }, {
        path: 'empresa-config',
        loadChildren: () => import('./appempresa/config/config.module').then(m => m.ConfigEmpresaModule),
        canActivate: [AuthGuard],
        data: { roles: ['Administrador'] }
      }, {
        path: 'facturero-config',
        loadChildren: () => import('./appfactureros/administraciones/facadministracion.module').then(m => m.FacturadosFacurasModule),
        canActivate: [AuthGuard],
        data: { roles: ['Administrador', 'Gerente', 'Contabilidad'] }
      }, {
        path: 'gastos',
        loadChildren: () => import('./appgastos/registros/registros-gastos.module').then(m => m.RegistrosGastosModule),
        canActivate: [AuthGuard],
        data: { roles: ['Administrador', 'Admisión'] }
      }, {
        path: 'plantillas',
        loadChildren: () => import('./appplantillas/facturas-plantillas/facturas-plantillas.module').then(m => m.FacturasPlantillastosModule),
        canActivate: [AuthGuard],
        data: { roles: ['Administrador', 'Contabilidad'] }
      }, {
        path: 'odontograma',
        loadChildren: () => import('./appodontograma/dientesdiesenios/dientesdiesenios.module').then(m => m.DienteDieseniosModule),
        canActivate: [AuthGuard],
        data: { roles: ['Administrador'] }
      },
    ]
  },
  {
    path: '',
    component: BlankComponent,
    canActivate: [AuthLoginGuard],
    children: [
      {
        path: 'inicio',
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/inicio/404'
  }
];
