import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EnfermeriasRoutes } from './enfermerias.routing';
import { EnfermeriasVistaComponent } from './vistas/enfermeriavista.component';
import { EnfermeriasServices } from './enfermeria/enfermerias.services';
import { ModalEnfermeriaModule } from './modal/modalenfermeria.module';
import { ModalPersonaModule } from '../personas/modal/modalpersona.module';
import { EnfermeriaEspecialidadComponent } from './enfermeria-especialidad/enfermaria-especialidad.component';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(EnfermeriasRoutes),
		NgxDatatableModule,
		ModalEnfermeriaModule,
		ModalPersonaModule
	],
	declarations:[
		EnfermeriasVistaComponent,
		EnfermeriaEspecialidadComponent
	],
	providers:[
		EnfermeriasServices
	],
	exports:[
		EnfermeriasVistaComponent
	]

})

export class EnfermeriasModule {}
