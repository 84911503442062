export class Parroquias{
	id?:number;
	nombre?:string;
	cantone_id?:number;
	estado?:number;
	created?:Date;
	modified?:Date;
	zona_id?:number;
}

export class Zonas{
	id?:number;
	codigo?:string;
	nombre?:string;
	estado?:number;
	created?:Date;
	modified?:Date;
}