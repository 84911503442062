import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NotificationServices } from '../../../appauth/notification/notificatio.services';
import { ElementComponentService } from '../servicios/component.servicios';

@Component({
  selector: 'app-poner-hora-fecha-atencion',
  templateUrl: './poner-hora-fecha-atencion.component.html',
  styleUrls: ['./poner-hora-fecha-atencion.component.scss']
})
export class PonerHoraFechaAtencionComponent implements OnInit {
  @Input() Atencion: any;
  current_date: any;
  current_time: any;
  diaAnterior: any;
  constructor(private servicioMedicina: ElementComponentService,
    private notificationServices: NotificationServices,) { }

  ngOnInit() {
    this.diaAnterior = moment().subtract(1, 'day').format("YYYY-MM-DD");
    this.current_time = moment().format("HH:mm")
    this.current_date = moment().format("YYYY-MM-DD");
  }

  saveAtencionesmedicaAnotaciones() {
    let item = { id: this.Atencion.id, fechaevaluacion: this.current_date, horaevaluacion: this.current_time } as any;
    this.servicioMedicina.updateAtencionesmedicasElement(item).subscribe((data: any) => {
      if (data.estado) {
        this.notificationServices.setNotification(data).then((datas) => {
          if (datas) {
            this.Atencion.fechaevaluacion = data.datos.fechaevaluacion;
            let cdt = moment(data.datos.horaevaluacion, 'HH:mm');
            this.Atencion.horaevaluacion = cdt.toDate();
          }
        });
      } else {
        this.notificationServices.setNotification(data);
      }
    });
  }

}
