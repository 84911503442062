import { Component, OnInit,ViewEncapsulation, ViewChild } from '@angular/core';
import { CantonesServices} from '../cantone/cantones.services';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { NotificationServices} from '../../../../appauth/notification/notificatio.services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Cantones } from '../cantone/cantones.model';
import { AddCantoneComponent } from '../modal/add/addcantone.component';
import { EditCantoneComponent } from '../modal/edit/editcantone.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
	selector: 'ms-cantonevista',
	templateUrl:'./cantonevista.component.html',
	styleUrls: ['./cantonevista.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class CantonesVistaComponent implements OnInit {
	page = 1;
	pageSize =20;
	rows = [];
	temp = [];

	iconItems = [{
		text: 'Nuevo',
		icon: 'mdi mdi-note-plus'
	},{
		text: 'Refrescar',
		icon: 'mdi mdi-backup-restore'
	}]

	config: any = {
		size: 'sm',
		centered: true,
		resolve: { datasend: null }
	}


	select(text: string) {
		if(text=='Refrescar'){
			this.listaCantones();
		} else if(text=='Nuevo') {
			this.open();
		}
	}

	constructor(public dialog: NgbModal,
		private serviciosCantones:CantonesServices,
		private notificationServices:NotificationServices,
		private ngxService: NgxUiLoaderService,
	){}

	ngOnInit(){
		this.listaCantones();
	}

	listaCantones(){
		this.ngxService.startLoader('princialLoder');
		this.serviciosCantones.getDataCantones().subscribe((data:any)=>{
			this.rows=data.cantones;
			let info=Math.ceil(this.rows.length/this.pageSize);
			if(info<this.page)
				this.page--;
			this.temp = [...this.rows];
			this.ngxService.stopLoader('princialLoder');
		});
	}

	updateFilter(event){
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function(d){
			return d.nombre.toLowerCase().indexOf(val) !== -1 || d.provincia.nombre.toLowerCase().indexOf(val) !== -1 || !val;
		});
		this.rows = temp;
	}

	open(){
		let dialogRef = this.dialog.open(AddCantoneComponent, this.config);
		dialogRef.result.then(
			result=>{
				if(result){
					this.listaCantones();
				}
			},
			reason=>{
				console.log(reason);
			}
		);
	}

	delete(dataCantone:any){
		let data={'val':dataCantone.nombre,'msg':'Eliminar Cantones'};
		this.notificationServices.msgEliminarConfir(data).then((datas)=>{
			if(datas){
				this.serviciosCantones.deleteCantones(dataCantone.id).subscribe((smsdata:any)=>{
					if(smsdata.estado){
						this.notificationServices.setNotification(smsdata).then((datas)=>{
							if(datas){
								this.listaCantones();
							}
						});
					}else{
						this.notificationServices.setNotification(smsdata);
					}
				});
			}
		});
	}

	edit(data:any){
		let dialogRef = this.dialog.open(EditCantoneComponent, this.config);
		dialogRef.componentInstance.Cantone = data;
		dialogRef.result.then(
			result=>{
				if(result===1){
					this.listaCantones();
				}
			},
			reason=>{
				console.log(reason);
			}
		);
	}

	editEstado(dataCantone:Cantones){
		let dataEstadoCantone= {} as Cantones;
		dataEstadoCantone.id=dataCantone.id;
		dataEstadoCantone.estado=Number(!dataCantone.estado);
		this.serviciosCantones.updateCantones(dataEstadoCantone).subscribe((data:any)=>{});
	}
}

