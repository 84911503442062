import { Component, Input, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataItemsGeneral } from '../../../appasclepio/examen-medicos/citaexemanes/citaexemane/cita.data';
import { Globals } from '../../../global/global.model';
import { ElementComponentService } from '../servicios/component.servicios';

@Component({
  selector: 'app-consulta-externa-informacion-resumen',
  templateUrl: './consulta-externa-informacion-resumen.component.html',
  styleUrls: ['./consulta-externa-informacion-resumen.component.scss']
})
export class ConsultaExternaInformacionResumenComponent implements OnInit {
  @Input() atencionInfoResumen: any;
  rows = [];
  dataInfoAtencionTotal: any;
  listaIngreso = [];
  listaAlta = [];
  imgEmergencia: string = Globals.imagenEmergencia;
  items = DataItemsGeneral.items;
  constructor(private servicioApps: ElementComponentService,
              private ngxService: NgxUiLoaderService,) { }

  ngOnInit() {
    if (this.atencionInfoResumen != undefined) {
      this.buscarEvolucio(this.atencionInfoResumen.id);
    }
  }

  buscarEvolucio(id: number) {
    this.ngxService.startLoader('princialLoder');
    this.servicioApps.viewAtencionCitaMedicaResumen(id).subscribe((data: any) => {
      this.dataInfoAtencionTotal = data.atencionesmedica;
      
      this.listaIngreso = this.dataInfoAtencionTotal.diagnosticos.filter(function (el) {
        return el.tipo == 1;
      });
      this.listaAlta = this.dataInfoAtencionTotal.diagnosticos.filter(function (el) {
        return el.tipo == 2;
      });

      this.servicioApps.viewAtencionCitaMedicaConsultaExamenResumen(this.dataInfoAtencionTotal.id).subscribe((info: any) => {
        this.rows = info.citaexemane;
        this.ngxService.stopLoader('princialLoder');
      });
      
    });
  }

  fraction(value, donly = true) {
    let tolerance = 1.0E-6; // a partir de cuantas decimales se hace el redondeo
    let h1 = 1;
    let h2 = 0;
    let k1 = 0;
    let k2 = 1;
    let negative = false;
    let i: any;

    if (parseInt(value) == value) { // si el valor es un número entero, detener el código
      return value;
    } else if (value < 0) {
      negative = true;
      value = -value;
    }

    if (donly) {
      i = parseInt(value);
      value -= i;
    }

    let b = value;

    do {
      let a = Math.floor(b);
      let aux = h1;
      h1 = a * h1 + h2;
      h2 = aux;
      aux = k1;
      k1 = a * k1 + k2;
      k2 = aux;
      b = 1 / (b - a);
    } while (Math.abs(value - h1 / k1) > value * tolerance);

    return (negative ? "-" : '') + ((donly && (i != 0)) ? i + ' ' : '') + (h1 == 0 ? '' : h1 + "/" + k1);
  }

}
