import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PersonasRoutes } from './personas.routing';
import { PersonasVistaComponent } from './vistas/personavista.component';
import { PersonasServices } from './persona/personas.services';
import { ModalPersonaModule } from './modal/modalpersona.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Especialidad_personalesModule } from '../especialidad_personales/especialidad_personales.module';
import { EmpleadosModule } from '../empleados/empleados.module';
import { EnfermeriasModule } from '../enfermerias/enfermerias.module';
import { SubirInformacionComponent } from './subir-informacion/subir-informacion.component';
import { ParroquiasModule } from '../../ubicacion/parroquias/parroquias.module';
import { CantonesModule } from '../../ubicacion/cantones/cantones.module';
import { ProvinciasModule } from '../../ubicacion/provincias/provincias.module';
import { SolicitudexternasModule } from '../solicitudexternas/solicitudexternas.module';
import { EtniasModule } from '../etnias/etnias.module';
import { FormacionacademicasModule } from '../formacionacademicas/formacionacademicas.module';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(PersonasRoutes),
		NgxDatatableModule,
		ModalPersonaModule,
		NgbModule,
		Especialidad_personalesModule,
		EmpleadosModule,
		EnfermeriasModule,
		ParroquiasModule,
		CantonesModule,
		ProvinciasModule,
		SolicitudexternasModule,
		EtniasModule,
		FormacionacademicasModule
	],
	providers:[
		PersonasServices
	],
	declarations:[
		PersonasVistaComponent,
		SubirInformacionComponent,
	],
})

export class PersonasModule {}
