import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-component-forma-pago',
  templateUrl: './component-forma-pago.component.html',
  styleUrls: ['./component-forma-pago.component.scss']
})
export class ComponentFormaPagoComponent implements OnInit {
  @Input() costo:number=0;
  @Output() newItemEvent = new EventEmitter<string>();
  abono:number=0;
  formaDePagos = [];
  formaDePagosTodo = [];
  constructor() { }

  ngOnInit() {
    console.log(this.costo);
  }

  actualizarFormaDePagos(data: any) {

    let existe: boolean = false;
    for (let index = 0; index < this.formaDePagos.length; index++) {
      const element = this.formaDePagos[index];
      if (element.formapago_id == data.data.formapago_id && element.institucionfinanciera_id == data.data.institucionfinanciera_id) {
        existe = true;
        break;
      }

    }
    if (!existe) {
      data.data.formaPago = data.pago.codigo;
      this.formaDePagos.push(data.data);
      this.formaDePagosTodo.push(data);
      this.abono = this.sumar();
      let dataSend:any={formaPagos:this.formaDePagos};
      this.newItemEvent.emit(dataSend);

    }
  }

  sumar(): number {
    this.abono = 0;
    let sum: number = 0;
    for (let index = 0; index < this.formaDePagos.length; index++) {
      const element = this.formaDePagos[index];
      sum += element.monto;
    }
    return sum;
  }

  eliminar(data: any) {
    let existe: boolean = false;
    let indice: number = -1;
    for (let index = 0; index < this.formaDePagosTodo.length; index++) {
      const element = this.formaDePagosTodo[index].data;
      if (element.formapago_id == data.data.formapago_id && element.institucionfinanciera_id == data.data.institucionfinanciera_id) {
        existe = true;
        indice = index;
        break;
      }
    }
    if (indice != -1) {
      this.formaDePagos.splice(indice, 1);
      this.formaDePagosTodo.splice(indice, 1);
      this.abono = this.sumar();
      let dataSend:any={formaPagos:this.formaDePagos};
      this.newItemEvent.emit(dataSend);
    }
  }

}
