import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[app-f008-seccion5]',
  templateUrl: './f008-seccion5.component.html',
  styleUrls: ['./f008-seccion5.component.scss']
})
export class F008Seccion5Component implements OnInit {
  @Input() infoFormulacionSeccion:any;
  constructor() { }

  ngOnInit() {
  }

}
