import { Component, Input, OnInit } from '@angular/core';
import { ElementComponentService } from '../servicios/component.servicios';

@Component({
  selector: 'app-ver-contro-glicemico',
  templateUrl: './ver-contro-glicemico.component.html',
  styleUrls: ['./ver-contro-glicemico.component.scss']
})
export class VerControGlicemicoComponent implements OnInit {
  @Input() idHospitalizacion?: number;
  @Input() idCita?: number;
  rows: any[]=[];
  constructor(private gricemico:ElementComponentService) { }

  ngOnInit() {
    if(this.idHospitalizacion!=undefined){
      this.listaGlicemicaHospital();
    }
    if(this.idCita!=undefined){
      this.listaGlicemicaCita();
    }
  }

  listaGlicemicaHospital() {
    this.gricemico.viewControlGlicemicoHospitalizaciones(this.idHospitalizacion).subscribe((info: any) => {
      this.rows = info.indicacionesgenerales;
    });
  }

  listaGlicemicaCita() {
    this.gricemico.viewControlGlicemicoCitaMedica(this.idCita).subscribe((info: any) => {
      this.rows = info.indicacionesgenerales;
    });
  }
  
  retornar(row: any): any {
    let lista = [row.persona.nombre, row.persona.apellido];
    let resultado3 = "";
    for (let i = 0; i < lista.length; i++) {
      lista[i].split(' ').reduce((r, w) => resultado3 += w.slice(0, 1), '');
    }
    return { ac: resultado3 };
  }
  
}
