import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Enfermerias } from '../../enfermeria/enfermerias.model';
import { EnfermeriasServices } from '../../enfermeria/enfermerias.services';
import { Personas } from '../../../personas/persona/personas.model';
import { PersonasServices } from '../../../personas/persona/personas.services';
import { Especialidades } from '../../../especialidades/especialidade/especialidades.model';
import { EspecialidadesServices } from '../../../especialidades/especialidade/especialidades.services';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
	selector: 'app-addenfermeria',
	templateUrl: './addenfermeria.component.html',
	styleUrls: ['./addenfermeria.component.scss']
})

export class AddEnfermeriaComponent implements OnInit {
	public dataPersonas:any;
	public dataEspecialidades:any;
	public formAddEnfermeria: FormGroup;
	busqueEnviar:string='';
	@Input() tipoBusqueda:boolean;
	@Input() dataPersona:any;
	@Input() rows=[];

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioEnfermeria:EnfermeriasServices,
		private servicioPersona:PersonasServices,
		private servicioEspecialidade:EspecialidadesServices,
		private ngxService: NgxUiLoaderService,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		
		this.formAddEnfermeria = this.fb.group({
			persona_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			especialidade_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});

		if(this.tipoBusqueda!=undefined){
			this.formAddEnfermeria.get('persona_id').setValue(Number(this.dataPersona.id));
			this.formAddEnfermeria.updateValueAndValidity();

			let ids=[];
			this.rows.forEach(element => {
				ids.push(element.especialidade_id)
			});
			
			this.servicioEspecialidade.getDataEspecialidades().subscribe((data:any)=>{
				this.dataEspecialidades=data.especialidades.filter(function(item){
					return ids.indexOf(item.id) === -1;
				});
			});
		}else{
			this.tipoBusqueda=false;
		}

		this.eviarBusqueda();
	}


	save(){
		let enfermeriaDataModel:Enfermerias=this.formAddEnfermeria.value;
		this.ngxService.startLoader('princialLoder');
		this.servicioEnfermeria.saveEnfermerias(enfermeriaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.ngxService.stopLoader('princialLoder');
						this.activeModal.close(1);
					}
				});
			}else{
				this.ngxService.stopLoader('princialLoder');
				this.Noti.setNotification(data);
			}
		});
	}

	buscarEvento(event){
		const val = event.target.value.toLowerCase();
		this.busqueEnviar=val;
	}
	
	eviarBusqueda(){
		this.ngxService.startLoader('princialLoder');
		this.servicioEnfermeria.viewPersonalEnfermeriaSinAsignar(this.busqueEnviar).subscribe((data:any)=>{
			this.dataPersonas=data.especialidadPersonales;
			this.ngxService.stopLoader('princialLoder');
		});
	}

	seleccionar(data:any){
		this.dataPersona=data;
		this.servicioEnfermeria.viewEnfermerias(this.dataPersona.id).subscribe((data:any)=>{
			this.rows=data.enfermerias;
		});
		let ids=[];
		this.rows.forEach(element => {
			ids.push(element.especialidade_id)
		});
		  
		this.servicioEspecialidade.getDataEspecialidades().subscribe((data:any)=>{
			this.dataEspecialidades=data.especialidades.filter(function(item){
				return ids.indexOf(item.id) === -1;
			  });
		});
		this.formAddEnfermeria.get('persona_id').setValue(Number(data.id));
		this.formAddEnfermeria.updateValueAndValidity();
		this.tipoBusqueda=true;
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}