import { Component, OnInit,ViewEncapsulation, ViewChild } from '@angular/core';
import { EnfermeriasServices} from '../enfermeria/enfermerias.services';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { NotificationServices} from '../../../../appauth/notification/notificatio.services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Enfermerias } from '../enfermeria/enfermerias.model';
import { AddEnfermeriaComponent } from '../modal/add/addenfermeria.component';
import { EditEnfermeriaComponent } from '../modal/edit/editenfermeria.component';
import { Empleados } from '../../empleados/empleado/empleados.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
	selector: 'ms-enfermeriavista',
	templateUrl:'./enfermeriavista.component.html',
	styleUrls: ['./enfermeriavista.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class EnfermeriasVistaComponent implements OnInit {
	rows = [];
	temp = [];

	timeout: any;
	@ViewChild('tableEnfermeria', { static: false }) table: any;

	iconItems = [{
		text: 'Nuevo',
		icon: 'mdi mdi-note-plus'
	},{
		text: 'Refrescar',
		icon: 'mdi mdi-backup-restore'
	}]

	config: any = {
		size: 'lg',
		centered: true,
		resolve: { datasend: null }
	}


	select(text: string) {
		if(text=='Refrescar'){
			this.listaEnfermerias();
		} else if(text=='Nuevo') {
			this.open();
		}
	}

	constructor(public dialog: NgbModal,
		private serviciosEnfermerias:EnfermeriasServices,
		private notificationServices:NotificationServices,
		private ngxService: NgxUiLoaderService,
	){}

	ngOnInit(){
		this.listaEnfermerias();
	}

	listaEnfermerias(){
		this.ngxService.startLoader('princialLoder');
		this.serviciosEnfermerias.getDataEnfermerias().subscribe((data:any)=>{
			this.rows=data.enfermerias;
			this.temp = [...this.rows];
			this.ngxService.stopLoader('princialLoder');
		});
	}

	updateFilter(event){
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function(d){
			return d.nombre.toLowerCase().indexOf(val) !== -1 || !val;
		});
		this.rows = temp;
	}

	open(){
		let dialogRef = this.dialog.open(AddEnfermeriaComponent, this.config);
		dialogRef.result.then(
			result=>{
				if(result===1){
					this.listaEnfermerias();
				}
			},
			reason=>{
				console.log(reason);
			}
		);
	}

	delete(dataEnfermeria:any){
		let data={'val':dataEnfermeria.estado,'msg':'Eliminar Personas'};
		this.notificationServices.msgEliminarConfir(data).then((datas)=>{
			if(datas){
				this.serviciosEnfermerias.deleteEnfermerias(dataEnfermeria.id).subscribe((smsdata:any)=>{
					if(smsdata.estado){
						this.notificationServices.setNotification(smsdata).then((datas)=>{
							if(datas){
								this.listaEnfermerias();
							}
						});
					}else{
						this.notificationServices.setNotification(smsdata);
					}
				});
			}
		});
	}

	edit(data:any){
		let dialogRef = this.dialog.open(EditEnfermeriaComponent, this.config);
		dialogRef.componentInstance.Enfermeria = data;
		dialogRef.result.then(
			result=>{
				if(result===1){
					this.listaEnfermerias();
				}
			},
			reason=>{
				console.log(reason);
			}
		);
	}

	editEstado(dataEnfermeria:Enfermerias){
		let dataEstadoEmpleado= {} as Enfermerias;
		dataEstadoEmpleado.estado=Number(!dataEnfermeria.estado);
		this.serviciosEnfermerias.updateEnfermerias(dataEstadoEmpleado).subscribe((data:any)=>{});
	}

	onPage(event) {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
		  //console.log('paged!', event);
		}, 100);
	  }
	
	  toggleExpandRow(row, i) {
		this.table.rowDetail.toggleExpandRow(row);
	  }
	
	  onDetailToggle(event) {
		//console.log('Detail Toggled', event);
	  }
	
}

