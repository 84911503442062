import { Component, Input, OnInit } from '@angular/core';
import { ElementComponentService } from '../../servicios/component.servicios';

@Component({
  selector: '[app-f008-seccion6]',
  templateUrl: './f008-seccion6.component.html',
  styleUrls: ['./f008-seccion6.component.scss']
})
export class F008Seccion6Component implements OnInit {
  @Input() infoFormulacionSeccion:any;
  listaagrupada = [];
  groupLista: any;
  constructor(private servicioApps: ElementComponentService,) { }

  ngOnInit() {
    this.groupLista = this.groupBy(this.infoFormulacionSeccion.lista, row => row.codigo);
    this.listaagrupada = [];
        for (let [key, value] of this.groupLista) {
          this.servicioApps.getDataCitasFormularioMotivo('FORM008', 6).subscribe((info: any) => {
            let listatmp = info.motivoscitas;
            listatmp.forEach(element => {
              element.seleccion = 0;
              element.visualizar = false;
              value.forEach(elementasignado => {
                element.codigogrupo = elementasignado.codigo;
                if (element.id == elementasignado.motivoscita_id) {
                  element.guardado = 1;
                  element.seleccion = 1;
                  element.visualizar = true;
                  element.detalle = elementasignado.detalle;
                  element.valor = elementasignado.valor;
                  element.citasmedicainformacione_id = elementasignado.id;
                }
              });
            });
            this.listaagrupada.push(listatmp);
          });
        }
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

}
