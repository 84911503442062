import { Component, Input, OnInit } from '@angular/core';
import { ElementComponentService } from '../servicios/component.servicios';

@Component({
  selector: '[app-lista-medicina-complementaria]',
  templateUrl: './lista-medicina-complementaria.component.html',
  styleUrls: ['./lista-medicina-complementaria.component.scss']
})
export class ListaMedicinaComplementariaComponent implements OnInit {
  @Input() idIndicacione:number;
  rows: any[]=[];
  constructor(private servicioConsultas:ElementComponentService,) { }

  ngOnInit() {
    this.servicioConsultas.getDataComplementomedicamentos(this.idIndicacione).subscribe((info:any)=>{
      this.rows=info.complementomedicamentos;
    })
  }

}
