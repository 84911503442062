import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-medicamento-control-valores-resumen',
  templateUrl: './medicamento-control-valores-resumen.component.html',
  styleUrls: ['./medicamento-control-valores-resumen.component.scss']
})
export class MedicamentoControlValoresResumenComponent implements OnInit {
  @Input() items: any;
  @Input() tamanio: number = 0;
  @Input() k: number = 0;
  @Output() clickEvent = new EventEmitter();
  @Input() Indicacione: any;

  config: any = {
    size: 'md',
    centered: true,
    resolve: { datasend: null }
  }

  constructor(public dialog: NgbModal) { }

  ngOnInit() { 
  }

  retornar(row:any):any{
    let lista = [row.persona.nombre, row.persona.apellido];
    let resultado3 = "";
    for (let i = 0; i < lista.length; i++){
      lista[i].split(' ').reduce((r,w)=> resultado3+=w.slice(0,1),'');
    }
    return  {ac:resultado3};
  } 

  

}
