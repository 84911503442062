import { Routes } from '@angular/router';
import { AuthGuard } from '../../../appauth/auth/auth.guard';
import { Especialidad_personalesVistaComponent } from './vistas/especialidad_personalevista.component';
import { EspecialidadesMedicosComponent } from './especialidades-medicos/especialidades-medicos.component';

export const Especialidad_personalesRoutes: Routes = [{
	path: '',
	redirectTo: 'index',
	pathMatch: 'full',
	canActivate: [AuthGuard]
},{
	path: '',
	children: [{
		path: 'index',
		component: Especialidad_personalesVistaComponent,
		canActivate: [AuthGuard]
	}]
},{
	path: '',
	children: [{
		path: 'medicos-especialidades',
		component: EspecialidadesMedicosComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Lista de médicos por especialidad',
			roles: ['Gerente'],
			urls: [
			  { title: 'Especialidades', url: '/configuracion/config-especialidades' },
			  { title: 'Página de especilidades' }
			]
		},
	}]
}];
