import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[app-f008-seccion10]',
  templateUrl: './f008-seccion10.component.html',
  styleUrls: ['./f008-seccion10.component.scss']
})
export class F008Seccion10Component implements OnInit {
  @Input() infoFormulacionSeccion: any;
  constructor() { }

  ngOnInit() {
  }

}
