import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Especialidad_personalesRoutes } from './especialidad_personales.routing';
import { Especialidad_personalesVistaComponent } from './vistas/especialidad_personalevista.component';
import { ModalEspecialidad_personaleModule } from './modal/modalespecialidad_personale.module';
import { Especialidad_personalesServices } from './especialidad_personale/especialidad_personales.services';
import { ModalPersonaModule } from '../personas/modal/modalpersona.module';
import { MedicosEspecialidadComponent } from './medicos-especialidad/medicos-especialidad.component';
import { EspecialidadesMedicosComponent } from './especialidades-medicos/especialidades-medicos.component';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(Especialidad_personalesRoutes),
		NgxDatatableModule,
		ModalEspecialidad_personaleModule,
		ModalPersonaModule
	],
	providers:[
		Especialidad_personalesServices
	],
	exports:[
		Especialidad_personalesVistaComponent,
		MedicosEspecialidadComponent
	],
	declarations:[
		Especialidad_personalesVistaComponent,
		MedicosEspecialidadComponent,
		EspecialidadesMedicosComponent,
	],
})

export class Especialidad_personalesModule {}
