import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Globals } from '../../../global/global.model';

import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Facturas } from '../../busquedas/servicios/consultas.model';

@Injectable({
	providedIn: 'root'
})
export class AdministracionesFacturasService {

	private formData: Facturas = new Facturas();

	constructor(private http: HttpClient) {
		this.formData.detallefacturas = [];
	}

	setDataFactura(data:any){
		this.formData=data;
	}

	getDataFactura(){
		var proveedor: Facturas = this.formData;
		let promise = new Promise((resolve, reject) => {
            resolve(proveedor);
        });
        return promise;
	}

	getDataFacturas(data: any): Observable<Facturas[]> {
		return this.http.put<Facturas[]>(Globals.BASE_URL_API_REST + 'lista_facturados_examcitas.json', data);
	}

	getDataFacturasAdmision(data: any): Observable<Facturas[]> {
		return this.http.put<Facturas[]>(Globals.BASE_URL_API_REST + 'lista_facturados_examcitas.json', data);
	}

	getDataFacturasPlantillasExel(data: any): Observable<Facturas[]> {
		return this.http.put<Facturas[]>(Globals.BASE_URL_API_REST + 'lista_facturados_plantillas.json', data);
	}

	saveFacturas(data: Facturas) {
		return this.http.post(Globals.BASE_URL_API_REST + 'facturas.json', data);
	}

	deleteFacturas(id: number) {
		return this.http.delete(Globals.BASE_URL_API_REST + 'facturas/' + id + '.json');
	}

	updateFacturas(data: Facturas) {
		return this.http.patch(Globals.BASE_URL_API_REST + 'facturas/' + data.id + '.json', data);
	}

	viewFacturas(id: number) {
		return this.http.get(Globals.BASE_URL_API_REST + 'facturas/' + id + '.json');
	}

	buscarComprobantesPermisos(data: Facturas) {
		return this.http.put(Globals.BASE_URL_API_REST + 'lista_permisos_facturas.json', data);
	}

	buscarComprobantesEstado(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'lista_permisos_facturas.json',data);
	}
	
}