import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Globals } from '../../global/global.model';
import { localStorageServices } from '../auth/localStorageServices';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { AuthServices } from '../auth/auth.services';
import { Router } from '@angular/router';
import { Groups } from '../group-pages/groups/groups.model';


@Injectable()
export class SideBarMenuServices {
    userdata: any;
    constructor(private http: HttpClient,
        public authServices: AuthServices,
        private router: Router, 
        private local: localStorageServices) { }
    getmenu() {
        this.userdata = this.local.getValueFromLocalStorage();
        console.log(this.userdata);
        let dataSend={id:0} as Groups;
        if (this.userdata.grupo == undefined) {
            this.authServices.logout().subscribe((data: any) => {
                if (data.message.estado) {
                    this.local.removeValueFromLocalStorage();
                    this.router.navigate(['inicio/login']);
                }
            });
        }else{
            dataSend=this.userdata.grupo.group;
        }

        return this.http.post(Globals.BASE_URL_API_REST + 'menusidebar.json', dataSend);

    }
}
