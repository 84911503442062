import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Especialidad_personales } from '../../especialidad_personale/especialidad_personales.model';
import { Especialidad_personalesServices } from '../../especialidad_personale/especialidad_personales.services';
import { Personas } from '../../../personas/persona/personas.model';
import { PersonasServices } from '../../../personas/persona/personas.services';
import { Especialidades } from '../../../especialidades/especialidade/especialidades.model';
import { EspecialidadesServices } from '../../../especialidades/especialidade/especialidades.services';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
	selector: 'app-addespecialidad_personale',
	templateUrl: './addespecialidad_personale.component.html',
	styleUrls: ['./addespecialidad_personale.component.scss']
})

export class AddEspecialidad_personaleComponent implements OnInit {
	public dataPersonas:any;
	public dataEspecialidades:any;
	public formAddEspecialidad_personale: FormGroup;
	busqueEnviar:string='';
	@Input() tipoBusqueda:boolean;
	@Input() dataPersona:any;
	@Input() rows=[];

	@ViewChild('sectorSelect', { static: false }) select: any;
	
	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioEspecialidad_personale:Especialidad_personalesServices,
		private servicioPersona:PersonasServices,
		private servicioEspecialidade:EspecialidadesServices,
		private ngxService: NgxUiLoaderService,
		private Noti:NotificationServices,) { }

	ngOnInit() {

		this.formAddEspecialidad_personale = this.fb.group({
			persona_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			especialidade_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});

		if(this.tipoBusqueda!=undefined){
			this.formAddEspecialidad_personale.get('persona_id').setValue(Number(this.dataPersona.id));
			this.formAddEspecialidad_personale.updateValueAndValidity();

			let ids=[];
			this.rows.forEach(element => {
				ids.push(element.especialidade_id)
			});
			
			this.servicioEspecialidade.getDataEspecialidades().subscribe((data:any)=>{
				this.dataEspecialidades=data.especialidades.filter(function(item){
					return ids.indexOf(item.id) === -1;
				});
			});
		}else{
			this.tipoBusqueda=false;
		}

		this.eviarBusqueda();
		
	}


	save(){
		let especialidad_personaleDataModel:Especialidad_personales=this.formAddEspecialidad_personale.value;
		this.ngxService.startLoader('princialLoder');
		this.servicioEspecialidad_personale.saveEspecialidad_personales(especialidad_personaleDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.servicioEspecialidad_personale.viewEspecialidad_personales(this.dataPersona.id).subscribe((data:any)=>{
							this.rows=data.especialidadPersonale;

							let ids=[];
							this.rows.forEach(element => {
								ids.push(element.especialidade_id)
							});
			
							this.dataEspecialidades=this.dataEspecialidades.filter(function(item){
								return ids.indexOf(item.id) === -1;
							});
							this.ngxService.stopLoader('princialLoder');
							this.select.handleClearClick();
						});
						
					}
				});
			}else{
				this.ngxService.stopLoader('princialLoder');
				this.Noti.setNotification(data);
			}
		});
	}

	buscarEvento(event){
		const val = event.target.value.toLowerCase();
		this.busqueEnviar=val;
	}
	
	eviarBusqueda(){
		this.ngxService.startLoader('princialLoder');
		this.servicioEspecialidad_personale.viewPersonalMedicoSinAsignar(this.busqueEnviar).subscribe((data:any)=>{
			this.dataPersonas=data.especialidadPersonales;
			this.ngxService.stopLoader('princialLoder');
		});
	}

	seleccionar(data:any){
		this.dataPersona=data;
		this.servicioEspecialidad_personale.viewEspecialidad_personales(this.dataPersona.id).subscribe((data:any)=>{
			this.rows=data.especialidadPersonale;
		});
		let ids=[];
		this.rows.forEach(element => {
			ids.push(element.especialidade_id)
		});
		  
		this.servicioEspecialidade.getDataEspecialidades().subscribe((data:any)=>{
			this.dataEspecialidades=data.especialidades.filter(function(item){
				return ids.indexOf(item.id) === -1;
			  });
		});
		this.formAddEspecialidad_personale.get('persona_id').setValue(Number(data.id));
		this.formAddEspecialidad_personale.updateValueAndValidity();
		this.tipoBusqueda=true;
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
	
}