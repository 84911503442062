import { Routes } from '@angular/router';
import { AuthGuard } from '../../../appauth/auth/auth.guard';
import { PersonasVistaComponent } from './vistas/personavista.component';

export const PersonasRoutes: Routes = [{
	path: '',
	redirectTo: 'medicos-index',
	pathMatch: 'full',
	canActivate: [AuthGuard]
},{
	path: '',
	children: [{
		path: 'medicos-index',
		component: PersonasVistaComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Lista de personas',
			roles: ['Gerente'],
			urls: [
			  { title: 'Home', url: '/home' },
			  { title: 'Página de inicio' }
			]
		},
	}]
}];
