import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Personas } from '../../persona/personas.model';
import { PersonasServices } from '../../persona/personas.services';
import { customValidatorMondogo } from '../../../../../appasclepio/custom-validator/customValidator.services';
import { PrefijosServices } from '../../../prefijos/prefijo/prefijos.services';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EtniasServices } from '../../../etnias/etnia/etnias.services';
import { FormacionacademicasServices } from '../../../formacionacademicas/formacionacademica/formacionacademicas.services';

@Component({
	selector: 'app-addpersona',
	templateUrl: './addpersona.component.html',
	styleUrls: ['./addpersona.component.scss']
})

export class AddPersonaComponent implements OnInit {
	public formAddPersona: FormGroup;
	tipoForm: number;
	public dataPrefijos: any;
	public dataEtnias: any;
	public dataFormacionAcademicas: any;
	sexoTipo: string = 'M';

	dataEstadoCivil = [
		{ id: 1, nombre: 'Soltero(a)' },
		{ id: 2, nombre: 'Unión de hecho' },
		{ id: 3, nombre: 'Casado(a)' },
		{ id: 4, nombre: 'Divorciado(a)' },
		{ id: 5, nombre: 'Viudo(a)' }
	]

	constructor(private fb: FormBuilder,
		private activeModal: NgbActiveModal,
		private servicioPersona: PersonasServices,
		private serivcioPrefijo: PrefijosServices,
		private serivcioEtnias: EtniasServices,
		private serivcioFormacionAcademicas: FormacionacademicasServices,
		private validatciones: customValidatorMondogo,
		private ngxService: NgxUiLoaderService,
		private Noti: NotificationServices,) { }

	ngOnInit() {
		this.tipoForm = 1;
		this.serivcioPrefijo.busquedaFiltros().subscribe((data: any) => {
			this.dataPrefijos = data.prefijos;
		});
		this.serivcioEtnias.getDataEtnias().subscribe((data: any) => {
			this.dataEtnias = data.etnias;
		});
		this.serivcioFormacionAcademicas.getDataFormacionacademicas().subscribe((data: any) => {
			this.dataFormacionAcademicas = data.formacionacademicas;
		});
		this.formAddPersona = this.fb.group({
			tipo: [1, Validators.compose([Validators.required, CustomValidators.number])],
			cedula: [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10), this.validatciones.digitoVerificador])],
			nombre: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(250)])],
			apellido: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(250)])],
			direccion: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(300)])],
			prefijo: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(10)])],
			telefono: [null, Validators.compose([Validators.minLength(9), Validators.maxLength(9)])],
			telefono_trabajo: [null, Validators.compose([Validators.minLength(9), Validators.maxLength(9)])],
			celular: [null, Validators.compose([Validators.minLength(10), Validators.maxLength(10)])],
			celular_trabajo: [null, Validators.compose([Validators.minLength(10), Validators.maxLength(10)])],
			email: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(250), CustomValidators.email])],
			estado: [1, null],
			telefono_trabajo_dos: [null, Validators.compose([Validators.minLength(9), Validators.maxLength(9)])],
			celular_trabajo_dos: [null, Validators.compose([Validators.minLength(10), Validators.maxLength(10)])],
			fecha_nacimiento: [null, Validators.compose([Validators.required, CustomValidators.date])],
			sexo: [this.sexoTipo, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(1)])],
			estado_civil: [null, Validators.compose([Validators.required, CustomValidators.number])],
			parroquia_id: [null, Validators.compose([Validators.required, CustomValidators.number])],
			etnia_id: [null, Validators.compose([CustomValidators.number])],
			formacionacademica_id: [null, Validators.compose([CustomValidators.number])],
			barrio: [null, Validators.compose([Validators.minLength(1), Validators.maxLength(50)])],
			lugarnacimiento: [null, Validators.compose([Validators.minLength(1), Validators.maxLength(150)])],
			nacionalidad: ['ECUATORIANA', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(50)])],
		});
	}


	save() {
		let personaDataModel: Personas = this.formAddPersona.value;
		this.ngxService.startLoader('princialLoder');
		this.servicioPersona.savePersonas(personaDataModel).subscribe((data: any) => {
			if (data.estado) {
				this.Noti.setNotification(data).then((datas) => {
					if (datas) {
						this.ngxService.stopLoader('princialLoder');
						this.activeModal.close(data);
					}
				});
			} else {
				this.ngxService.stopLoader('princialLoder');
				this.Noti.setNotification(data);

			}
		});
	}

	tipo(data: any) {

		this.tipoForm = data.target.value;
		if (Number(data.target.value) === 1) {
			this.formAddPersona = this.fb.group({
				tipo: [1, Validators.compose([Validators.required, CustomValidators.number])],
				cedula: [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10), this.validatciones.digitoVerificador])],
				nombre: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(250)])],
				apellido: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(250)])],
				direccion: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(300)])],
				prefijo: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(10)])],
				telefono: [null, Validators.compose([Validators.minLength(9), Validators.maxLength(9)])],
				telefono_trabajo: [null, Validators.compose([Validators.minLength(9), Validators.maxLength(9)])],
				celular: [null, Validators.compose([Validators.minLength(10), Validators.maxLength(10)])],
				celular_trabajo: [null, Validators.compose([Validators.minLength(10), Validators.maxLength(10)])],
				email: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(250), CustomValidators.email])],
				estado: [1, null],
				telefono_trabajo_dos: [null, Validators.compose([Validators.minLength(9), Validators.maxLength(9)])],
				celular_trabajo_dos: [null, Validators.compose([Validators.minLength(10), Validators.maxLength(10)])],
				fecha_nacimiento: [null, Validators.compose([Validators.required, CustomValidators.date])],
				sexo: [this.sexoTipo, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(1)])],
				estado_civil: [null, Validators.compose([Validators.required, CustomValidators.number])],
				parroquia_id: [null, Validators.compose([Validators.required, CustomValidators.number])],
				etnia_id: [null, Validators.compose([CustomValidators.number])],
				formacionacademica_id: [null, Validators.compose([CustomValidators.number])],
				barrio: [null, Validators.compose([Validators.minLength(1), Validators.maxLength(50)])],
				lugarnacimiento: [null, Validators.compose([Validators.minLength(1), Validators.maxLength(150)])],
				nacionalidad: ['ECUATORIANA', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(50)])],
			});

		} else if (Number(data.target.value) === 2) {

			this.formAddPersona = this.fb.group({
				tipo: [2, Validators.compose([Validators.required, CustomValidators.number])],
				cedula: [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(13)])],
				razonsocial: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(300)])],
				direccion: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(300)])],
				prefijo: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(10)])],
				telefono: [null, Validators.compose([Validators.minLength(9), Validators.maxLength(9)])],
				telefono_trabajo: [null, Validators.compose([Validators.minLength(9), Validators.maxLength(9)])],
				celular: [null, Validators.compose([Validators.minLength(10), Validators.maxLength(10)])],
				celular_trabajo: [null, Validators.compose([Validators.minLength(10), Validators.maxLength(10)])],
				email: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(250), CustomValidators.email])],
				estado: [1, null],
				telefono_trabajo_dos: [null, Validators.compose([Validators.minLength(9), Validators.maxLength(9)])],
				celular_trabajo_dos: [null, Validators.compose([Validators.minLength(10), Validators.maxLength(10)])],
				fecha_nacimiento: [null, Validators.compose([Validators.required, CustomValidators.date])],
				sexo: [this.sexoTipo, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(1)])],
				estado_civil: [null, Validators.compose([Validators.required, CustomValidators.number])],
				parroquia_id: [null, Validators.compose([Validators.required, CustomValidators.number])],
				etnia_id: [null, Validators.compose([CustomValidators.number])],
				formacionacademica_id: [null, Validators.compose([CustomValidators.number])],
				barrio: [null, Validators.compose([Validators.minLength(1), Validators.maxLength(50)])],
				lugarnacimiento: [null, Validators.compose([Validators.minLength(1), Validators.maxLength(150)])],
				nacionalidad: ['ECUATORIANA', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(50)])],
			});

		} else if (Number(data.target.value) === 3) {

			this.formAddPersona = this.fb.group({
				tipo: [3, Validators.compose([Validators.required, CustomValidators.number])],
				cedula: [null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(50)])],
				nombre: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(250)])],
				apellido: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(250)])],
				direccion: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(300)])],
				prefijo: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(10)])],
				telefono: [null, Validators.compose([Validators.minLength(9), Validators.maxLength(9)])],
				telefono_trabajo: [null, Validators.compose([Validators.minLength(9), Validators.maxLength(9)])],
				celular: [null, Validators.compose([Validators.minLength(10), Validators.maxLength(10)])],
				celular_trabajo: [null, Validators.compose([Validators.minLength(10), Validators.maxLength(10)])],
				email: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(250), CustomValidators.email])],
				estado: [1, null],
				telefono_trabajo_dos: [null, Validators.compose([Validators.minLength(9), Validators.maxLength(9)])],
				celular_trabajo_dos: [null, Validators.compose([Validators.minLength(10), Validators.maxLength(10)])],
				fecha_nacimiento: [null, Validators.compose([Validators.required, CustomValidators.date])],
				sexo: [this.sexoTipo, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(1)])],
				estado_civil: [null, Validators.compose([Validators.required, CustomValidators.number])],
				parroquia_id: [null, Validators.compose([Validators.required, CustomValidators.number])],
				etnia_id: [null, Validators.compose([CustomValidators.number])],
				formacionacademica_id: [null, Validators.compose([CustomValidators.number])],
				barrio: [null, Validators.compose([Validators.minLength(1), Validators.maxLength(50)])],
				lugarnacimiento: [null, Validators.compose([Validators.minLength(1), Validators.maxLength(150)])],
				nacionalidad: ['ECUATORIANA', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(50)])],
			});
		}
	}

	sexo(data: any) {
		//console.log(data.target.value);
		this.sexoTipo = data.target.value;
		this.formAddPersona.get('sexo').setValue(data.target.value);
		this.formAddPersona.updateValueAndValidity();
	}

	cambiarUbicacion(data: any) {
		this.formAddPersona.get('parroquia_id').setValue(data.parroquia_id);
		this.formAddPersona.updateValueAndValidity();
	}

	cerrarModal() {
		this.activeModal.close(2);
	}

	email(data: any) {
		this.formAddPersona.get('email').setValue(data + '@natger.com');
		this.formAddPersona.updateValueAndValidity();
	}

	validar() {
		console.log(this.formAddPersona.value.cedula);
		if (this.formAddPersona.value.cedula) {
			if (this.formAddPersona.value.cedula.length <= 9 && this.tipoForm === 1) {
				let datasms = { 'val': '', 'msg': { tipo: 'warning', tipomsg: 'Ingrese una cédula de 10 digitos' } };
				this.Noti.setNotification(datasms);
			} else if (this.formAddPersona.value.cedula.length <= 12 && this.tipoForm === 2) {
				let datasms = { 'val': '', 'msg': { tipo: 'warning', tipomsg: 'Ingrese un ruc de 13 digitos' } };
				this.Noti.setNotification(datasms);
			} else if (this.formAddPersona.value.cedula.length <= 5 && this.tipoForm === 3) {
				let datasms = { 'val': '', 'msg': { tipo: 'warning', tipomsg: 'Ingrese una pasaporte' } };
				this.Noti.setNotification(datasms);
			} else {
				this.servicioPersona.getexisteserver({ 'cedula': this.formAddPersona.value.cedula }).subscribe((info: any) => {
					if (info.existe) {
						let datasms = { 'val': '', 'msg': { tipo: 'warning', tipomsg: 'Ya esta registrado' } };
						this.Noti.setNotification(datasms);
						this.activeModal.close(2);
					}
				});
			}
		} else {
			let datasms = { 'val': '', 'msg': { tipo: 'warning', tipomsg: 'Ingrese una cédula' } };
			this.Noti.setNotification(datasms);
		}
	}
}