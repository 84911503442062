import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthModule } from './auth/auth.module';
import {UserServices} from './user-pages/user/users.services';
import {AuthInterceptor} from './interceptors/authInterceptor';
import {HTTP_INTERCEPTORS,HttpClientModule} from '@angular/common/http';
import {AuthGuard} from './auth/auth.guard';
import {AuthLoginGuard} from './auth/auth.login.guard';
import {SideBarMenuServices} from './sidebarmenu/sidebarmenu.services';
import {NotificationServices} from './notification/notificatio.services';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import {UserAddModalModule} from './user-pages/user-modal/usermodal.module';
import { CerrarErrorComponent } from './cerrar-error/cerrar-error.component';


@NgModule({
  imports: [
    CommonModule,
    AuthModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    UserAddModalModule,
  ],
  entryComponents:[CerrarErrorComponent],
  declarations: [
  CerrarErrorComponent],
  providers:[
    {
      provide:HTTP_INTERCEPTORS,
      useClass:AuthInterceptor,
      multi:true
    },
    UserServices,
    NotificationServices,
    AuthGuard,
    AuthLoginGuard,
    SideBarMenuServices
  ]
})

export class AppauthModule {}
