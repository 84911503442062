import { Component, Input, OnInit } from '@angular/core';
import { ElementComponentService } from '../servicios/component.servicios';

@Component({
  selector: 'app-informacion-paciente-cita',
  templateUrl: './informacion-paciente-cita.component.html',
  styleUrls: ['./informacion-paciente-cita.component.scss']
})
export class InformacionPacienteCitaComponent implements OnInit {
  @Input() idCitaMedica: number;
  @Input() idHistoriaMedica: number;
  @Input() numero: number = 0;
  @Input() title: string = "";
  @Input() tipo:number=0;
  dataInfoHistoriaResumen: any;
  verInfoCita: boolean = true;

  constructor(private servicioApps: ElementComponentService) { }

  ngOnInit() {
    this.servicioApps.getDataViewHistoriaclinicas(this.idHistoriaMedica).subscribe((data: any) => {
      this.dataInfoHistoriaResumen = data.historiasclinica;
      this.dataInfoHistoriaResumen.persona.apellido = this.dataInfoHistoriaResumen.persona.apellido.split(' ');
      this.dataInfoHistoriaResumen.persona.nombre = this.dataInfoHistoriaResumen.persona.nombre.split(' ');
    });
  }

  verInfoCitaDetalle() {
    if (this.verInfoCita == true) {
      this.verInfoCita = false;
    } else {
      this.verInfoCita = true;
    }
  }

}
