import { Component, OnInit, Input } from '@angular/core';
import { Personas } from '../../persona/personas.model';

@Component({
  selector: 'app-resumen-persona',
  templateUrl: './resumen.component.html',
  styleUrls: ['./resumen.component.scss']
})
export class ResumenComponent implements OnInit {
  @Input() persona:Personas;
  vermas:boolean=false;
  @Input() op:number=0;
  constructor() { }

  ngOnInit() {
  }

  cambiar(estado:boolean){
    this.vermas=estado;
  }

}
