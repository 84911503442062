import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConsultasFacturasService } from '../../../appfacturas/busquedas/servicios/consultas.facturas.services';
import { localStorageServices } from '../../../appauth/auth/localStorageServices';

@Component({
  selector: 'app-lista-citas-medicas-info',
  templateUrl: './lista-citas-medicas-info.component.html',
  styleUrls: ['./lista-citas-medicas-info.component.scss']
})
export class CitasListaMedicasInfoComponent implements OnInit, AfterContentChecked {
  @Input() data: any;
  total: number = 0;
  todo = { 'seleccionado': 0 };
  sendRowsFactura = [];
  totalAFacturar: number = 0;
  @Output() clickEvent = new EventEmitter();
  sinImpuesto: boolean = false;

  constructor(private serviciosFacturas: ConsultasFacturasService,
    private cdr: ChangeDetectorRef,
    private servicioDecimal: localStorageServices) { }

  ngOnInit() {

    this.serviciosFacturas.getFacturaInformacion().then((dataInfo: any) => {
      if (dataInfo) {
        for (let index = 0; index < this.data.value.length; index++) {
          const elementdetalle = this.data.value[index];
          if (elementdetalle.tipo == 1) {
            this.total += this.data.value[index].costo;
          } else if (elementdetalle.tipo == 2 && elementdetalle.pendiente == 0) {
            this.total += this.data.value[index].costo;
          }
          for (let d = 0; d < dataInfo.detallefacturas.length; d++) {
            const element = dataInfo.detallefacturas[d];
            if (elementdetalle.id == element.citasmedica_id) {
              elementdetalle['seleccionado'] = 1;
              if (elementdetalle.tipo == 1) {
                this.sendRowsFactura.push(element);
                this.totalAFacturar += this.data.value[index].costo;
                this.clickEvent.emit(this.totalAFacturar);
              } else if (elementdetalle.tipo == 2 && elementdetalle.pendiente == 0) {
                this.sendRowsFactura.push(element);
                this.totalAFacturar += this.data.value[index].costo;
                this.clickEvent.emit(this.totalAFacturar);
              }
              break;
            } else {
              elementdetalle['seleccionado'] = 0;
            }
          }
          this.validarTodoComprobante();
        }
      }
    });

  }

  todoRegistro(data) {

    this.todo.seleccionado = Number(!data.seleccionado);
    if (this.todo.seleccionado == 1) {
      this.sendRowsFactura = [];
    }

    this.totalAFacturar = 0;
    for (let index = 0; index < this.data.value.length; index++) {
      this.data.value[index].seleccionado = this.todo.seleccionado;
      if (this.todo.seleccionado == 1 && this.data.value[index].tipo == 1) {

        let sumaiva: number = 0;
        let sumaivatotal: number = 0;
        let sumaivadescuento: number = 0;

        for (let indeximp = 0; indeximp < this.data.value[index].tipocitamedica.citaimpuestos.length; indeximp++) {
          const element = this.data.value[index].tipocitamedica.citaimpuestos[indeximp];
          if (element.tipoimpusto && element.tipoimpusto.numero == 2 && element.tipoimpusto.valorimpuestos.length > 0) {
            sumaiva += this.servicioDecimal.round((this.data.value[index].costo - this.data.value[index].descuento) - ((this.data.value[index].costo - this.data.value[index].descuento) / (1 + (element.tipoimpusto.valorimpuestos[0].valor / 100))));
            sumaivatotal +=this.servicioDecimal.round((this.data.value[index].costo) - ((this.data.value[index].costo)/ (1 + (element.tipoimpusto.valorimpuestos[0].valor / 100))));
            sumaivadescuento +=this.servicioDecimal.round((this.data.value[index].descuento) - ((this.data.value[index].descuento )/ (1 + (element.tipoimpusto.valorimpuestos[0].valor / 100))));
          } else {
            sumaiva += 0;
            sumaivatotal+= 0;
            sumaivadescuento+= 0;
          }
        }

        let dataSendExamenParametro = { citasmedica_id: this.data.value[index].id, 
                                        estado: 1, 
                                        cantidad: 1, 
                                        precio: this.servicioDecimal.round(this.data.value[index].costo - sumaivatotal),
                                        descuento: this.servicioDecimal.round(this.data.value[index].descuento - sumaivadescuento), 
                                        iva: sumaiva } as any;
        this.sendRowsFactura.push(dataSendExamenParametro);
        this.totalAFacturar += this.data.value[index].costo;
        this.clickEvent.emit(this.totalAFacturar);
      }
      if (this.todo.seleccionado == 1 && this.data.value[index].tipo == 2 && this.data.value[index].pendiente == 0) {
        let sumaiva: number = 0;
        let sumaivatotal: number = 0;
        let sumaivadescuento: number = 0;
        const element = this.data.value[index].tipocitamedica;
        if (element.tipoimpusto && element.tipoimpusto.numero == 2 && element.tipoimpusto.valorimpuestos.length > 0) {
          sumaiva += this.servicioDecimal.round((this.data.value[index].costo - this.data.value[index].descuento) - ((this.data.value[index].costo - this.data.value[index].descuento) / (1 + (element.tipoimpusto.valorimpuestos[0].valor / 100))));
          sumaivatotal +=this.servicioDecimal.round((this.data.value[index].costo) - ((this.data.value[index].costo)/ (1 + (element.tipoimpusto.valorimpuestos[0].valor / 100))));
          sumaivadescuento +=this.servicioDecimal.round((this.data.value[index].descuento) - ((this.data.value[index].descuento )/ (1 + (element.tipoimpusto.valorimpuestos[0].valor / 100))));
        } else {
          sumaiva += 0;
          sumaivatotal+= 0;
          sumaivadescuento+= 0;
        }

        let dataSendExamenParametro = { citasmedica_id: this.data.value[index].id, 
                                        estado: 1, cantidad: 1, 
                                        precio: this.servicioDecimal.round(this.data.value[index].costo - sumaivatotal),
                                        descuento: this.servicioDecimal.round(this.data.value[index].descuento - sumaivadescuento), 
                                        iva: sumaiva } as any;
        this.sendRowsFactura.push(dataSendExamenParametro);
        this.totalAFacturar += this.data.value[index].costo;
        this.clickEvent.emit(this.totalAFacturar);
      }
    }

    if (this.todo.seleccionado == 1) {
      this.serviciosFacturas.setDetallesFacturasCitas(this.sendRowsFactura, 1);
    } else {
      this.serviciosFacturas.setDetallesFacturasCitas(this.sendRowsFactura, 2);
      this.sendRowsFactura = [];
      this.clickEvent.emit(this.totalAFacturar);
    }

  }


  cambiarSeleccionar(data: any) {

    if (data.sinimpuesto == false) {
      this.sinImpuesto = true;
      return;
    }


    let sumaiva: number = 0;
    let sumaivatotal: number = 0;
    let sumaivadescuento: number = 0;
    for (let index = 0; index < data.tipocitamedica.citaimpuestos.length; index++) {
      const element = data.tipocitamedica.citaimpuestos[index];
      if (element.tipoimpusto && element.tipoimpusto.numero == 2 && element.tipoimpusto.valorimpuestos.length > 0) {
        sumaiva += this.servicioDecimal.round((data.costo-data.descuento) - ((data.costo-data.descuento) / (1 + (element.tipoimpusto.valorimpuestos[0].valor / 100))));
        sumaivatotal +=this.servicioDecimal.round((data.costo) - ((data.costo)/ (1 + (element.tipoimpusto.valorimpuestos[0].valor / 100))));
        sumaivadescuento +=this.servicioDecimal.round((data.descuento) - ((data.descuento )/ (1 + (element.tipoimpusto.valorimpuestos[0].valor / 100))));
      } else {
        sumaiva += 0;
        sumaivatotal+= 0;
        sumaivadescuento+= 0;
      }
    }

    let dataSendExamenParametro = { citasmedica_id: data.id, 
                                    estado: 1, 
                                    cantidad: 1, 
                                    precio: this.servicioDecimal.round(data.costo - sumaivatotal), 
                                    descuento:this.servicioDecimal.round(data.descuento-sumaivadescuento), 
                                    iva: sumaiva } as any;
    let existe: boolean = false;
    let indexE: number = 0;
    for (let j = 0; j < this.sendRowsFactura.length; j++) {
      const elementtmp = this.sendRowsFactura[j];
      console.log(elementtmp.citasmedica_id + '==' + data.id);

      if (elementtmp.citasmedica_id == data.id) {
        existe = true;
        indexE = j;
        break;
      }
    }

    if (!existe && data.seleccionado == 1) {
      this.sendRowsFactura.push(dataSendExamenParametro);
      this.serviciosFacturas.setDetallesFacturaCitas(dataSendExamenParametro, 1);
      this.totalAFacturar += data.costo;
      this.clickEvent.emit(this.totalAFacturar);
    } else if (data.seleccionado == 0 && existe) {
      this.sendRowsFactura.splice(indexE, 1);
      this.serviciosFacturas.setDetallesFacturaCitas(dataSendExamenParametro, 2);
      this.totalAFacturar -= data.costo;
      this.clickEvent.emit(this.totalAFacturar);
    }


    this.validarTodoComprobante();

  }

  validarTodoComprobante() {

    if (this.sendRowsFactura.length === this.data.value.length) {
      this.todo.seleccionado = 1;
    } else {
      this.todo.seleccionado = 0;
    }

  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }


}
