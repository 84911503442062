import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Cantones } from '../../cantone/cantones.model';
import { CantonesServices } from '../../cantone/cantones.services';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Provincias } from '../../../provincias/provincia/provincias.model';
import { ProvinciasServices } from '../../../provincias/provincia/provincias.services';

@Component({
	selector: 'app-editcantone',
	templateUrl: './editcantone.component.html',
	styleUrls: ['./editcantone.component.scss']
})

export class EditCantoneComponent implements OnInit {

	@Input() Cantone:Cantones;
	public dataProvincias={'nombre':'vacio'} as Provincias;
	public provinciasData:any;

	public formCantoneNombre:FormGroup;
	public editNombre:boolean=false;

	public formCantoneProvincia:FormGroup;
	public editProvincia:boolean=false;

	public formCantoneEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioCantone:CantonesServices,
		private servicioProvincia:ProvinciasServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioProvincia.getDataProvincias().subscribe((data:any)=>{
			this.provinciasData=data.provincias;
			this.dataProvincias=this.provinciasData.find(obj => obj.id == this.Cantone.provincia_id);
		});

		this.formCantoneNombre=this.fb.group({
			nombre:[this.Cantone.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150)])],
		});

		this.formCantoneProvincia=this.fb.group({
			provincia_id:[this.Cantone.provincia_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}
	

	editCantoneNombreEnable(){
		this.editNombre=true;
		this.formCantoneNombre=this.fb.group({
			nombre:[this.Cantone.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150)])],
		});
	}

	editCantoneNombreDisable(){
		this.editNombre=false;
	}

	saveCantoneNombre(){
		let CantoneDataModel:Cantones=this.formCantoneNombre.value;
		CantoneDataModel.id=this.Cantone.id;
		this.Cantone.nombre=CantoneDataModel.nombre;
		this.servicioCantone.updateCantones(CantoneDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editNombre=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editCantoneProvinciaEnable(){
		this.editProvincia=true;
		this.formCantoneProvincia=this.fb.group({
			provincia_id:[this.Cantone.provincia_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editCantoneProvinciaDisable(){
		this.editProvincia=false;
	}

	saveCantoneProvincia(){
		let CantoneDataModel:Cantones=this.formCantoneProvincia.value;
		CantoneDataModel.id=this.Cantone.id;
		this.Cantone.provincia_id=CantoneDataModel.provincia_id;
		this.dataProvincias=this.provinciasData.find(obj => obj.id == this.Cantone.provincia_id);
		this.servicioCantone.updateCantones(CantoneDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editProvincia=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}

