import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-resumen-dosis-enfermeria-hospitalizacion',
  templateUrl: './resumen-dosis-enfermeria-hospitalizacion.component.html',
  styleUrls: ['./resumen-dosis-enfermeria-hospitalizacion.component.scss']
})
export class ResumenDosisEnfermeriaHospitalizarComponent implements OnInit {
  @Input() items: any;
  @Input() tamanio: number = 0;
  @Input() k: number = 0;
  @Output() clickEvent = new EventEmitter();
  @Input() Indicacione: any;
  ultimoDato: any;
  current_date: any;
  current_time: any;
  config: any = {
    size: 'md',
    centered: true,
    resolve: { datasend: null }
  }
  constructor() { }

  ngOnInit() {
    this.current_time = moment().format("HH")
    this.current_date = moment().format("YYYY-MM-DD");
    for (let index = 0; index < this.items.value.length; index++) {
      const element = this.items.value[index];
      this.ultimoDato = element;
      if (element.estado == 0 && this.current_time >= moment(element.hora).format("HH") && this.current_date == moment(this.items.key).add(1, 'day').format("YYYY-MM-DD")) {
        element.paso = 1;
      } else {
        element.paso = 0;
      }
    }
  }

  retornar(row: any): any {
    let lista = [row.persona.nombre, row.persona.apellido];
    let resultado3 = "";
    for (let i = 0; i < lista.length; i++) {
      lista[i].split(' ').reduce((r, w) => resultado3 += w.slice(0, 1), '');
    }
    return { ac: resultado3 };
  }

}
