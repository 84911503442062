import { Component, OnInit, Input } from '@angular/core';
import { ElementComponentService } from '../servicios/component.servicios';

@Component({
  selector: 'app-tiempo-lista-resumen',
  templateUrl: './tiempo-lista-resumen.component.html',
  styleUrls: ['./tiempo-lista-resumen.component.scss']
})
export class TiempoListaResumenComponent implements OnInit {
  @Input() id:number=0;
  data:any;
  dataMedidaFrecuencia=[];
    
  constructor(private servicioFrecuencia:ElementComponentService,) { }

  ngOnInit() {

    this.servicioFrecuencia.getDataFrecuenciamedicamentosResumen().subscribe((data:any)=>{
      this.dataMedidaFrecuencia=data.frecuenciamedicamentos;
      this.data =  this.dataMedidaFrecuencia.find(x => x.id === this.id);
		});

  }
}
