import { Component, Input, OnInit } from '@angular/core';
import { Atencionesmedicas } from '../../../appasclepio/citas-medicas-medicos/atencionesmedicas/atencionesmedica/atencionesmedicas.model';
import { ElementResumenHospitalizacionComponentService } from '../servicios/resumen.hospitalizacion.services';

@Component({
  selector: 'app-resumen-general-citas-emergencias',
  templateUrl: './resumen-general-citas-emergencias.component.html',
  styleUrls: ['./resumen-general-citas-emergencias.component.scss']
})
export class ResumenGeneralCitasEmergenciasComponent implements OnInit {
  @Input() dataCitaMedica: any;
  dataInfoAtencionMedica: any;
  dataInfoAtencionMedicaCero:any;
  citas = [];
  tipo = 11;
  
  constructor(private resumen: ElementResumenHospitalizacionComponentService) { }

  ngOnInit() {
    console.log(this.dataCitaMedica);

    let itemAddSignos = {} as Atencionesmedicas;
    itemAddSignos.citasmedica_id = -7;
    itemAddSignos.anotaciones = "";
    itemAddSignos.id = 0;
    itemAddSignos.medico = undefined;
    itemAddSignos.topoatencion = 11;
    this.citas.push(itemAddSignos);

    this.resumen.getDataResumenInfoAtencionesHopitalizacionEmergencia(this.dataCitaMedica.id).subscribe((infoHopitalizacion: any) => {
      infoHopitalizacion.atencionesmedicas.forEach(element => {
        this.citas.push(element);
      });
      this.dataInfoAtencionMedica = infoHopitalizacion.atencionesmedicas[0];
      this.dataInfoAtencionMedicaCero= infoHopitalizacion.atencionesmedicas[0];

      let itemAddMedicamentos = {} as Atencionesmedicas;
      itemAddMedicamentos.citasmedica_id = -4;
      itemAddMedicamentos.anotaciones = "";
      itemAddMedicamentos.id = 0;
      itemAddMedicamentos.medico = undefined;
      itemAddMedicamentos.topoatencion = 8;
      this.citas.push(itemAddMedicamentos);

      let itemAddGlicemica = {} as Atencionesmedicas;
      itemAddGlicemica.citasmedica_id = -44;
      itemAddGlicemica.anotaciones = "";
      itemAddGlicemica.id = 0;
      itemAddGlicemica.medico = undefined;
      itemAddGlicemica.topoatencion = 888;
      this.citas.push(itemAddGlicemica);

      let itemResumennfermeria = {} as Atencionesmedicas;
      itemResumennfermeria.citasmedica_id = -8;
      itemResumennfermeria.anotaciones = "";
      itemResumennfermeria.id = 0;
      itemResumennfermeria.medico = undefined;
      itemResumennfermeria.topoatencion = 12;
      this.citas.push(itemResumennfermeria);

      let itemResumenReceta = {} as Atencionesmedicas;
      itemResumenReceta.citasmedica_id = -6;
      itemResumenReceta.anotaciones = "";
      itemResumenReceta.id = 0;
      itemResumenReceta.medico = undefined;
      itemResumenReceta.topoatencion = 10;
      this.citas.push(itemResumenReceta);



    });
    
  }

  confirmarAtencion(data: any) {
    console.log(data);
    this.dataInfoAtencionMedica = data;
    this.tipo = data.topoatencion;
    switch (this.tipo) {
      case 4:
        {
          this.tipo=0;
          this.dataInfoAtencionMedica = undefined;
          setTimeout(() => {
            this.tipo = data.topoatencion;
            this.dataInfoAtencionMedica = data;
          }, 100);
        }
        break;
      default:
        console.log("No such day exists!");
        break;
    }

  }

}
