import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Empleados } from '../../empleado/empleados.model';
import { EmpleadosServices } from '../../empleado/empleados.services';
import { Personas } from '../../../personas/persona/personas.model';
import { PersonasServices } from '../../../personas/persona/personas.services';
import { Tipoempleados } from '../../../tipoempleados/tipoempleado/tipoempleados.model';
import { TipoempleadosServices } from '../../../tipoempleados/tipoempleado/tipoempleados.services';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
	selector: 'app-addempleado',
	templateUrl: './addempleado.component.html',
	styleUrls: ['./addempleado.component.scss']
})

export class AddEmpleadoComponent implements OnInit {
	public dataPersonas:any;
	public dataTipoempleados:any;
	public formAddEmpleado: FormGroup;

	public dataLaboratorios=[{id:1,nombre:'Laboratorio clinico'},{id:2,nombre:'Enfermería'}];

	busqueEnviar:string='';
	@Input() tipoBusqueda:boolean;
	@Input() dataPersona:any;
	@Input() rows=[];

	@ViewChild('sectorSelect', { static: false }) select: any;
	
	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioEmpleado:EmpleadosServices,
		private servicioTipoempleado:TipoempleadosServices,
		private ngxService: NgxUiLoaderService,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		
		this.formAddEmpleado = this.fb.group({
			persona_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			tipoempleado_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			labclinico:[null,Validators.compose([CustomValidators.number])],
			estado:[1,null],
		});

		if(this.tipoBusqueda!=undefined){
			this.formAddEmpleado.get('persona_id').setValue(Number(this.dataPersona.id));
			this.formAddEmpleado.updateValueAndValidity();

			let ids=[];
			this.rows.forEach(element => {
				ids.push(element.tipoempleado_id)
			});
			
			this.servicioTipoempleado.getDataTipoempleados().subscribe((data:any)=>{
				this.dataTipoempleados=data.tipoempleados.filter(function(item){
					return ids.indexOf(item.id) === -1;
				});
			});

			
		}else{
			this.tipoBusqueda=false;
		}

		//this.eviarBusqueda();
	}


	save(){
		let empleadoDataModel:Empleados=this.formAddEmpleado.value;
		if(this.formAddEmpleado.value.tipoempleado_id!=2){
			empleadoDataModel.labclinico=null;
		}
		console.log(empleadoDataModel);
		
		this.ngxService.startLoader('princialLoder');
		this.servicioEmpleado.saveEmpleados(empleadoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.ngxService.stopLoader('princialLoder');
						this.activeModal.close(1);
					}
				});
			}else{
				this.ngxService.stopLoader('princialLoder');
				this.Noti.setNotification(data);
			}
		});
	}

	buscarEvento(event){
		const val = event.target.value.toLowerCase();
		this.busqueEnviar=val;
	}
	
	eviarBusqueda(){
		this.ngxService.startLoader('princialLoder');
		this.servicioEmpleado.viewEmpleadosSinAsignar(this.busqueEnviar).subscribe((data:any)=>{
			this.dataPersonas=data.empleados;
			this.ngxService.stopLoader('princialLoder');
		});
	}

	seleccionar(data:any){
		this.dataPersona=data;
		this.servicioEmpleado.viewEmpleados(this.dataPersona.id).subscribe((data:any)=>{
			this.rows=data.especialidadPersonale;
		});
		let ids=[];
		this.rows.forEach(element => {
			ids.push(element.tipoempleado_id)
		});
		  
		this.servicioTipoempleado.getDataTipoempleados().subscribe((data:any)=>{
			this.dataTipoempleados=data.tipoempleados.filter(function(item){
				return ids.indexOf(item.id) === -1;
			  });
		});
		this.formAddEmpleado.get('persona_id').setValue(Number(data.id));
		this.formAddEmpleado.updateValueAndValidity();
		this.tipoBusqueda=true;
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
	
}