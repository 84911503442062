import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditParroquiaComponent } from './edit/editparroquia.component';
import { AddParroquiaComponent } from './add/addparroquia.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DirectivaModule } from '../../../../directivas/directiva.module';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgSelectModule,
		DirectivaModule
	],
	declarations:[EditParroquiaComponent,AddParroquiaComponent],
	entryComponents:[EditParroquiaComponent,AddParroquiaComponent],
})
export class ModalParroquiaModule { }
