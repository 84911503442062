import { Component, Input, OnInit } from '@angular/core';
import { AtencionesmedicasServices } from '../../atencionesmedica/atencionesmedicas.services';

@Component({
  selector: 'app-ver-info-resumenes',
  templateUrl: './ver-info-resumenes.component.html',
  styleUrls: ['./ver-info-resumenes.component.scss']
})
export class VerInfoResumenesComponent implements OnInit {
  @Input() dataCitaMedicaInfo:any;
  @Input() titulo:string;
  dataAtension:any;
  constructor(private atencionServ:AtencionesmedicasServices) { }

  ngOnInit() {
    console.log(this.dataCitaMedicaInfo);
    
    this.atencionServ.viewAtencionCitaMedica(this.dataCitaMedicaInfo.id).subscribe((info:any)=>{
      this.dataAtension=info.atencionesmedica[0];

    });
  }

}
