import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditFormacionacademicaComponent } from './edit/editformacionacademica.component';
import { AddFormacionacademicaComponent } from './add/addformacionacademica.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
	],
	declarations:[EditFormacionacademicaComponent,AddFormacionacademicaComponent],
	entryComponents:[EditFormacionacademicaComponent,AddFormacionacademicaComponent],
})
export class ModalFormacionacademicaModule { }
