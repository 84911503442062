import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataItemsGeneral } from '../../../appasclepio/examen-medicos/citaexemanes/citaexemane/cita.data';

@Component({
  selector: 'app-lista-items-examenes-medicos-factura',
  templateUrl: './lista-items-examenes-medicos-factura.component.html',
  styleUrls: ['./lista-items-examenes-medicos-factura.component.scss']
})
export class ItemsListaExamenesMedicosFacturaComponent implements OnInit {
  @Input() item:any;
  items=DataItemsGeneral.items;
  @Output() clickEvent = new EventEmitter();
  existeImpuesto:boolean=false;
  constructor() { }

  ngOnInit() {
    if(this.item.examenesmedico.exameninpuestos.length>0){
      this.existeImpuesto=true;
    }else{
      this.item.sinimpuesto=this.existeImpuesto;
      this.clickEvent.emit(this.item);
    } 
  }

  editEstado(data:any){
    this.item.seleccionado=Number(!data.seleccionado);
    this.clickEvent.emit(this.item);
	}
  

}
