import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddEnfermeriaComponent } from '../modal/add/addenfermeria.component';
import { EnfermeriasServices } from '../enfermeria/enfermerias.services';
import { Enfermerias } from '../enfermeria/enfermerias.model';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';

@Component({
	selector: 'app-enfermeria-especialidad',
	templateUrl: './enfermeria-especialidad.component.html',
	styleUrls: ['./enfermeria-especialidad.component.scss']
})
export class EnfermeriaEspecialidadComponent implements OnInit {
	@Input() idEnfermeria: any;
	rows = [];
	temp = [];

	constructor(private serviciosEnfermerias: EnfermeriasServices, 
				private notificationServices:NotificationServices,
				public dialog: NgbModal, ) { }

	ngOnInit() {
		this.listaEnfermerias();
	}

	config: any = {
		size: 'lg',
		centered: true,
		resolve: { datasend: null }
	}

	listaEnfermerias() {
		this.serviciosEnfermerias.viewEnfermerias(this.idEnfermeria.id).subscribe((data: any) => {
			this.rows = data.enfermerias;
			this.temp = [...this.rows];
		});
	}

	open() {
		let dialogRef = this.dialog.open(AddEnfermeriaComponent, this.config);
		dialogRef.componentInstance.tipoBusqueda = true;
		dialogRef.componentInstance.dataPersona = this.idEnfermeria;
		dialogRef.componentInstance.rows = this.rows;
		dialogRef.result.then(
			result => {
				if (result) {
					this.listaEnfermerias();
				}
			},
			reason => {
				console.log(reason);
			}
		);
	}

	editEstado(dataEnfermeria:Enfermerias){
		let dataEstadoEnfermeria= {} as Enfermerias;
		dataEstadoEnfermeria.estado=Number(!dataEnfermeria.estado);
		dataEstadoEnfermeria.persona_id=dataEnfermeria.persona_id;
		dataEstadoEnfermeria.especialidade_id=dataEnfermeria.especialidade_id;
		this.serviciosEnfermerias.updateEnfermerias(dataEstadoEnfermeria).subscribe((data:any)=>{
			this.notificationServices.setNotification(data);
		});
	}

}
