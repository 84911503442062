import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Insumounidades } from '../../insumounidade/insumounidades.model';
import { InsumounidadesServices } from '../../insumounidade/insumounidades.services';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
	selector: 'app-addinsumounidade',
	templateUrl: './addinsumounidade.component.html',
	styleUrls: ['./addinsumounidade.component.scss']
})

export class AddInsumounidadeComponent implements OnInit {
	public formAddInsumounidade: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioInsumounidade:InsumounidadesServices,
		private ngxService: NgxUiLoaderService,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.formAddInsumounidade = this.fb.group({
			nombre:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(25)])],
			estado:[1,null],
		});
	}


	save(){
		let insumounidadeDataModel:Insumounidades=this.formAddInsumounidade.value;
		this.ngxService.startLoader('princialLoder');
		this.servicioInsumounidade.saveInsumounidades(insumounidadeDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.ngxService.stopLoader('princialLoder');
						this.activeModal.close(1);
					}
				});
			}else{
				this.ngxService.stopLoader('princialLoder');
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}