import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from '../../../../global/global.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ElementComponentService } from '../../servicios/component.servicios';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';

@Component({
    selector: 'app-imprimir-certificado-custom',
    templateUrl: './imprimir-informacion.component.html',
    styleUrls: ['./imprimir-informacion.component.scss']
})

export class ImprimirCertificadoMedicoCustomComponent implements OnInit {
    @Input() dataPdf: any;
    @Input() titulo: string = '';
    @Input() medico: any;
    @Input() paciente: any;
    @Input() especialidad: any;
    @Input() cita: number;
    tipo: number = 1;
    pdfpath: any = '';
    ubicacion: any;
    empresa = {} as any;
    locales = [];
    diagnosticos = [];
    contexto: string = '';
    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '25rem',
        minHeight: '0',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Ingrese el texto...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
            { class: 'times-new-roman', name: 'Times New Roman' },
            { class: 'arial', name: 'Arial' },
            { class: 'calibri', name: 'Calibri' },
            { class: 'comic-sans-ms', name: 'Comic Sans MS' }
        ],
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ],
        uploadUrl: 'v1/image',
        uploadWithCredentials: false,
        sanitize: true,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            ['strikeThrough',
                'subscript',
                'superscript',
                'textColor',
                'backgroundColor',
                'customClasses',
                'link',
                'unlink',
                'insertImage',
                'insertVideo',
                'insertHorizontalRule',
                'removeFormat',
                'toggleEditorMode']
        ]
    };

    constructor(public sanitizer: DomSanitizer,
        private activeModal: NgbActiveModal,
        private Noti: NotificationServices,
        private comerciales: ElementComponentService) { }

    ngOnInit() {
        this.comerciales.getDataComerciales().subscribe((info: any) => {
            this.locales = info.locales;
            this.medico.seleccion = 0;
            for (let index = 0; index < this.locales.length; index++) {
                const element = this.locales[index];
                element.seleccion = 0;
            }
        });
        this.comerciales.getDataDiagnostico(this.cita).subscribe((info: any) => {
            this.diagnosticos = info.atencionesmedicas;
        })
    }

    cerrarModal() {
        this.activeModal.close(2);
    }

    medicoSelect(item: any) {
        this.empresa.nombre = item.comerciale.razonsocial;
        this.empresa.direccion = item.direccioncompleta;
        this.empresa.ubicacion = item.parroquia.cantone.provincia.nombre + " " + item.parroquia.cantone.nombre;
        this.empresa.ciudad = item.parroquia.cantone.nombre;
        this.tipo = 1;
        this.ubicacion = item.parroquia;
        this.medico.seleccion = 0;
        let silaba = "la"
        if (item.genero == 'M') {
            silaba = "el"
        } else if (item.genero == 'O') {
            silaba = "";
        }
        this.comerciales.viewCertificadoEncontrado(this.cita).subscribe((info: any) => {
            if (info.certificado) {
                let datasms = { 'val': 'para generar certificado con ' + item.comerciale.razonsocial, 'msg': 'Poner información por defecto' };

                this.Noti.msgConfir(datasms).then((datas) => {
                    if (datas) {
                        this.contexto = '<div style="text-align: justify;"><font size="2">Quien suscribe, </font><font size="2"><b>' + item.comerciale.razonsocial + '</b> </font><font size="2">, con especialidad en,<b> </b></font><font size="2"><b>CARDIOCIRUGÍA Y MEDICINA FAMILIAR</b></font><font size="2"> con número de ruc </font><font size="2"><b>' + item.comerciale.numeroruc + '</b></font><font size="2">,&#160; certifico que atendí a ' + silaba + ' </font><font size="2"><b>' + this.paciente.prefijo + " " + this.paciente.cedula + " " + this.paciente.nombre + " " + this.paciente.apellido + '</b> </font><font size="2">en nuestras instalaciones realizando estudios cardiologicos con las cuales se diagnostica: </font><font size="2">';
                        for (let k = 0; k < this.diagnosticos.length; k++) {
                            const element = this.diagnosticos[k];
                            for (let p = 0; p < element.diagnosticos.length; p++) {
                                const elementdia = element.diagnosticos[p];
                                this.contexto += '<b>' + elementdia.detalles.toUpperCase() + " CON " + elementdia.ciedy.nombre.toUpperCase() + " CIE " + elementdia.ciedy.codigo.toUpperCase() + '</b>';
                            }
                        }
                        this.contexto += ' </font><font size="2"><b>indicaciones</b></font><font size="2">,</font></div>';
                        if (this.medico.facturaremail == null || this.medico.facturaremail == undefined) {
                            this.medico.facturaremail = "";
                        }
                        if (this.medico.celular == null || this.medico.celular == undefined) {
                            this.medico.celular = "";
                        }
                        this.contexto += '<div><font size="2"><br></font></div><div><font size="2"><br></font></div><div><font size="2"><br></font></div><div><font size="2">Atentamente</font></div><div><font size="2"><br></font></div><div><font size="2"><br></font></div><div><font size="2">' + this.medico.prefijo + " " + this.medico.nombre + " " + this.medico.apellido + '</font></div><div><font size="2">CI: ' + this.medico.cedula + '</font></div><div><font size="2">Email: ' + this.medico.facturaremail + '</font></div><div><font size="2">Tel&#233;fono: ' + this.medico.celular + '</font></div>';
                    } else {
                        this.contexto = info.certificado.body;
                    }
                });
            } else {
                this.contexto = '<div style="text-align: justify;"><font size="2">Quien suscribe, </font><font size="2"><b>' + item.comerciale.razonsocial + '</b> </font><font size="2">, con especialidad en,<b> </b></font><font size="2"><b>CARDIOCIRUGÍA Y MEDICINA FAMILIAR</b></font><font size="2"> con número de ruc </font><font size="2"><b>' + item.comerciale.numeroruc + '</b></font><font size="2">,&#160; certifico que atendí a ' + silaba + ' </font><font size="2"><b>' + this.paciente.prefijo + " " + this.paciente.cedula + " " + this.paciente.nombre + " " + this.paciente.apellido + '</b> </font><font size="2">en nuestras instalaciones realizando estudios cardiologicos con las cuales se diagnostica: </font><font size="2">';
                for (let k = 0; k < this.diagnosticos.length; k++) {
                    const element = this.diagnosticos[k];
                    for (let p = 0; p < element.diagnosticos.length; p++) {
                        const elementdia = element.diagnosticos[p];
                        this.contexto += '<b>' + elementdia.detalles.toUpperCase() + " CON " + elementdia.ciedy.nombre.toUpperCase() + " CIE " + elementdia.ciedy.codigo.toUpperCase() + '</b>';
                    }
                }
                this.contexto += ' </font><font size="2"><b>indicaciones</b></font><font size="2">,</font></div>';
                if (this.medico.facturaremail == null || this.medico.facturaremail == undefined) {
                    this.medico.facturaremail = "";
                }
                if (this.medico.celular == null || this.medico.celular == undefined) {
                    this.medico.celular = "";
                }
                this.contexto += '<div><font size="2"><br></font></div><div><font size="2"><br></font></div><div><font size="2"><br></font></div><div><font size="2">Atentamente</font></div><div><font size="2"><br></font></div><div><font size="2"><br></font></div><div><font size="2">' + this.medico.prefijo + " " + this.medico.nombre + " " + this.medico.apellido + '</font></div><div><font size="2">CI: ' + this.medico.cedula + '</font></div><div><font size="2">Email: ' + this.medico.facturaremail + '</font></div><div><font size="2">Tel&#233;fono: ' + this.medico.celular + '</font></div>'

            }
        });
        for (let index = 0; index < this.locales.length; index++) {
            const element = this.locales[index];
            element.seleccion = 0;
        }
        for (let index = 0; index < this.locales.length; index++) {
            const element = this.locales[index];
            element.seleccion = 0;
            if (element.id == item.id) {
                element.seleccion = 1;
            }
        }
    }

    medicoSelectCita(item: any) {
        this.empresa.nombre = "";
        this.empresa.direccion = "";
        this.empresa.ubicacion = "";
        this.empresa.ciudad = "";
        this.tipo = 0;
        let silaba = "la"
        if (item.genero == 'M') {
            silaba = "el"
        } else if (item.genero == 'O') {
            silaba = "";
        }
        this.comerciales.viewCertificadoEncontrado(this.cita).subscribe((info: any) => {
            if (info.certificado) {
                let datasms = { 'val': 'para generar certificado con ' + item.prefijo + " " + item.cedula + " " + item.nombre + " " + item.apellido, 'msg': 'Poner información por defecto' };

                this.Noti.msgConfir(datasms).then((datas) => {
                    if (datas) {
                        this.contexto = '<div style="text-align: justify;"><font size="2">Quien suscribe, </font><font size="2"><b>' + item.prefijo + " " + item.cedula + " " + item.nombre + " " + item.apellido + '</b> </font><font size="2">, ciudadano ecuatoriano, médico especialista en,<b> </b></font><font size="2"><b>' + this.especialidad.nombre + '</b></font><font size="2"> con registro profecional </font><font size="2"><b>' + item.cedula + '</b></font><font size="2">,&#160; certifico que atendí a ' + silaba + ' </font><font size="2"><b>' + this.paciente.prefijo + " " + this.paciente.cedula + " " + this.paciente.nombre + " " + this.paciente.apellido + '</b> </font><font size="2">en nuestras instalaciones realizando estudios cardiológicos con los cuales se diagnostica: </font><font size="2">';
                        for (let k = 0; k < this.diagnosticos.length; k++) {
                            const element = this.diagnosticos[k];
                            for (let p = 0; p < element.diagnosticos.length; p++) {
                                const elementdia = element.diagnosticos[p];
                                this.contexto += '<b>' + elementdia.detalles.toUpperCase() + " CON " + elementdia.ciedy.nombre.toUpperCase() + " CIE " + "" + elementdia.ciedy.codigo.toUpperCase() + '</b>';
                            }
                        }
                        this.contexto += ' </font><font size="2"><b>indicaciones</b></font><font size="2">,</font></div>';
                        if (this.medico.facturaremail == null || this.medico.facturaremail == undefined) {
                            this.medico.facturaremail = "";
                        }
                        if (this.medico.celular == null || this.medico.celular == undefined) {
                            this.medico.celular = "";
                        }
                        this.contexto += '<div><font size="2"><br></font></div><div><font size="2"><br></font></div><div><font size="2"><br></font></div><div><font size="2">Atentamente</font></div><div><font size="2"><br></font></div><div><font size="2"><br></font></div><div><font size="2">' + this.medico.prefijo + " " + this.medico.nombre + " " + this.medico.apellido + '</font></div><div><font size="2">CI: ' + this.medico.cedula + '</font></div><div><font size="2">Email: ' + this.medico.facturaremail + '</font></div><div><font size="2">Tel&#233;fono: ' + this.medico.celular + '</font></div>'
                    } else {
                        this.contexto = info.certificado.body;
                    }
                });
            } else {
                this.contexto = '<div style="text-align: justify;"><font size="2">Quien suscribe, </font><font size="2"><b>' + item.prefijo + " " + item.cedula + " " + item.nombre + " " + item.apellido + '</b> </font><font size="2">, ciudadano ecuatoriano, médico especialista en,<b> </b></font><font size="2"><b>' + this.especialidad.nombre + '</b></font><font size="2"> con registro profecional </font><font size="2"><b>' + item.cedula + '</b></font><font size="2">,&#160; certifico que atendí a ' + silaba + ' </font><font size="2"><b>' + this.paciente.prefijo + " " + this.paciente.cedula + " " + this.paciente.nombre + " " + this.paciente.apellido + '</b> </font><font size="2">en nuestras instalaciones realizando estudios cardiológicos con los cuales se diagnostica: </font><font size="2">';
                for (let k = 0; k < this.diagnosticos.length; k++) {
                    const element = this.diagnosticos[k];
                    for (let p = 0; p < element.diagnosticos.length; p++) {
                        const elementdia = element.diagnosticos[p];
                        this.contexto += '<b>' + elementdia.detalles.toUpperCase() + " CON " + elementdia.ciedy.nombre.toUpperCase() + " CIE " + "" + elementdia.ciedy.codigo.toUpperCase() + '</b>';
                    }
                }
                this.contexto += ' </font><font size="2"><b>indicaciones</b></font><font size="2">,</font></div>';
                if (this.medico.facturaremail == null || this.medico.facturaremail == undefined) {
                    this.medico.facturaremail = "";
                }
                if (this.medico.celular == null || this.medico.celular == undefined) {
                    this.medico.celular = "";
                }
                this.contexto += '<div><font size="2"><br></font></div><div><font size="2"><br></font></div><div><font size="2"><br></font></div><div><font size="2">Atentamente</font></div><div><font size="2"><br></font></div><div><font size="2"><br></font></div><div><font size="2">' + this.medico.prefijo + " " + this.medico.nombre + " " + this.medico.apellido + '</font></div><div><font size="2">CI: ' + this.medico.cedula + '</font></div><div><font size="2">Email: ' + this.medico.facturaremail + '</font></div><div><font size="2">Tel&#233;fono: ' + this.medico.celular + '</font></div>'
            }
        });
        for (let index = 0; index < this.locales.length; index++) {
            const element = this.locales[index];
            element.seleccion = 0;
        }
        item.seleccion = 1;
    }

    generar() {
        this.comerciales.viewCertificadoEncontrado(this.cita).subscribe((info: any) => {
            if (info.certificado) {
                let dataSendCertificados = { id: info.certificado.id, body: this.contexto } as any;
                this.comerciales.updateCertificados(dataSendCertificados).subscribe((data: any) => {
                    if (data.estado) {
                        this.Noti.setNotification(data).then((datas) => {
                            if (datas) {
                                this.comerciales.getDataViewCitaMedicaCertificadoMedico(this.cita, { tipo: this.tipo, contexto: this.contexto, empresa: this.empresa }).subscribe((datapdf: any) => {
                                    this.pdfpath = this.sanitizer.bypassSecurityTrustResourceUrl(Globals.BASE_URL_API_REST + datapdf.datos.CertificadoMedico.ruta);
                                });
                            }
                        });
                    } else {
                        this.Noti.setNotification(data);
                    }
                })
            } else {
                let dataSendCertificados = { citasmedica_id: this.cita, body: this.contexto } as any;
                this.comerciales.saveCertificados(dataSendCertificados).subscribe((data: any) => {
                    if (data.estado) {
                        this.Noti.setNotification(data).then((datas) => {
                            if (datas) {
                                this.comerciales.getDataViewCitaMedicaCertificadoMedico(this.cita, { tipo: this.tipo, contexto: this.contexto, empresa: this.empresa }).subscribe((datapdf: any) => {
                                    this.pdfpath = this.sanitizer.bypassSecurityTrustResourceUrl(Globals.BASE_URL_API_REST + datapdf.datos.CertificadoMedico.ruta);
                                });
                            }
                        });
                    } else {
                        this.Noti.setNotification(data);
                    }
                })
            }
        });
    }
}