import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Etnias } from '../../etnia/etnias.model';
import { EtniasServices } from '../../etnia/etnias.services';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-editetnia',
	templateUrl: './editetnia.component.html',
	styleUrls: ['./editetnia.component.scss']
})

export class EditEtniaComponent implements OnInit {

	@Input() Etnia:Etnias;

	public formEtniaNombre:FormGroup;
	public editNombre:boolean=false;

	public formEtniaEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioEtnia:EtniasServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {

		this.formEtniaNombre=this.fb.group({
			nombre:[this.Etnia.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150)])],
		});
	}
	

	editEtniaNombreEnable(){
		this.editNombre=true;
		this.formEtniaNombre=this.fb.group({
			nombre:[this.Etnia.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150)])],
		});
	}

	editEtniaNombreDisable(){
		this.editNombre=false;
	}

	saveEtniaNombre(){
		let EtniaDataModel:Etnias=this.formEtniaNombre.value;
		EtniaDataModel.id=this.Etnia.id;
		this.Etnia.nombre=EtniaDataModel.nombre;
		this.servicioEtnia.updateEtnias(EtniaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editNombre=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}

