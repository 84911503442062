import { Routes } from '@angular/router';
import { AuthGuard } from '../../../appauth/auth/auth.guard';
import { CantonesVistaComponent } from './vistas/cantonevista.component';

export const CantonesRoutes: Routes = [{
	path: '',
	redirectTo: 'cantones-index',
	pathMatch: 'full',
	canActivate: [AuthGuard]
},{
	path: '',
	children: [{
		path: 'cantones-index',
		component: CantonesVistaComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Lista de cantones',
			roles: ['Gerente'],
			urls: [
			  { title: 'Home', url: '/home' },
			  { title: 'Página de inicio' }
			]
		},
	}]
}];
