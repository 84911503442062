import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from '../../../../app/global/global.model';
import { MspReportesService } from '../servicios/msprepotrtes.servicios';
import { ElementResumenHospitalizacionComponentService } from '../servicios/resumen.hospitalizacion.services';

@Component({
  selector: '[app-resumen-formulario-f005-enfermeria]',
  templateUrl: './resumen-formulario-f005-enfermeria.component.html',
  styleUrls: ['./resumen-formulario-f005-enfermeria.component.scss']
})
export class ResumenFormularioF005EnfermeriaComponent implements OnInit {
  @Input() idHospital: number;
  @Input() idAtencion: number;
  @Input() idCitaMedica: number;
  @Input() idHistoriaClinica?: number;
  rows=[];

  pdfpath: any;
  
  constructor( private reporteInfermeria: ElementResumenHospitalizacionComponentService,
    public sanitizer: DomSanitizer,
    private smpForm:MspReportesService,) { }

  ngOnInit() {
    this.reporteInfermeria.viewReporteenfermeriasPorHospitalizacion(this.idHospital).subscribe((dataRepo:any)=>{
      if(dataRepo){
        this.rows=dataRepo.reporteenfermerias;
        this.imprimir();
      }
    });
  }

  retornar(row:any):any{
    let lista = [row.persona.nombre, row.persona.apellido];
    let resultado3 = "";
    for (let i = 0; i < lista.length; i++){
      lista[i].split(' ').reduce((r,w)=> resultado3+=w.slice(0,1),'');
    }
    return  {ac:resultado3};
  } 

  imprimir(){
    this.smpForm.getMSPForm005Enfermeria({hospitalizacione_id:this.idHospital,historia:this.idHistoriaClinica}).subscribe((datapdf:any)=>{
      this.pdfpath=this.sanitizer.bypassSecurityTrustResourceUrl(Globals.BASE_URL_API_REST+ datapdf.datos.MSP.ruta); 
    })
  }

}
