import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Etnias } from '../../etnia/etnias.model';
import { EtniasServices } from '../../etnia/etnias.services';

@Component({
	selector: 'app-addetnia',
	templateUrl: './addetnia.component.html',
	styleUrls: ['./addetnia.component.scss']
})

export class AddEtniaComponent implements OnInit {
	public formAddEtnia: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioEtnia:EtniasServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.formAddEtnia = this.fb.group({
			nombre:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150)])],
			estado:[1,null],
		});
	}


	save(){
		let etniaDataModel:Etnias=this.formAddEtnia.value;
		this.servicioEtnia.saveEtnias(etniaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}