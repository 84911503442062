import { Component, OnInit,ViewEncapsulation, ViewChild } from '@angular/core';
import { ParroquiasServices} from '../parroquia/parroquias.services';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { NotificationServices} from '../../../../appauth/notification/notificatio.services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Parroquias } from '../parroquia/parroquias.model';
import { AddParroquiaComponent } from '../modal/add/addparroquia.component';
import { EditParroquiaComponent } from '../modal/edit/editparroquia.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
	selector: 'ms-parroquiavista',
	templateUrl:'./parroquiavista.component.html',
	styleUrls: ['./parroquiavista.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class ParroquiasVistaComponent implements OnInit {
	page = 1;
	pageSize =20;
	rows = [];
	temp = [];

	iconItems = [{
		text: 'Nuevo',
		icon: 'mdi mdi-note-plus'
	},{
		text: 'Refrescar',
		icon: 'mdi mdi-backup-restore'
	}]

	config: any = {
		size: 'sm',
		centered: true,
		resolve: { datasend: null }
	}


	select(text: string) {
		if(text=='Refrescar'){
			this.listaParroquias();
		} else if(text=='Nuevo') {
			this.open();
		}
	}

	constructor(public dialog: NgbModal,
		private serviciosParroquias:ParroquiasServices,
		private notificationServices:NotificationServices,
		private ngxService: NgxUiLoaderService,
	){}

	ngOnInit(){
		this.listaParroquias();
	}

	listaParroquias(){
		this.ngxService.startLoader('princialLoder');
		this.serviciosParroquias.getDataParroquias().subscribe((data:any)=>{
			this.rows=data.parroquias;
			let info=Math.ceil(this.rows.length/this.pageSize);
			if(info<this.page)
				this.page--;
			this.temp = [...this.rows];
			this.ngxService.stopLoader('princialLoder');
		});
	}

	updateFilter(event){
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function(d){
			return d.nombre.toLowerCase().indexOf(val) !== -1 || !val || d.cantone.nombre.toLowerCase().indexOf(val) !== -1;
		});
		this.rows = temp;
	}

	open(){
		let dialogRef = this.dialog.open(AddParroquiaComponent, this.config);
		dialogRef.result.then(
			result=>{
				if(result){
					this.listaParroquias();
				}
			},
			reason=>{
				console.log(reason);
			}
		);
	}

	delete(dataParroquia:any){
		let data={'val':dataParroquia.nombre,'msg':'Eliminar Parroquias'};
		this.notificationServices.msgEliminarConfir(data).then((datas)=>{
			if(datas){
				this.serviciosParroquias.deleteParroquias(dataParroquia.id).subscribe((smsdata:any)=>{
					if(smsdata.estado){
						this.notificationServices.setNotification(smsdata).then((datas)=>{
							if(datas){
								this.listaParroquias();
							}
						});
					}else{
						this.notificationServices.setNotification(smsdata);
					}
				});
			}
		});
	}

	edit(data:any){
		let dialogRef = this.dialog.open(EditParroquiaComponent, this.config);
		dialogRef.componentInstance.Parroquia = data;
		dialogRef.result.then(
			result=>{
				if(result===1){
					this.listaParroquias();
				}
			},
			reason=>{
				console.log(reason);
			}
		);
	}

	editEstado(dataParroquia:Parroquias){
		let dataEstadoParroquia= {} as Parroquias;
		dataEstadoParroquia.id=dataParroquia.id;
		dataEstadoParroquia.estado=Number(!dataParroquia.estado);
		this.serviciosParroquias.updateParroquias(dataEstadoParroquia).subscribe((data:any)=>{});
	}
}

