import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-motivos-agupado-seccion',
  templateUrl: './motivos-agupado-seccion.component.html',
  styleUrls: ['./motivos-agupado-seccion.component.scss']
})
export class MotivosAgupadoSeccionComponent implements OnInit {
  @Input() lista=[];
	groupedAcciones:any;

  constructor() { }

  ngOnInit() {
    this.groupedAcciones = this.groupBy(this.lista, row => row.seccion);

  }

  groupBy(list, keyGetter) {
		const map = new Map();
		list.forEach((item) => {
			const key = keyGetter(item);
			const collection = map.get(key);
			if (!collection) {
				map.set(key, [item]);
			} else {
				collection.push(item);
			}
		});
		return map;
	}

}
