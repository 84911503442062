import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { AddPagoVentaElementComponent } from './add-pago-venta/add-pago-venta.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormaPagosElementComponent } from './forma-pagos/forma-pagos.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { InfoFormapagoModalElementComponent } from './info-formapago/info-formapago.component';
import { ConfirmarMedicamentoEnfermeraHospitalizarComponent } from './confirmar-medicamento-enfermera-hospitalizar/confirmar-medicamento-enfermera-hospitalizar.component';
import { ImprimirCertificadoMedicoCustomComponent } from './imprimir-informacion/imprimir-informacion.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ImprimirCertificadoGeneralComponent } from './imprimir-informacion-certificado/imprimir-informacion-certificado.component';
import { DirectivaModule } from '../../../directivas/directiva.module';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxDatatableModule,
        NgSelectModule,
		AngularEditorModule,
		DirectivaModule
	],
	declarations:[AddPagoVentaElementComponent,FormaPagosElementComponent,InfoFormapagoModalElementComponent,ConfirmarMedicamentoEnfermeraHospitalizarComponent,ImprimirCertificadoMedicoCustomComponent,ImprimirCertificadoGeneralComponent],
	entryComponents:[AddPagoVentaElementComponent,FormaPagosElementComponent,ConfirmarMedicamentoEnfermeraHospitalizarComponent,ImprimirCertificadoMedicoCustomComponent,ImprimirCertificadoGeneralComponent],
})
export class ModalConfirmarPagoElementeModule { }