import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Motivoscitas } from '../motivoscita/motivoscitas.model';
import { MotivoscitasServices } from '../motivoscita/motivoscitas.services';

@Component({
  selector: 'app-mover-item-motivo',
  templateUrl: './mover-item-motivo.component.html',
  styleUrls: ['./mover-item-motivo.component.scss']
})
export class MoverItemMotivoComponent implements OnInit {
  @Input() indice: number;
  @Input() cantidad: number;
  @Input() data: any;
  @Input() eliminacion: boolean;
  @Output() clickEvent = new EventEmitter();

  constructor(private servicioMotivo: MotivoscitasServices,) { }

  ngOnInit() {
    /* console.log(this.cantidad);
    console.log(this.indice);
    console.log(this.data); */
    
    if (!this.data.orden || this.eliminacion) {
      let ParametrosexameneDataModel = {} as Motivoscitas;
      ParametrosexameneDataModel.id = this.data.id;
      ParametrosexameneDataModel.orden = this.indice;
      this.servicioMotivo.updateMotivoscitas(ParametrosexameneDataModel).subscribe((data: any) => {
        if (data.estado) {
          this.data.orden = data.datos.orden;
        }
      });
    }

  }

  up() {

    let ParametrosexameneDataModelPreview = {} as Motivoscitas;
    ParametrosexameneDataModelPreview.formulario = this.data.formulario;
    ParametrosexameneDataModelPreview.seccion = this.data.seccion;
    ParametrosexameneDataModelPreview.id = this.data.id;
    ParametrosexameneDataModelPreview.orden = this.indice;
    ParametrosexameneDataModelPreview.nextorden = this.indice - 1;
    this.servicioMotivo.updateMotivoscitasSiguiente(ParametrosexameneDataModelPreview).subscribe((data: any) => {
      if (data.estado) {
        let ParametrosexameneDataModel = {} as Motivoscitas;
        ParametrosexameneDataModel.id = this.data.id;
        ParametrosexameneDataModel.orden = this.indice - 1;
        this.servicioMotivo.updateMotivoscitas(ParametrosexameneDataModel).subscribe((data: any) => {
          if (data.estado) {
            this.data.orden = data.datos.orden;
            this.clickEvent.emit(this.data);
          }
        });
      }
    });
  }

  down() {

    let ParametrosexameneDataModelNext = {} as Motivoscitas;
    ParametrosexameneDataModelNext.formulario = this.data.formulario;
    ParametrosexameneDataModelNext.seccion = this.data.seccion;
    ParametrosexameneDataModelNext.id = this.data.id;
    ParametrosexameneDataModelNext.orden = this.indice;
    ParametrosexameneDataModelNext.nextorden = this.indice + 1;
    this.servicioMotivo.updateMotivoscitasSiguiente(ParametrosexameneDataModelNext).subscribe((data: any) => {
      if (data.estado) {
        let ParametrosexameneDataModel = {} as Motivoscitas;
        ParametrosexameneDataModel.id = this.data.id;
        ParametrosexameneDataModel.orden = this.indice + 1;
        this.servicioMotivo.updateMotivoscitas(ParametrosexameneDataModel).subscribe((data: any) => {
          if (data.estado) {
            this.data.orden = data.datos.orden;
            this.clickEvent.emit(this.data);
          }
        });
      }
    });
  }

}
