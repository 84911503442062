import { Routes } from '@angular/router';
import { AuthGuard } from '../../../appauth/auth/auth.guard';
import { SolicitudexternasVistaComponent } from './vistas/solicitudexternavista.component';

export const SolicitudexternasRoutes: Routes = [{
	path: '',
	redirectTo: 'externo-index',
	pathMatch: 'full',
	canActivate: [AuthGuard]
},{
	path: '',
	children: [{
		path: 'externo-index',
		component: SolicitudexternasVistaComponent,
		canActivate: [AuthGuard]
	}]
}];
