import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SolicitudexternasRoutes } from './solicitudexternas.routing';
import { SolicitudexternasVistaComponent } from './vistas/solicitudexternavista.component';
import { ModalSolicitudexternaModule } from './modal/modalsolicitudexterna.module';
import { SolicitudexternasServices } from './solicitudexterna/solicitudexternas.services';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(SolicitudexternasRoutes),
		NgxDatatableModule,
		ModalSolicitudexternaModule
	],
	declarations:[
		SolicitudexternasVistaComponent,
	],
	providers:[
		SolicitudexternasServices
	],
	exports:[
		SolicitudexternasVistaComponent
	]
})

export class SolicitudexternasModule {}
