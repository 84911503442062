import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MotivoscitasRoutes } from './motivoscitas.routing';
import { MotivoscitasVistaComponent } from './vistas/motivoscitavista.component';
import { ModalMotivoscitaModule } from './modal/modalmotivoscita.module';
import { MotivoscitasServices } from './motivoscita/motivoscitas.services';
import { CitasmedicainformacionesServices } from './citasmedicainformacione/citasmedicainformaciones.services';
import { AccionesFormulariosCitasComponent } from './acciones-formularios-citas/acciones-formularios-citas.component';
import { ItemAccionesMotivosComponent } from './item-acciones-motivos/item-acciones-motivos.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MotivosAgupadoSeccionComponent } from './motivos-agupado-seccion/motivos-agupado-seccion.component';
import { MotivosAgupadoSeccionDetallesComponent } from './motivos-agupado-seccion-detalles/motivos-agupado-seccion-detalles.component';
import { OpcionesmotivosModule } from '../opcionesmotivos/opcionesmotivos.module';
import { MoverItemMotivoComponent } from './mover-item-motivo/mover-item-motivo.component';
import { GrupoMotivosDetalleComponent } from './grupo-motivos-detalle/grupo-motivos-detalle.component';
import { SvgObjectComponent } from './svg-object/svg-object.component';
import { DataSharingService } from './citasmedicainformacione/imagensgv.services';
import { Tipo1PersonalizadoMotivoComponent } from './items-motivos-custom/tipo1-personalizado-motivo/tipo1-personalizado-motivo.component';
import { Tipo2PersonalizadoMotivoComponent } from './items-motivos-custom/tipo2-personalizado-motivo/tipo2-personalizado-motivo.component';
import { Tipo3PersonalizadoMotivoComponent } from './items-motivos-custom/tipo3-personalizado-motivo/tipo3-personalizado-motivo.component';
import { Tipo4PersonalizadoMotivoComponent } from './items-motivos-custom/tipo4-personalizado-motivo/tipo4-personalizado-motivo.component';
import { Tipo5PersonalizadoMotivoComponent } from './items-motivos-custom/tipo5-personalizado-motivo/tipo5-personalizado-motivo.component';
import { Tipo6PersonalizadoMotivoComponent } from './items-motivos-custom/tipo6-personalizado-motivo/tipo6-personalizado-motivo.component';
import { Tipo7PersonalizadoMotivoComponent } from './items-motivos-custom/tipo7-personalizado-motivo/tipo7-personalizado-motivo.component';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(MotivoscitasRoutes),
		NgxDatatableModule,
		ModalMotivoscitaModule,
		NgxUiLoaderModule,
		NgbModule,
		OpcionesmotivosModule
	],
	declarations:[
		MotivoscitasVistaComponent,
		AccionesFormulariosCitasComponent,
		ItemAccionesMotivosComponent,
		MotivosAgupadoSeccionComponent,
		MotivosAgupadoSeccionDetallesComponent,
		MoverItemMotivoComponent,
		GrupoMotivosDetalleComponent,
		SvgObjectComponent,
		Tipo1PersonalizadoMotivoComponent,
		Tipo2PersonalizadoMotivoComponent,
		Tipo3PersonalizadoMotivoComponent,
		Tipo4PersonalizadoMotivoComponent,
		Tipo5PersonalizadoMotivoComponent,
		Tipo6PersonalizadoMotivoComponent,
		Tipo7PersonalizadoMotivoComponent
	],
	providers:[
		MotivoscitasServices,
		CitasmedicainformacionesServices,
		DataSharingService
	],
	exports:[
		MotivoscitasVistaComponent,
		AccionesFormulariosCitasComponent,
		ItemAccionesMotivosComponent,
		MotivosAgupadoSeccionComponent,
		MotivosAgupadoSeccionDetallesComponent,
		MoverItemMotivoComponent,
		GrupoMotivosDetalleComponent
	]
})

export class MotivoscitasModule {}
