import { Component, Input, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { localStorageServices } from '../../../appauth/auth/localStorageServices';
import { ElementComponentService } from '../servicios/component.servicios';

@Component({
  selector: '[app-resumen-receta-alta-paciente]',
  templateUrl: './resumen-receta-alta-paciente.component.html',
  styleUrls: ['./resumen-receta-alta-paciente.component.scss']
})
export class ResumenRecetaAltaPacienteComponent implements OnInit {
  @Input() idHospitalizacion: number;
  userdata: any;
  groupLista:any;
  dataMedida = [];
  dataMedidaFrecuencia = [];
  medicosRecetas = [];
	rowsIndicacionesGenerales: any[] = [];
  rows = [];
  temp = [];
  rowsgenerales = [];
	tempgenerales = [];
  medicoParaReceta:any;
  

  constructor(public localdata: localStorageServices,
    private servicioMedicina: ElementComponentService,
    private servicioFrecuencia: ElementComponentService,
    private ngxService: NgxUiLoaderService,
    ) { }

  ngOnInit() {
    this.userdata = this.localdata.getValueFromLocalStorage();
    this.servicioMedicina.getDataMedidaMedicinasResumen().subscribe((data: any) => {
      this.dataMedida = data.medidamedicamentos;
    });
    this.servicioFrecuencia.getDataFrecuenciamedicamentosResumen().subscribe((data: any) => {
      this.dataMedidaFrecuencia = data.frecuenciamedicamentos;
    });
    this.medicosRecetas = [];
    this.listaIndicaciones();
    this.listaIndicacionesgenerales();
  }

  listaIndicaciones() {
   
    this.servicioFrecuencia.viewIndicacionrecetasResumen(this.idHospitalizacion).subscribe((data: any) => {
      this.rows = data.indicacionrecetas;
      for (let w = 0; w < this.rows.length; w++) {
        const element = this.rows[w];
        let existe = false;
        for (let z = 0; z < this.medicosRecetas.length; z++) {
          const elementmed = this.medicosRecetas[z];
          if (element.persona.id == elementmed.id) {
            existe = true;
            break;
          }
        }
        if (!existe) {
          this.medicosRecetas.push(element.persona);
          this.medicoParaReceta=this.medicosRecetas[0];
        }
      }
      this.temp = [...this.rows];
      this.ngxService.stopLoader('princialLoder');
    });

  }

 
	listaIndicacionesgenerales() {
		this.servicioFrecuencia.viewIndicacionesgeneralesHospitalizacionResumen(this.idHospitalizacion).subscribe((data: any) => {
			this.rowsgenerales = data.indicacionesgenerales;
			this.tempgenerales = [...this.rowsgenerales];
       for (let w = 0; w < this.rowsgenerales.length; w++) {
        const element = this.rowsgenerales[w];
        let existe = false;
        for (let z = 0; z < this.medicosRecetas.length; z++) {
          const elementmed = this.medicosRecetas[z];
          if (element.persona.id == elementmed.id) {
            existe = true;
            break;
          }
        }
        if (!existe) {
          this.medicosRecetas.push(element.persona);
          this.medicoParaReceta=this.medicosRecetas[0];
        }
      }

			this.groupLista = this.groupBy(this.tempgenerales, row => row.agrupado);

			this.ngxService.stopLoader('princialLoder');
		});
	}

  fraction(value, donly = true) {
    let tolerance = 1.0E-6; // a partir de cuantas decimales se hace el redondeo
    let h1 = 1;
    let h2 = 0;
    let k1 = 0;
    let k2 = 1;
    let negative = false;
    let i: any;

    if (parseInt(value) == value) { // si el valor es un número entero, detener el código
      return value;
    } else if (value < 0) {
      negative = true;
      value = -value;
    }

    if (donly) {
      i = parseInt(value);
      value -= i;
    }

    let b = value;

    do {
      let a = Math.floor(b);
      let aux = h1;
      h1 = a * h1 + h2;
      h2 = aux;
      aux = k1;
      k1 = a * k1 + k2;
      k2 = aux;
      b = 1 / (b - a);
    } while (Math.abs(value - h1 / k1) > value * tolerance);

    return (negative ? "-" : '') + ((donly && (i != 0)) ? i + ' ' : '') + (h1 == 0 ? '' : h1 + "/" + k1);
  }

	groupBy(list, keyGetter) {
		const map = new Map();
		list.forEach((item) => {
			const key = keyGetter(item);
			const collection = map.get(key);
			if (!collection) {
				map.set(key, [item]);
			} else {
				collection.push(item);
			}
		});
		return map;
	}

}
