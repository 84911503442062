import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditSolicitudexternaComponent } from './edit/editsolicitudexterna.component';
import { AddSolicitudexternaComponent } from './add/addsolicitudexterna.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgSelectModule,
	],
	declarations:[EditSolicitudexternaComponent,AddSolicitudexternaComponent],
	entryComponents:[EditSolicitudexternaComponent,AddSolicitudexternaComponent],
})
export class ModalSolicitudexternaModule { }
