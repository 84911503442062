import { Injectable } from '@angular/core';
import { TokenParams } from '../../global/tokenParams';

@Injectable()
export class localStorageServices {
    public setAuthorizationData(Auth: TokenParams): void {
        localStorage.setItem('Authorization', JSON.stringify(Auth));
    }

    public getValueFromLocalStorage(): TokenParams {
        let datatoken = JSON.parse(localStorage.getItem('Authorization'));
        return datatoken == null ? null : datatoken;
    }

    public setDataInsumoExamenes(data: any): void {
        localStorage.setItem('insumos', JSON.stringify(data));
    }

    public getDataInsumoExamenes(): any[] {
        let datatoken = JSON.parse(localStorage.getItem('insumos'));
        return datatoken == null ? null : datatoken;
    }

    public removeDataInsumoExamenes(): void {
        localStorage.setItem('insumos', JSON.stringify([]));
    }

    public removeValueFromLocalStorage(): void {
        localStorage.setItem('Authorization', JSON.stringify({}));
    }
    
    public round(num: any, decimales = 4): number {
        let signo: any = (num >= 0 ? 1 : -1);
        num = num * signo;
        if (decimales === 0) //con 0 decimales
            return signo * Math.round(num);
        // round(x * 10 ^ decimales)
        num = num.toString().split('e');
        num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
        // x * 10 ^ (-decimales)
        num = num.toString().split('e');
        let val: any = num[0] + 'e';
        return signo * (val + (num[1] ? (+num[1] - decimales) : -decimales));
    }

    public round2Decimales(num: any, decimales = 2): number {
        let signo: any = (num >= 0 ? 1 : -1);
        num = num * signo;
        if (decimales === 0) //con 0 decimales
            return signo * Math.round(num);
        // round(x * 10 ^ decimales)
        num = num.toString().split('e');
        num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
        // x * 10 ^ (-decimales)
        num = num.toString().split('e');
        let val: any = num[0] + 'e';
        return signo * (val + (num[1] ? (+num[1] - decimales) : -decimales));
    }
}