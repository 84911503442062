import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditEspecialidad_personaleComponent } from './edit/editespecialidad_personale.component';
import { AddEspecialidad_personaleComponent } from './add/addespecialidad_personale.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ModalPersonaModule } from '../../personas/modal/modalpersona.module';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxDatatableModule,
		ModalPersonaModule,
		NgSelectModule
	],
	declarations:[EditEspecialidad_personaleComponent,AddEspecialidad_personaleComponent],
	entryComponents:[EditEspecialidad_personaleComponent,AddEspecialidad_personaleComponent],
})
export class ModalEspecialidad_personaleModule { }
