import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditProvinciaComponent } from './edit/editprovincia.component';
import { AddProvinciaComponent } from './add/addprovincia.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { DirectivaModule } from '../../../../directivas/directiva.module';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DirectivaModule
	],
	declarations:[EditProvinciaComponent,AddProvinciaComponent],
	entryComponents:[EditProvinciaComponent,AddProvinciaComponent],
})
export class ModalProvinciaModule { }
