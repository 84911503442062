import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Motivoscitas } from '../../motivoscita/motivoscitas.model';
import { MotivoscitasServices } from '../../motivoscita/motivoscitas.services';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-editmotivoscita',
	templateUrl: './editmotivoscita.component.html',
	styleUrls: ['./editmotivoscita.component.scss']
})

export class EditMotivoscitaComponent implements OnInit {

	@Input() Motivoscita:Motivoscitas;

	public formMotivoscitaCodigo:FormGroup;
	public editCodigo:boolean=false;

	public formMotivoscitaNombre:FormGroup;
	public editNombre:boolean=false;

	public formMotivoscitaFormulario:FormGroup;
	public editFormulario:boolean=false;

	public formMotivoscitaSeccion:FormGroup;
	public editSeccion:boolean=false;

	public formMotivoscitaEstado:FormGroup;
	public editEstado:boolean=false;

	public formMotivoscitaTipo:FormGroup;
	public editTipo:boolean=false;

	public formMotivoscitaInicio:FormGroup;
	public editInicio:boolean=false;

	public formMotivoscitaFin:FormGroup;
	public editFin:boolean=false;

	public formMotivoscitaCol:FormGroup;
	public editCol:boolean=false;

	public formMotivoscitaMedida:FormGroup;
	public editMedida:boolean=false;


	dataTipo = [
		{ id: 1, nombre: 'Selección' },
		{ id: 2, nombre: 'Otro' },
		{ id: 3, nombre: 'Selección con detalle' },
		{ id: 4, nombre: 'Número' },
		{ id: 5, nombre: 'Opciones' },
		{ id: 6, nombre: 'Opciones y texto' },
		{ id: 7, nombre: 'Fecha' },
		{ id: 8, nombre: 'Observaciones' }
	];

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioMotivoscita:MotivoscitasServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {

		this.formMotivoscitaTipo=this.fb.group({
			tipo:[this.Motivoscita.tipo,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formMotivoscitaCodigo=this.fb.group({
			codigo:[this.Motivoscita.codigo,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(15)])],
		});

		this.formMotivoscitaNombre=this.fb.group({
			nombre:[this.Motivoscita.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300)])],
		});

		this.formMotivoscitaFormulario=this.fb.group({
			formulario:[this.Motivoscita.formulario,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(15)])],
		});

		this.formMotivoscitaSeccion=this.fb.group({
			seccion:[this.Motivoscita.seccion,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formMotivoscitaInicio=this.fb.group({
			inicio:[this.Motivoscita.inicio,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formMotivoscitaFin=this.fb.group({
			fin:[this.Motivoscita.fin,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formMotivoscitaCol=this.fb.group({
			col:[this.Motivoscita.col,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formMotivoscitaMedida=this.fb.group({
			medida:[this.Motivoscita.medida,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(20)])],
		});
	}
	

	editMotivoscitaTipoEnable(){
		this.editTipo=true;
		this.formMotivoscitaTipo=this.fb.group({
			tipo:[this.Motivoscita.tipo,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editMotivoscitaTipoDisable(){
		this.editTipo=false;
	}

	saveMotivoscitaTipo(){
		let MotivoscitaDataModel:Motivoscitas=this.formMotivoscitaTipo.value;
		MotivoscitaDataModel.id=this.Motivoscita.id;
		this.Motivoscita.tipo=MotivoscitaDataModel.tipo;
		this.servicioMotivoscita.updateMotivoscitas(MotivoscitaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editTipo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editMotivoscitaCodigoEnable(){
		this.editCodigo=true;
		this.formMotivoscitaCodigo=this.fb.group({
			codigo:[this.Motivoscita.codigo,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(15)])],
		});
	}

	editMotivoscitaCodigoDisable(){
		this.editCodigo=false;
	}

	saveMotivoscitaCodigo(){
		let MotivoscitaDataModel:Motivoscitas=this.formMotivoscitaCodigo.value;
		MotivoscitaDataModel.id=this.Motivoscita.id;
		this.Motivoscita.codigo=MotivoscitaDataModel.codigo;
		this.servicioMotivoscita.updateMotivoscitas(MotivoscitaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editCodigo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editMotivoscitaNombreEnable(){
		this.editNombre=true;
		this.formMotivoscitaNombre=this.fb.group({
			nombre:[this.Motivoscita.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300)])],
		});
	}

	editMotivoscitaNombreDisable(){
		this.editNombre=false;
	}

	saveMotivoscitaNombre(){
		let MotivoscitaDataModel:Motivoscitas=this.formMotivoscitaNombre.value;
		MotivoscitaDataModel.id=this.Motivoscita.id;
		this.Motivoscita.nombre=MotivoscitaDataModel.nombre;
		this.servicioMotivoscita.updateMotivoscitas(MotivoscitaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editNombre=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editMotivoscitaFormularioEnable(){
		this.editFormulario=true;
		this.formMotivoscitaFormulario=this.fb.group({
			formulario:[this.Motivoscita.formulario,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(15)])],
		});
	}

	editMotivoscitaFormularioDisable(){
		this.editFormulario=false;
	}

	saveMotivoscitaFormulario(){
		let MotivoscitaDataModel:Motivoscitas=this.formMotivoscitaFormulario.value;
		MotivoscitaDataModel.id=this.Motivoscita.id;
		this.Motivoscita.formulario=MotivoscitaDataModel.formulario;
		this.servicioMotivoscita.updateMotivoscitas(MotivoscitaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editFormulario=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editMotivoscitaSeccionEnable(){
		this.editSeccion=true;
		this.formMotivoscitaSeccion=this.fb.group({
			seccion:[this.Motivoscita.seccion,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editMotivoscitaSeccionDisable(){
		this.editSeccion=false;
	}

	saveMotivoscitaSeccion(){
		let MotivoscitaDataModel:Motivoscitas=this.formMotivoscitaSeccion.value;
		MotivoscitaDataModel.id=this.Motivoscita.id;
		this.Motivoscita.seccion=MotivoscitaDataModel.seccion;
		this.servicioMotivoscita.updateMotivoscitas(MotivoscitaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editSeccion=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

	editMotivoscitaInicioEnable(){
		this.editInicio=true;
		this.formMotivoscitaInicio=this.fb.group({
			inicio:[this.Motivoscita.inicio,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editMotivoscitaInicioDisable(){
		this.editInicio=false;
	}

	saveMotivoscitaInicio(){
		let MotivoscitaDataModel:Motivoscitas=this.formMotivoscitaInicio.value;
		MotivoscitaDataModel.id=this.Motivoscita.id;
		this.Motivoscita.inicio=MotivoscitaDataModel.inicio;
		this.servicioMotivoscita.updateMotivoscitas(MotivoscitaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editInicio=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editMotivoscitaFinEnable(){
		this.editFin=true;
		this.formMotivoscitaFin=this.fb.group({
			fin:[this.Motivoscita.fin,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editMotivoscitaFinDisable(){
		this.editFin=false;
	}

	saveMotivoscitaFin(){
		let MotivoscitaDataModel:Motivoscitas=this.formMotivoscitaFin.value;
		MotivoscitaDataModel.id=this.Motivoscita.id;
		this.Motivoscita.fin=MotivoscitaDataModel.fin;
		this.servicioMotivoscita.updateMotivoscitas(MotivoscitaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editFin=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editMotivoscitaColEnable(){
		this.editCol=true;
		this.formMotivoscitaCol=this.fb.group({
			col:[this.Motivoscita.col,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editMotivoscitaColDisable(){
		this.editCol=false;
	}

	saveMotivoscitaCol(){
		let MotivoscitaDataModel:Motivoscitas=this.formMotivoscitaCol.value;
		MotivoscitaDataModel.id=this.Motivoscita.id;
		this.Motivoscita.col=MotivoscitaDataModel.col;
		this.servicioMotivoscita.updateMotivoscitas(MotivoscitaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editCol=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editMotivoscitaMedidaEnable(){
		this.editMedida=true;
		this.formMotivoscitaMedida=this.fb.group({
			medida:[this.Motivoscita.medida,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(20)])],
		});
	}

	editMotivoscitaMedidaDisable(){
		this.editMedida=false;
	}

	saveMotivoscitaMedida(){
		let MotivoscitaDataModel:Motivoscitas=this.formMotivoscitaMedida.value;
		MotivoscitaDataModel.id=this.Motivoscita.id;
		this.Motivoscita.medida=MotivoscitaDataModel.medida;
		this.servicioMotivoscita.updateMotivoscitas(MotivoscitaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editMedida=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

}

