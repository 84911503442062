import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditInsumounidadeComponent } from './edit/editinsumounidade.component';
import { AddInsumounidadeComponent } from './add/addinsumounidade.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { DirectivaModule } from '../../../directivas/directiva.module';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DirectivaModule
	],
	declarations:[EditInsumounidadeComponent,AddInsumounidadeComponent],
	entryComponents:[EditInsumounidadeComponent,AddInsumounidadeComponent],
})
export class ModalInsumounidadeModule { }
