import { Component, OnInit, Input } from '@angular/core';
import { Especialidad_personalesServices } from '../especialidad_personale/especialidad_personales.services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddEspecialidad_personaleComponent } from '../modal/add/addespecialidad_personale.component';
import { Especialidad_personales } from '../especialidad_personale/especialidad_personales.model';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';

@Component({
  selector: 'app-medicos-especialidad',
  templateUrl: './medicos-especialidad.component.html',
  styleUrls: ['./medicos-especialidad.component.scss']
})
export class MedicosEspecialidadComponent implements OnInit {
  @Input() idMedico:any;
  rows = [];
  temp = [];
  
  constructor(private serviciosEspecialidad_personales:Especialidad_personalesServices,
			  private notificationServices:NotificationServices,
			  public dialog: NgbModal,) { }

  ngOnInit(){
		this.listaEspecialidad_personales();
  }
  
  config: any = {
		size: 'lg',
		centered: true,
		resolve: { datasend: null }
	}

	listaEspecialidad_personales(){
		this.serviciosEspecialidad_personales.viewEspecialidad_personales(this.idMedico.id).subscribe((data:any)=>{
			this.rows=data.especialidadPersonale;
			this.temp = [...this.rows];
		});
  }
  
  open(){
    let dialogRef = this.dialog.open(AddEspecialidad_personaleComponent, this.config);
    dialogRef.componentInstance.tipoBusqueda = true;
    dialogRef.componentInstance.dataPersona = this.idMedico;
    dialogRef.componentInstance.rows =this.rows;
		dialogRef.result.then(
			result=>{
				if(result){
					this.listaEspecialidad_personales();
				}
			},
			reason=>{
				console.log(reason);
			}
		);
	}

	editEstado(dataEspecialidad_personale:Especialidad_personales){
		
		let dataEstadoEspecialidad_personale= {} as Especialidad_personales;
		dataEstadoEspecialidad_personale.estado=Number(!dataEspecialidad_personale.estado);
		dataEstadoEspecialidad_personale.persona_id=dataEspecialidad_personale.persona_id;
		dataEstadoEspecialidad_personale.especialidade_id=dataEspecialidad_personale.especialidade_id;
		this.serviciosEspecialidad_personales.updateEspecialidad_personales(dataEstadoEspecialidad_personale).subscribe((data:any)=>{
			this.notificationServices.setNotification(data);
		});
	}

}
