import { Component, OnInit, Input } from '@angular/core';
import { ElementComponentService } from '../servicios/component.servicios';

@Component({
  selector: 'app-medidas-lista-resumen-control',
  templateUrl: './medidas-lista-resumen-control.component.html',
  styleUrls: ['./medidas-lista-resumen-control.component.scss']
})
export class MedidasListaResumenControlComponent implements OnInit {
  @Input() id:number=0;
  data:any;
  dataMedida=[];
    
  constructor(private servicioMedicina:ElementComponentService,) { }

  ngOnInit() {
    this.servicioMedicina.getDataMedidaMedicinasResumenControl().subscribe((data:any)=>{
      this.dataMedida=data.medidamedicamentos;
      this.data =  this.dataMedida.find(x => x.id === this.id);
		});
  }

}
