import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { localStorageServices } from '../../appauth/auth/localStorageServices';
import { Impresora } from '../../global/global.model';
import { ListaImpresorasServices } from '../../global/glogal.services';
import { NotificationServices } from '../../appauth/notification/notificatio.services';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  color = 'default';
  showSettings = false;
  showMinisidebar = false;
  showDarktheme = false;
  showRtl = false;
  userdata: any;
  grupos = [];

  public innerWidth: any;
  ulrsimp='https://';

  public config: PerfectScrollbarConfigInterface = {};

  insertar:boolean=false;
  dataImpresora={} as Impresora;
  Persona ={} as any;
  actulizar:boolean=false;
  iconoApp:string="";

  constructor(public router: Router,
              private servicioImpresora: ListaImpresorasServices,
              private Noti:NotificationServices,
              public localdata: localStorageServices,) {}

  ngOnInit() {
    this.ulrsimp='https://';
    this.userdata = this.localdata.getValueFromLocalStorage();
    let dataSend = { 'user_id': this.userdata.datos.id } as any;
    this.iconoApp=this.userdata.datos.config.logo;
    this.grupos = this.userdata.datos.group_users;
    this.grupos.forEach((element,index) => {
      this.grupos[index].select =false;
      if(this.userdata.grupo!=undefined){
        if(element.group_id==this.userdata.grupo.group_id){
          this.grupos[index].select =true;
        }
      }
    });

    if(this.userdata.grupo!=undefined){
      this.servicioImpresora.viewAdministradoresImpresoras(this.userdata.grupo).subscribe((datadmin: any) => {
        if(datadmin.impresora!=undefined){
          this.dataImpresora=datadmin.impresora;
          this.ulrsimp='https://'+this.dataImpresora.ip+':3333';
          this.insertar=true;
        }else{
          this.insertar=false;
        }
      });
    }

    if (this.router.url === '/') {
      this.router.navigate(['/dashboard/dashboard1']);
    }
    this.handleLayout();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleLayout();
  }

  toggleSidebar() {
    this.showMinisidebar = !this.showMinisidebar;
  }

  handleLayout() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1170) {
      this.showMinisidebar = true;
    } else {
      this.showMinisidebar = false;
    }
  }

  seleccionar(data:any){
    this.userdata.grupo=data;
    this.localdata.setAuthorizationData(this.userdata);
    this.grupos = this.userdata.datos.group_users;
    this.grupos.forEach((element,index) => {
      this.grupos[index].select =false;
      if(this.userdata.grupo!=undefined){
        if(element.group_id==this.userdata.grupo.group_id){
          this.grupos[index].select =true;
        }
      }
    });
    this.router.navigate(['home']);
    setInterval(() => {
      window.location.reload();
    }, 1000);
  }

  guardar(data:any){
    this.dataImpresora=data.value;
    this.dataImpresora.group_id=this.userdata.grupo.group_id;
    this.dataImpresora.user_id=this.userdata.grupo.user_id;
    this.dataImpresora.estado=1;
    this.servicioImpresora.saveImpresoras(this.dataImpresora).subscribe((dataImpr:any)=>{
      if(dataImpr.estado){
				this.Noti.setNotification(dataImpr).then((datas)=>{
					if(datas){
					this.insertar=true;
					}
				});
			}else{
				this.Noti.setNotification(dataImpr);
			}
    });
    
  }

  actulizarInfo(){  
    this.servicioImpresora.updateImpresoras(this.dataImpresora).subscribe((dataImpr:any)=>{
      if(dataImpr.estado){
				this.Noti.setNotification(dataImpr).then((datas)=>{
					if(datas){
            this.actulizar=false;
					}
				});
			}else{
				this.Noti.setNotification(dataImpr);
			}
    });
  }

  habiltar(){
    this.actulizar=true;
  }

  actulizarCancel(){
    this.actulizar=false;
  }

  probar(){
    let sendCod = { data: { "empresa": "CODEKUNA"}, ip: this.dataImpresora.ip } as any;
    this.servicioImpresora.printerPueba(sendCod).subscribe();
  }
  
}
